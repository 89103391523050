import React, { useState, Fragment, useEffect } from "react";
import Button from '@mui/material/Button';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import { AiOutlineClose } from 'react-icons/ai'
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters, selectFilters, updateFilters } from "../store/slices/filtersSlice";
import { selectUser } from "../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import FilterList from "./FilterDropdown";
import { selectDiseaseCategory, selectDiseaseType } from "../store/slices/diseaseCategorySlice";
import { selectSearchKey, setSearchKey } from "../store/slices/searchSlice";
import { Checkbox, Typography, useMediaQuery } from "@mui/material";

interface Filter {
    Id: string;
    label: string;
    isVisible: boolean;
}

interface SelectedFilters {
    [key: string]: string[];
}

const tabStyle = (activeTab: string, tabName: string) => ({
    cursor: "pointer",
    padding: "9px 12px",
    borderRadius: activeTab === tabName ? "10px 10px 0 0" : "10px",
    border: activeTab === tabName ? "1px solid #E2E5F9" : "none",
    borderBottom: activeTab === tabName ? "none" : "1px solid transparent",
    backgroundColor: activeTab === tabName ? "#FFFFFF" : "transparent",
    color: activeTab === tabName ? "black" : "gray",
    fontWeight: "600",
});

interface SideBarProps {
    setTotalRows: (rows: number) => void;
    columns: Filter[];
    handleChange: (label: string) => void;
    handleFilteredRows: (rows: any) => void;
    curation: string;
    expanded: boolean;
    setExpanded: (value: boolean) => void;
}

function SideBar({ setTotalRows, columns, handleChange, handleFilteredRows, curation ,expanded, setExpanded}: SideBarProps) {
    const columnIds = columns.map((column) => {
        return column.Id;
    });
    const [filters, setFilters] = useState<SelectedFilters>({});
    const [totalCounts, setTotalCounts] = useState<{ [key: string]: number }>({});
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const diseaseCategory = useSelector(selectDiseaseCategory);
    const searchKey = useSelector(selectSearchKey);
    const diseaseType = useSelector(selectDiseaseType);
    const fetchFilters = async () => {
        let query = `/api/filter?DiseaseCategory=${diseaseCategory}`;
        if (diseaseCategory !== "Inflammatory Bowel Disease") {
            query += `&DiseaseType=${diseaseType}`;
        }
        try {
            const response = await axiosConfig.get(query);
            setTotalCounts(response.data.data.total_count);
            setFilters(response.data.data);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    setFilters((await axiosConfig.get(query)).data.data);
                    setTotalCounts((await axiosConfig.get(query)).data.data.total_count);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }    
        }
    }
    useEffect(() => {
        fetchFilters();
        // eslint-disable-next-line
    }, [diseaseType,diseaseCategory]);
    
    const filtersConfig = [
        { Id: 'GEOAccession', label: 'GEO Accession', isVisible: columnIds.includes('GEOAccession') },
        { Id: 'Contributor', label: 'Contributor', isVisible: columnIds.includes('Contributor') },
        { Id: 'CellType', label: 'Cell Type', isVisible: columnIds.includes('CellType') },
        { Id: 'CellLine', label: 'Cell Line', isVisible: columnIds.includes('CellLine') },
        { Id: 'Disease', label: 'Disease', isVisible: columnIds.includes('Disease') },
        { Id: 'Organism', label: 'Organism', isVisible: columnIds.includes('Organism') },
        { Id: 'PublicationYear', label: 'Publication Year', isVisible: columnIds.includes('PublicationYear') },
        { Id: 'LastUpdateYear', label: 'Last Update Year', isVisible: columnIds.includes('LastUpdateYear') },
        { Id: 'TechnologyType', label: 'Technology Type', isVisible: columnIds.includes('TechnologyType') },
        { Id: 'Tissue', label: 'Tissue', isVisible: columnIds.includes('Tissue') },
        { Id: 'Age', label: 'Age', isVisible: columnIds.includes('Age') },
        { Id: 'Gender', label: 'Gender', isVisible: columnIds.includes('Gender') },
        { Id: 'InstrumentModel', label: 'Sequencing Platform', isVisible: columnIds.includes('InstrumentModel') },
        { Id: 'CellSortingTechnique', label: 'Cell Sorting Technique', isVisible: columnIds.includes('CellSortingTechnique') },
        { Id: 'LibraryPreparationPlatform', label: 'Library Preparation Platform', isVisible: columnIds.includes('LibraryPreparationPlatform') },
        { Id: 'LibraryProcessingProtocol', label: 'Library Processing Protocol', isVisible: columnIds.includes('LibraryProcessingProtocol') },
        { Id: 'Sequencer', label: 'Sequencer', isVisible: columnIds.includes('Sequencer') },
        { Id: 'SequenceDataAligner', label: 'Sequence Data Aligner', isVisible: columnIds.includes('SequenceDataAligner') },
        { Id: 'SequenceDataProcessor', label: 'Sequence Data Processor', isVisible: columnIds.includes('SequenceDataProcessor') },
        { Id: 'SampleAcquisitionMethod', label: 'Sample Acquisition Method', isVisible: columnIds.includes('SampleAcquisitionMethod') },
        { Id: 'DiseaseStatus', label: 'Disease Status', isVisible: columnIds.includes('DiseaseStatus') },
        { Id: 'ExtractedMolecule', label: 'Extracted Molecule', isVisible: columnIds.includes('ExtractedMolecule') },
        { Id: 'LibraryStrategy', label: 'Library Strategy', isVisible: columnIds.includes('LibraryStrategy') },
        { Id: 'LibrarySource', label: 'Library Source', isVisible: columnIds.includes('LibrarySource') },
        { Id: 'LibrarySelection', label: 'Library Selection', isVisible: columnIds.includes('LibrarySelection') },
        { Id: 'NumberOfCells', label: 'Number of Cells', isVisible: columnIds.includes('NumberOfCells') },
        { Id: 'Treatment', label: 'Treatment', isVisible: columnIds.includes('Treatment') },
        { Id: 'TreatmentResponse', label: 'Treatment Response', isVisible: columnIds.includes('TreatmentResponse')} 
    ];

const filteredFiltersConfig = filtersConfig.filter((filter) => {
    const filterValues = filters[filter.Id];
    const hasValidValues =
        Array.isArray(filterValues) && filterValues.length > 0 &&
        (typeof filterValues[0] === 'object' ? Object.keys(filterValues[0]).length > 0 : true);
        
    return hasValidValues;
});
    const [width, setWidth] = useState<string>('16vw');
    const handleClick = () => {
        setExpanded(!expanded);
        if (!expanded) {
            for (let i = 1; i <= 8; i++) {
                setTimeout(() => setWidth(`${i+8}vw`), i * 10);  
            }
        } else {
            for (let i = 16; i >= 1; i--) {
                setTimeout(() => setWidth(`${i}vw`), (16- i) * 10);
            }
        }
    };


    const selectedFilters = useSelector(selectFilters);
    const updateSelectedFilters = (updatedFilters: SelectedFilters) => {
        dispatch(updateFilters(updatedFilters));
    };

    const clearFilters = async () => {
        handleFilteredRows(['loader']);
        dispatch(resetFilters());
        if (searchKey.length > 0) {
            dispatch(setSearchKey(''));
            return;
        }
        let query = (curation === 'sample') ? "/api/sample" : (curation === 'study') ? "/api/study" : "/api/experiment";
        query += `?page=1&per_page=15&DiseaseCategory=${diseaseCategory}`;
        if (diseaseCategory !== "Inflammatory Bowel Disease") {
            query += `&DiseaseType=${diseaseType}`;
        }
        try {
            const response = await axiosConfig.get(query);
            setTotalRows(response.data.no_of_records);
            handleFilteredRows(response.data.data);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                } 
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    const refreshedResponse = await axiosConfig.get(query);
                    setTotalRows(refreshedResponse.data.no_of_records);
                    handleFilteredRows(refreshedResponse.data.data);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };

    const renderedFilterColumns = filteredFiltersConfig.map((filter) => {
        if ((Object.values(filters).some((value) => value.length > 0))) {
            return (
                <div key={filter.Id} style={{ display: 'flex'}}>
                    <FilterList filters={(filters as Record<string,string[]>)[filter.Id]} filter={filter} totalCounts={totalCounts} selectedFilters={selectedFilters} updateSelectedFilters={updateSelectedFilters} clearFilters={clearFilters} />
                </div>
            );
        }
        return null;
    });
    const checkboxStyles: React.CSSProperties = {  margin: '7px 1px 7px 15px',cursor: 'pointer', transform: "scale(0.85)", padding: 0,fontSize:"16px",fontWeight:"400" };
    const buttonStyles: React.CSSProperties = { textTransform: 'none', color: 'black', margin: '7px 40px 7px 15px', display: 'inline-block', textAlign: 'left', padding: 0, width: 'auto',fontWeight:"500",fontSize:"17px", };
    const handleCheckboxChange = (label: string) => {
        handleChange(label);
    };
    const renderedVisibilityColumns = columns.map((column) => {
        return (
            <div key={column.Id} style={{ marginTop: '4.5px', display: 'flex' }}>
                <Checkbox size="small" style={{...checkboxStyles, color: column.isVisible ? "#364098" : "#959AC7"}} checked={column.isVisible} onChange={() => handleCheckboxChange(column.label)}/>

                <Button id="tablecolumn" style={{...buttonStyles, color: column.isVisible ? "#364098" : "#000000",padding:"5px" }}>{column.label}</Button>

            </div>
        );
    });

    const isSmallScreen =useMediaQuery("(max-width:1300px)");
    const removeFilter = (key: string, value: string) => {
        const updatedFilters = { ...selectedFilters, [key]: selectedFilters[key].filter((item) => item !== value) };
        dispatch(updateFilters(updatedFilters));
        if (Object.values(selectedFilters).reduce((sum, filterArray) => sum + filterArray.length, 0) === 1) {
            clearFilters();
        }
    };

    const [activeTab, setActiveTab] = useState<string>('Filters');
    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };
    const filterLabel = (
        <div style={{ display: 'flex', alignItems: 'center', paddingTop: '1px', zIndex: '1', top: '60px', fontWeight: "600", marginLeft: '15px' }}>
            {expanded && 
             <div style={{ display: 'flex', gap: '10px' }}>
                          {["Filters", "Display"].map((tab) => (
                            <span 
                                key={tab} 
                                className="navbar" 
                                onClick={() => handleTabClick(tab)} 
                                style={tabStyle(activeTab, tab)}
                            >
                                <Typography variant="subtitle2">
                                    {tab}
                                </Typography>
                            </span>
                            ))}
                </div>
            }
               <span onClick={handleClick} 

    style={{ 
        cursor: "pointer",
        marginLeft: "auto", 
        marginRight:'30px'
    }}
>
    {expanded ? (
        <GoChevronLeft size={22}/>
    ) : (
        <GoChevronRight size={26} />
    )}
</span>

                </div>           
    );

    const renderedSelectedFilters = Object.keys(selectedFilters).map((key) => {
        if (selectedFilters[key].length > 0) {
            const renderedFilters = selectedFilters[key].map((value) => {
                return <Button onClick={() => removeFilter(key, value)} size="small" key={value} variant="contained" style={{ margin: '5px', whiteSpace: 'normal', textTransform: 'none',border:"1px solid #CCCFEA",backgroundColor:"#F7F8FF",color:"#364098",borderRadius:"40px"}}><AiOutlineClose size={10} style={{ marginRight: '10px' }} /><span className="filterbutton">{value}</span></Button>;
            });
            return (
                <Fragment key={key}>
                    <div style={{ marginLeft: '10px', marginTop: '3px',marginBottom:'4px',fontWeight:'500'}}>{filteredFiltersConfig.find((filter) => filter.Id === key)?.label}</div>
                    <div style={{ marginLeft: '5px',marginBottom: '5px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {renderedFilters}
                    </div>
                </Fragment>
            );
        }
        return null;
    });

    const applyFilters = async () => {
        handleFilteredRows(['loader']);
        if (searchKey.length > 0) {
            dispatch(setSearchKey(''));
            return;
        }
        let query=`?page=1&per_page=15&DiseaseCategory=${diseaseCategory}&`;
        Object.keys(selectedFilters).forEach(filter => {
            selectedFilters[filter].forEach(value => {
                query += `${filter}=${encodeURIComponent(value)}&`;
            });
        });
        query = query.slice(0,-1);
        if (diseaseCategory !== "Inflammatory Bowel Disease") {
            query += `&DiseaseType=${diseaseType}`;
        }
        query = (curation === 'sample') ? `/api/sample${query}` : (curation === 'study') ? `/api/study${query}` : `/api/experiment${query}` ;
        try {
            const response = await axiosConfig.get(query);
            setTotalRows(response.data.no_of_records);
            handleFilteredRows(response.data.data);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked"){
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    const refreshedResponse = await axiosConfig.get(query);
                    setTotalRows(refreshedResponse.data.no_of_records);
                    handleFilteredRows(refreshedResponse.data.data);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    }

    const renderedContent = () => {
        if (activeTab === "Filters"){
            return renderedFilterColumns;
        }else if (activeTab === "Display"){
            return renderedVisibilityColumns;
        }
    };

    return (
        <>
          {filterLabel}
        <div className="bg-gray-100 p-4 shadow-lg flex-none" 
    style={{  
        width: width,
        display: 'flex', 
        flexDirection: 'column',
        borderRadius: "12px",
        boxShadow: "2px 2px 8px -5px #0000003D",
        overflowY: 'auto',
        overflowX:'hidden',
        height:'calc(100vh - 168px)',
        border: '1px solid #E2E5F9',
        marginLeft: expanded?'10px':'0px',
        minWidth:expanded?'265px':'2vw',
        minHeight:'732px'
    }}
>

            {expanded && activeTab === "Filters" && (Object.values(selectedFilters).some((value) => value.length > 0)) && 
                <span style={{ paddingBottom: '8px', position: 'sticky', zIndex: '1', top: '3px', backgroundColor: 'white',paddingTop:"5px",paddingLeft:'10px',display:'flex',gap:'3px',flexWrap:'wrap'}}>
                    <Button size={isSmallScreen ? "small" : "medium"} variant="contained" style={{ backgroundColor: '#364098', textTransform: 'none', marginRight: '12px',borderRadius:"10px",fontWeight:"500" }} onClick={applyFilters}>
                        Apply Filters
                    </Button>
                    <Button  size={isSmallScreen ? "small" : "medium"} variant="contained" style={{ backgroundColor: '#364098', textTransform: 'none',borderRadius:"10px",fontWeight:"500"}} onClick={clearFilters}>
                        Clear Filters
                    </Button>
                </span>}
              
            {expanded && activeTab === "Filters" && renderedSelectedFilters}
            {expanded && renderedContent()}
        </div>
        </>
    );
}

export default SideBar;
