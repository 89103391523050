import { createSlice } from "@reduxjs/toolkit";

interface SelectedFilters {
    [key: string]: string[];
}

interface FiltersState {
    selectedFilters: SelectedFilters;
}

const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        selectedFilters: {
            'GEOAccession': [], 
            'Contributor': [], 
            'CellType': [], 
            'CellLine': [], 
            'Disease': [], 
            'Organism': [], 
            'PublicationYear': [], 
            'LastUpdateYear': [], 
            'TechnologyType': [], 
            'Tissue': [], 
            'Age': [], 
            'Gender': [], 
            'InstrumentModel': [], 
            'CellSortingTechnique': [], 
            'LibraryPreparationPlatform': [], 
            'LibraryProcessingProtocol': [], 
            'Sequencer': [], 
            'SequenceDataAligner': [], 
            'SequenceDataProcessor': [], 
            'SampleAcquisitionMethod': [], 
            'DiseaseStatus': [], 
            'ExtractedMolecule': [], 
            'LibraryStrategy': [], 
            'LibrarySource': [], 
            'LibrarySelection': [], 
            'NumberOfCells': [],
            'Treatment': [],
            'TreatmentResponse': [],
        },
    },
    reducers: {
        updateFilters: (state, action) => {
            state.selectedFilters = action.payload;
        },
        resetFilters: (state) => {
            state.selectedFilters = {
                'GEOAccession': [], 
                'Contributor': [], 
                'CellType': [], 
                'CellLine': [], 
                'Disease': [], 
                'Organism': [], 
                'PublicationYear': [], 
                'LastUpdateYear': [], 
                'TechnologyType': [], 
                'Tissue': [], 
                'Age': [], 
                'Gender': [], 
                'InstrumentModel': [], 
                'CellSortingTechnique': [], 
                'LibraryPreparationPlatform': [], 
                'LibraryProcessingProtocol': [], 
                'Sequencer': [], 
                'SequenceDataAligner': [], 
                'SequenceDataProcessor': [], 
                'SampleAcquisitionMethod': [], 
                'DiseaseStatus': [], 
                'ExtractedMolecule': [], 
                'LibraryStrategy': [], 
                'LibrarySource': [], 
                'LibrarySelection': [], 
                'NumberOfCells': [],
                'Treatment': [],
                'TreatmentResponse':[]
            };
        }
    },
});

export const { updateFilters, resetFilters } = filtersSlice.actions;
export const selectFilters = (state: { filters: FiltersState }) => state.filters.selectedFilters;
export default filtersSlice.reducer;