import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../store/slices/userSlice";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import { selectSearchKey } from "../store/slices/searchSlice";
import { selectDiseaseCategory, selectDiseaseType } from "../store/slices/diseaseCategorySlice";
import { selectFilters, updateFilters } from "../store/slices/filtersSlice";
import NavigationAppBar from "./NavigationAppBar";
import BreadcrumbNavigation from "./BreadcrumbNavigation";
import TableDownloadButton from "./TableDownloadButton";
import { Box,Typography } from "@mui/material";
import RowSelectionDialog from "./RowSelectionDialog";
import DiseaseTabs from "./DiseaseTabs";
import SideBar from "./SideBar";
import DataTable from "./DataTable";
import DataTableLoader from "./ComponentLoader";
import { styled } from '@mui/system';

interface ColumnConfig {
    Id: string;
    label: string;
    render: (Datasets: any) => any;
    isVisible: boolean;
    width: number;
}

interface TableProps {
    config: ColumnConfig[];
    curating: string;
    navButtons: string[];
    width: number;
    rowkey: string;
}

function CurationTable({ config, curating, navButtons, width, rowkey }: TableProps) {
    const [rows, setRows] = useState([]);
    const [columns, updateColumns] = useState(config);
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [totalRows, setTotalRows] = useState(0);
    const searchKey = useSelector(selectSearchKey);
    const [filterApplied, setFilterApplied] = useState(false);
    const diseaseCategory = useSelector(selectDiseaseCategory);
    let selectedFilters = useSelector(selectFilters);
    const diseaseType = useSelector(selectDiseaseType);
    const [expanded, setExpanded] = useState<boolean>(true);

    useEffect(() => {
        updateColumns(config);
    }, [config]);

    const fetchCurationTable = async (page: number = 1, perPage: number = 15) => {
        let query = (searchKey.length > 0) ? `/api/${curating}_search?page=${page}&per_page=${perPage}&search_string=${encodeURIComponent(searchKey)}&DiseaseCategory=${diseaseCategory}&` : 
                    `/api/${curating}?page=${page}&per_page=${perPage}&DiseaseCategory=${diseaseCategory}&`;
        if (Object.values(selectedFilters).some((value) => value.length > 0)) {
            Object.keys(selectedFilters).forEach(filter => {
                selectedFilters[filter].forEach(value => {
                    query += `${filter}=${encodeURIComponent(value)}&`;
                });
            });
        }
        query = query.slice(0,-1);
        if (diseaseCategory !== "Inflammatory Bowel Disease") {
            query += `&DiseaseType=${diseaseType}`;
        }
        try {
            const response = await axiosConfig.get(query);
            setTotalRows(response.data.no_of_records);
            setRows(response.data.data);
            setFilterApplied(true);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    const refreshedResponse = await axiosConfig.get(query);
                    setTotalRows(refreshedResponse.data.no_of_records);
                    setRows(refreshedResponse.data.data);
                    setFilterApplied(true);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };

    useEffect(() => {
        setFilterApplied(false);
        fetchCurationTable();
    // eslint-disable-next-line
    }, [searchKey, diseaseType,diseaseCategory]);

    const handleChange = (label: string) => {
        const updatedColumns = columns.map((column) => {
            if (label !== column.label) {
                return column;
            }
            return { ...column, isVisible: !column.isVisible };
        });
        updateColumns(updatedColumns);
    };

    const handleFilteredRows = (filteredRows: any) => {
        if (filteredRows[0] === 'loader') {
            setFilterApplied(false);
            setRows([]);
            return;
        }
        setFilterApplied(true);
        setRows(filteredRows);
    }

    const [selectedRows, setSelectedRows] = useState<string[] | object>(curating === 'sample' ? {} : []);
    const handleNavigation = (newSelectedRows: string[] | object) => {
        setSelectedRows(newSelectedRows);
    };

    const [openDialog, setOpenDialog] = useState(false);
    const navigateToTable = (curation: string) => {
        const checkedRows = (curating === 'sample') ? Array.from(new Set(Object.values(selectedRows))) : selectedRows;
        if ((checkedRows as string[]).length === 0) {
            setOpenDialog(true);
            return;
        }
        setRows([]);
        setFilterApplied(false);
        selectedFilters = { ...selectedFilters, "GEOAccession": [] };
        (checkedRows as string[]).forEach((GEOAccession) => {
            selectedFilters = { ...selectedFilters, "GEOAccession": [...selectedFilters['GEOAccession'], GEOAccession] };
        });
        dispatch(updateFilters(selectedFilters));
        navigate((curation === "Sample") ? '/sample-table' : (curation === "Study") ? '/study-table' : '/experiment-table');
    };

    const CurationButton = styled("button")({
        backgroundColor: "#FFFFFF",
        color: "#364098",
        border: "2px solid #364098",
        width: "11vw",
        minWidth: "max(110px, 8vw)",
        padding: "12px 6px",
        borderRadius: "10px",
        height: "38px",
        fontSize: "0.875rem",
        fontWeight: 500,
        textTransform: "none",
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
      });
      

    return (
    <Fragment>
    <div><NavigationAppBar includeSearch={true}/></div>
 
    <div className="flex" style={{gap:'20px'}}  >

        <div className="bg-gray-100 p-4 overflow-y-auto flex-none" style={{ marginTop: "85px",maxWidth:expanded?'16.5%':'3.5%',minWidth:expanded?'265px':'2vw'}}>   
         <SideBar setTotalRows={setTotalRows} columns={columns} handleChange={handleChange} handleFilteredRows={handleFilteredRows} curation={curating} expanded={expanded} setExpanded={setExpanded}/>
        </div>

        {/* Main Content */}

        <div className="flex-1 flex flex-col overflow-auto"  style={{marginTop: "80px",maxWidth:expanded?'82.5%':'96.5%',minWidth: expanded ? "775px" : "max(96.5%,calc(1000px - 2vw)" }}>

              <div style={{marginLeft:'-7px',minHeight:'5px'}}>
                <BreadcrumbNavigation breadcrumbs={[{path: '/study-table', text: 'Datasets'}, { path: '/study-table', text: (diseaseCategory === "Inflammatory Bowel Disease" ? diseaseCategory : diseaseType) ?? "" },{path: `/${curating}-table`, text: `${curating[0].toUpperCase() + curating.slice(1)} Level Curation`}]}/>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginRight:'13px',minHeight:'8px',marginTop:'-20px'}}>

                <div style={{ display: 'flex'}}>
                <Typography variant="h1" style={{                   
                    minWidth: "150px",fontWeight:'600'
                }}>
                    {diseaseCategory === "Inflammatory Bowel Disease" ? diseaseCategory : diseaseType}
                </Typography>
                </div>
                <div style={{ display: 'flex', gap: "10px", flexWrap: "wrap",marginTop:'10px'}}>

                    <TableDownloadButton setOpenDialog={setOpenDialog} selectedRows={selectedRows} curation={curating}/>
                    <CurationButton onClick={() => navigateToTable(navButtons[0])} >{(navButtons[0])} Level Curation</CurationButton>
                    <CurationButton style={{marginBottom:'20px'}} onClick={() => navigateToTable(navButtons[1])}>{(navButtons[1])} Level Curation</CurationButton>
                    <RowSelectionDialog openDialog={openDialog} setOpenDialog={setOpenDialog}/>
                </div>
                
               
            </div>
            <div >
             <Box sx={{ flexGrow: 1, minWidth: "100px", maxWidth: "99%", overflowY: "hidden",
                borderColor: "divider",
                bgcolor: "#EEEFFF",
                p: '2px',
                borderRadius: "12px",
                minHeight: "40px",marginTop:'-10px' }}>

                <DiseaseTabs/>
            </Box>
            </div>
            
            <div className="flex-1" style={{marginTop:'-6px'}}>
                {filterApplied ? (
                  <DataTable fetchFunction={fetchCurationTable} totalRows={totalRows} table={rows} columns={columns.filter((column) => column.isVisible)} width={rows.length === 0 ? 0 : width} rowkey={rowkey} selectedRows={selectedRows} handleNavigation={handleNavigation} apiParams={null} curation={curating} isExpanded={expanded}/> 
                ) : (
                    <DataTableLoader />
                )}
            </div>
        </div>
    </div>
</Fragment>

    );
}

export default CurationTable;