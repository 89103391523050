import React, { useState, MouseEvent, useEffect } from "react";
import { Tabs, Tab, Menu, MenuItem,Typography,useTheme} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDiseaseCategory, updateDiseaseType,selectDiseaseCategory,selectDiseaseType } from "../store/slices/diseaseCategorySlice";
import { resetFilters } from "../store/slices/filtersSlice";

import ArticleIcon from "../images/Article.svg";
import ExpIcon from "../images/Exp.svg";
import SamIcon from "../images/Sample.svg";
import Arrow from "../images/iconamoon_arrow-up-2-bold (1).svg";

interface Category {
  label: string;
  subMenu: boolean;
  route: string;
}

const categories: Category[] = [
  { label: "Inflammatory Bowel Disease", subMenu: true, route: "/inflammatory-bowel-disease" },
  { label: "Alzheimer's Disease", subMenu: true, route: "/alzheimers-disease" },
  { label: "Parkinson's Disease", subMenu: true, route: "/parkinsons-disease" },
  { label: "Frontotemporal Dementia", subMenu: true, route: "/frontotemporal-dementia" },
  { label: "Documentation", subMenu: false, route: "/documentation" },
];

const subMenuItems = [
  { label: "Study Level Curation", icon: ArticleIcon, route: "/study-table" },
  { label: "Experiment Level Curation", icon: ExpIcon, route: "/experiment-table" },
  { label: "Sample Level Curation", icon: SamIcon, route: "/sample-table" },
];

const DiseaseTabs: React.FC = () => {
  const diseaseType=useSelector(selectDiseaseType);
  const diseaseCategory=useSelector(selectDiseaseCategory);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDisease, setSelectedDisease] = useState<string | null>(null);

  const getSelectedTab = () => {
    if (location.pathname === "/publicly-curated-datasets") {
      return 0; 
    } else if (location.pathname === "/documentation") {
      return categories.length+1; 
    } else {
      const index = categories.findIndex(
        (category) => category.label === diseaseType || category.label === diseaseCategory
      );
            return index !== -1 ? index + 1 : false;
    }
  };

  const [value, setValue] = useState<number | false>(getSelectedTab());
  
  useEffect(() => {
    const tabValue = getSelectedTab();
    setValue(tabValue);
    const index = categories.findIndex((category) => category.label === diseaseType);
  }, [location.pathname]);
  

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleMenuOpen = (event: MouseEvent<HTMLDivElement>, index: number) => {
    setAnchorEl(event.currentTarget);
    setValue(index + 1);
    setSelectedDisease(categories[index].label);
  };

  const handleDiseaseCategoryChange = (selectedDiseaseCategory: string) => {
    dispatch(resetFilters());
    dispatch(updateDiseaseCategory(selectedDiseaseCategory));
  };

  const handleDiseaseTypeChange = (selectedDiseaseType: string) => {
    dispatch(resetFilters());
    dispatch(updateDiseaseType(selectedDiseaseType));
  };

  const handleNavigation = (category: string, route: string) => {
    if (category !== "Inflammatory Bowel Disease") {
      handleDiseaseCategoryChange("Neurodegenerative Disease");
      handleDiseaseTypeChange(category);
    } else {
      handleDiseaseCategoryChange(category);
    }

    navigate(route);
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
   
<>

      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="Disease Tabs"
        variant="scrollable"
      >
        <Tab
        
          label={
            <Typography variant="h5" sx={{fontWeight:'500',marginLeft:'-10px'}}>
              Summary
            </Typography>
          }
          sx={{
            height: "15px",
            bgcolor: value === 0 ? "white" : "transparent",
            "&.Mui-selected": { bgcolor: "white", color: "#000" },
          }}
          
          onClick={() => navigate("/publicly-curated-datasets")}
          
        />
        {categories.map((category, index) => (
          <Tab
            key={index}
            label={
              <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <Typography variant="h5" sx={{fontWeight:'500'}}>
                  {category.label}
                   {category.subMenu && <img src={Arrow} alt="Dropdown Arrow" width="24" height="24" />}
                </Typography>
              </div>
            }
            onClick={
              category.subMenu
                ? (event) => handleMenuOpen(event, index)
                : () => navigate(category.route)
            }
            sx={{
              height:'15px',
              bgcolor: value === index+1 ? "white" : "transparent",
              "&.Mui-selected": { bgcolor: "white", color: "#000"},
            }}
          />


        ))}
      </Tabs>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className="font-og"
        sx={{
          mt:1,
          "& .MuiPaper-root": {
            borderRadius: "12px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "10px",
            minWidth: "220px",
          },
        }}
      >
        <MenuItem
          disabled
          sx={{
            fontWeight: 600,
            color: "blue",
            fontSize: "14px",
            fontStyle: "normal",
            lineHeight: "23px",
            letterSpacing: "0.28px",
            paddingBottom: "8px",
            textTransform: "uppercase",
            marginTop: "1px",
          }}
        >
          SINGLE-CELL SEQUENCING
        </MenuItem>
        {subMenuItems.map((item, idx) => (
          <MenuItem
            key={idx}
            onClick={() => handleNavigation(selectedDisease || "", item.route)}
            className="font-og"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              borderRadius: "8px",
              fontWeight: 600,
              lineHeight: "23px",
              letterSpacing: "-0.01em",
              textAlign: "left",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
              "&:hover": { bgcolor: "#F0F0FF" },
              marginTop: "4px",
            }}
          >
            <img src={item.icon} alt={item.label} width="10" height="16" />
            <Typography variant="body2" sx={{ fontSize: "16px" }}>
              {item.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      </>
  );
};

export default DiseaseTabs;
