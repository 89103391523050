import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar, SnackbarContent ,Grid} from "@mui/material";
import { Fragment, useState, useCallback  } from "react";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { selectDiseaseCategory } from "../store/slices/diseaseCategorySlice";
import Download_icon from "../images/Download.svg"
type SnackbarType = 'success' | 'error';

function TableDownloadButton ({setOpenDialog, selectedRows, curation}: any) {
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const diseaseCategory = useSelector(selectDiseaseCategory);
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [nameError, setNameError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [snackbar, setSnackbar] = useState<{ open: boolean, message: string, type: SnackbarType }>({ open: false, message: "", type: 'success' });

    const handleDownloadSubmit = async () => {

        let hasError = false;
        if (!name) {
            setNameError("Please enter your name.");
            hasError = true;
        } else {
            setNameError(null);
        }
        if (!email) {
            setEmailError("Please enter your email address.");
            hasError = true;
        } else if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
            hasError = true;
        } else {
            setEmailError(null);
        }

        if (hasError) {
            return;
        }

        try {
            setOpenDownloadDialog(false);
            let query = `/api/local_document_download?DiseaseCategory=${encodeURIComponent(diseaseCategory)}&To_Name=${encodeURIComponent(name)}&To_Email_Address=${encodeURIComponent(email)}&`;
            if (curation === "sample") {
                const rows: any = Array.from(new Set(Object.values(selectedRows)));
                rows.forEach((GEOAccession: string) => {
                    query += `GEOAccession=${GEOAccession}&`;
                });
            } else {
                selectedRows.forEach((GEOAccession: string) => {
                    query += `GEOAccession=${GEOAccession}&`;
                });
            }
            query = query.slice(0, -1);

            const response = await axiosConfig.get(query, { responseType: 'blob' });

            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "curation.zip");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);

                setSnackbar({ open: true, message: 'Download successful!.', type: 'success' });
            } else {
                setSnackbar({ open: true, message: 'Download failed. Please try again.', type: 'error' });
            }
        } catch (error) {
            handleDownloadError(error);
            setSnackbar({ open: true, message: 'An error occurred. Please try again later.', type: 'error' });
        } finally {
            setName("");
            setEmail("");
        }
    };

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleDownloadError = async (error: any) => {
        try {
            const errdata = JSON.parse(await new Blob([error.response.data]).text());
            if (errdata.msg === "Token has been revoked") {
                handleLogout(dispatch, navigate);
            } else if (errdata.msg === "Token has expired") {
                const response = await handleRefresh(loggedUser.refreshToken);
                refreshAccessToken(dispatch, loggedUser, response);
                document.getElementById(String("download_xlsx"))?.click();
            }
        } catch {
            if (error.response?.data?.msg === "Token has expired") {
                handleLogout(dispatch, navigate);
            }
        }
    };

    const handleCloseSnackbar = useCallback(() => {
        setSnackbar(prev => ({ ...prev, open: false }));
    }, []);

    return (
        <Fragment>
            <Button id="download_xlsx" size="small"  style={{width: "11vw", minWidth:'max(100px,8vw)',padding:'5px 6px',color: "#FFFFFF", textTransform: 'none', backgroundColor:"#364098",border:"2px solid #364098",borderRadius:"10px",height:"38px",fontSize:"14px",fontWeight:"500",display:"flex",alignItems:"center" }}
            onClick={() => {
             if ((curation === 'sample' && Object.keys(selectedRows).length === 0) || selectedRows.length === 0) {
               setOpenDialog(true); 
            } else {
                setOpenDownloadDialog(true); 
             }
          }}>
          <img src={Download_icon} alt="download" style={{padding:"4px"}}></img>

            Download
            </Button>

            <Dialog open={openDownloadDialog} onClose={() => setOpenDownloadDialog(false)} sx={{"& .MuiPaper-root": { border: "1px solid #E2E5F9",borderRadius: "16px",width:"600px"}}}>
                <DialogTitle sx={{ textAlign: 'center', fontWeight: '600', color: 'black',fontSize:"25px" }}>Download File</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField autoFocus margin="dense" label="Name" type="text" fullWidth  value={name}  onChange={(e) => setName(e.target.value)} error={!!nameError} helperText={nameError}  required sx={{borderRadius:"12px"}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Email"  type="email"   fullWidth   value={email}  onChange={(e) => setEmail(e.target.value)}   error={!!emailError}   helperText={emailError} required sx={{borderRadius:"12px"}}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
                    <Button onClick={() => setOpenDownloadDialog(false)} color="primary" sx={{backgroundColor:"#FFFFFF",border:"2px solid #364098",borderRadius:"10px",color:"#364098",width:"50px",margin:"5px",textTransform: "none"}}>
                        Cancel
                    </Button>
                    <Button onClick={handleDownloadSubmit} sx={{backgroundColor:"#364098",border:"2px solid #364098",borderRadius:"10px",color:"#FFFFFF",textTransform: "none","&:hover": {backgroundColor: "#2c357f", borderColor: "#2c357f",color: "#FFFFFF"}}}   >
                        Submit & Download
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbar.open} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                <SnackbarContent
                    style={{
                        backgroundColor: snackbar.type === 'success' ? '#4caf50' : '#f44336',
                        color: 'white',
                        fontWeight: 'bold',
                    }}
                    message={snackbar.message}
                />
            </Snackbar>
        </Fragment>
    );
}

export default TableDownloadButton;