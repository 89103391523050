import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@mui/material";

function RowSelectionDialog({openDialog, setOpenDialog}: any) {
    return (
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} sx={{"& .MuiPaper-root": { border: "1px solid #E2E5F9",borderRadius: "16px",width:"600px",padding:'10px'}
        }}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div>
            <DialogContent>
                <DialogContentText id="alertdialogtext">
                <p style={{fontWeight:'600',fontSize:'26px',color:'#000000'}}>
                No rows selected.
      </p>
      <p style={{fontWeight:'400',fontSize:'18px',color:'#242424'}}>
      Please select row(s) to continue
      </p>
     
      
                </DialogContentText>
            </DialogContent>
            </div>
            <div style={{margin:"20px"}}>
            <DialogActions>
                <Button style={{ backgroundColor:'#364098', color:'white', fontWeight: 'bold',borderRadius:"10px",width:"133px",height:"48px",textTransform:"none"}} onClick={() => setOpenDialog(false)}>Okay</Button>
            </DialogActions>
            </div>
        </div>
        </Dialog>
    );
}

export default RowSelectionDialog;