import { Link } from "react-router-dom";
import aboutmission from "../images/aboutmission.png";
import arrowR from "../images/arrow-r.svg";
import bg from "../images/bg.png";
import c from "../images/c.svg";
import d from "../images/d.svg";
import faq from "../images/faq.png";
import mv from "../images/mv.svg";
import q from "../images/q.png";
import r from "../images/r.svg";
import secondBg from "../images/second-bg.png";
import landingBgg from "../images/landing-bgg.png";
import { updateDiseaseCategory } from '../store/slices/diseaseCategorySlice';
import { useDispatch,useSelector} from 'react-redux';
import { resetFilters } from '../store/slices/filtersSlice';
import { useEffect,useState } from "react";
import { axiosConfig } from "../axiosConfig";

const Landing_Features = () => {

    const [sampleCount, setSampleCount] = useState<number | null>(null);
    const [cellCount, setCellCount] = useState<number | null>(null);
    const [cellTypeCount, setCellTypeCount] = useState<number | null>(null);
    const dispatch =useDispatch();
    
    const handleDiseaseCategoryChange = (selectedDiseaseCategory: string) => {
        dispatch(resetFilters());
        dispatch(updateDiseaseCategory(selectedDiseaseCategory));
    };
    useEffect(() => {
        dispatch(resetFilters());
    }, [dispatch]); 

    let activeAccordion: string | null = null;

    const fetchFooterDetails = async () =>
            {
            let query = 'api/public_dataset_details?page_name=Public Dataset';
            try {
                const response = await axiosConfig.get(query);
                const { no_of_samples, no_of_cells, no_of_distinct_cell_types } = response.data.data;
                setSampleCount(no_of_samples);
                setCellCount(no_of_cells);
                setCellTypeCount(no_of_distinct_cell_types);
            } catch (error: any) {
                console.error("Failed to fetch footer details:", error.message);
            }
        }

      useEffect(() => {
            fetchFooterDetails();
        }, []);

    function toggleAccordion(id:string) {

        const content = document.querySelector(`[data-accordion="${id}"] .content`) as HTMLElement;
        const line = document.querySelector(`[data-accordion="${id}"] .line`) as HTMLElement;
        const lineRotated = document.querySelector(`[data-accordion="${id}"] .line-rotated`) as HTMLElement;

        if (!content || !line || !lineRotated) {
            return;
        }

        if (activeAccordion && activeAccordion !== id) {
            const activeContent = document.querySelector(`[data-accordion="${activeAccordion}"] .content`) as HTMLElement;
            const activeLine = document.querySelector(`[data-accordion="${activeAccordion}"] .line`) as HTMLElement;
            const activeLineRotated = document.querySelector(`[data-accordion="${activeAccordion}"] .line-rotated`) as HTMLElement;

            if (activeContent && activeLine && activeLineRotated) {
                activeContent.style.display = 'none';
                activeLine.classList.remove('bg-[#090B0A]');
                activeLine.classList.add('bg-[#F14234]');
                activeLineRotated.style.opacity = '1';
            }
        }

        if (activeAccordion === id) {
            content.style.display = content.style.display === 'none' ? 'block' : 'none';
            line.classList.toggle('bg-[#090B0A]');
            line.classList.toggle('bg-[#F14234]');
            lineRotated.style.opacity = lineRotated.style.opacity === '0' ? '1' : '0';
            activeAccordion = content.style.display === 'none' ? null : id;
        } else {
            content.style.display = 'block';
            line.classList.add('bg-[#090B0A]');
            line.classList.remove('bg-[#F14234]');
            lineRotated.style.opacity = '0';
            activeAccordion = id;
        }
}



    return(

        <> 
       
        {/* <!-- Landing section --> */}
        <section className="about-strand font-og relative w-screen overflow-x-hidden">
            <img src={landingBgg} className="absolute top-1/2 right-0 w-[50vw]  translate-x-1/4 -translate-y-[1vw] md:hidden" alt="landingBgg"/>
            <div className="about-strand absolute bg-[#F7F4FF] w-[45.5%] h-[calc(100%-2.5vw)] right-0 bottom-0 z-0 hidden md:block">
                <img src={landingBgg} className="absolute top-0 right-0 w-[30vw] -translate-y-[20%] translate-x-1/4" alt="landingBgg"/>
            </div>
            <div className="about-strand pb-[15vw] md:pb-[3.5vw] pt-[10vw] md:pt-[0vw] px-[5.25vw] relative z-[1]">
                <div className="about-strand flex flex-col gap-[1vw]">
                    <div className="about-strand pt-[6vw]">
                        <h1 className="about-strand text-[#191919] text-[13.5vw] md:text-[8.15vw] leading-[.875] font-[550] tracking-tight md:w-[60%]">Strand’s scRNA Portal</h1>
                    </div>
                    <div className="about-strand text-[3.75vw] md:text-[1.55vw] text-[#F14234] pt-[3vw] md:pt-[0vw] font-[325]">
                        Refining Precision Research, One Cell at a Time
                    </div>
                </div>

                <div className="about-strand md:items-end md:justify-between md:flex-row flex flex-col mt-[10vw] md:mt-[-6vw]">
                    <div className="about-strand md:w-[36%]">
                        <div>
                            <p className="about-strand text-[3.5vw] md:text-[1.235vw] text-[#363535] font-[350] leading-[1.3]">This portal is your gateway to <span className="font-[500]">meticulously collected single-cell RNA sequencing data</span> empowering precision research.</p>
                            <p className="about-strand text-[3.5vw] md:text-[1.235vw] text-[#363535] font-[350] leading-[1.3] mt-[3vw] md:mt-[1vw]">This platform offers <span className="font-[500]">harmonized single-cell RNA sequencing datasets</span> focused on complex diseases with significant unmet clinical needs, including <span className="font-[500]">Ulcerative Colitis, Crohn's Disease, Alzheimer’s Disease, Parkinson's Disease</span> and <span className="font-[500]">Frontotemporal Dementia.</span></p>   
                        </div>

                        <button data-modal-target="bookModal" className="about-strand open-modal-button flex items-center mt-[8vw] md:mt-[2vw]">
                            <div className="about-strand flex items-center rounded-full border-[1.5px] border-[#191919] text-[#FFFFFF] h-[7vw] md:h-[2.65vw] bg-[#191919] font-og text-[3.05vw] md:text-[1.05vw] leading-[1] px-[3vw] py-[1vw] md:px-[1vw] md:py-[.65vw]">
                                <p className="about-strand">BOOK A DEMO</p>
                            </div>
                            <div className="about-strand bg-[#191919] h-[1.5px] md:h-[2.5px] w-[7vw] md:w-[4.75vw]"></div>
                            <div className="about-strand rounded-full aspect-square flex items-center justify-center bg-[#191919] h-[7vw] md:h-[2.65vw]">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-white w-[3vw] md:w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                    <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                        <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                        <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                        <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                    </g>
                                </svg>
                            </div>
                        </button>
                    </div>
                    <div className="md:w-[40%] md:mt-0 mt-[10vw]">
                        <div className="grid grid-cols-3">
                            <div className="relative border border-[#EBA78C] aspect-[16/16.5] col-start-2">
                                <div className="absolute inset-0 z-0">
                                    <div className="absolute top-0 left-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full -translate-y-1/2 -translate-x-1/2"></div>
                                    <div className="absolute top-0 right-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full -translate-y-1/2 translate-x-1/2"></div>
                                </div>
                                <div className="relative z-[1] p-[2vw] md:p-[1vw] pt-[3vw] md:pt-[1.25vw] h-full">
                                    <div className="flex flex-col justify-between h-full">
                                        <div className="text-right font-[350] text-[3vw] md:text-[1vw] leading-[1.09] text-[#1D1D1D]">Total No. of<br/>Samples</div>
                                        <div className="flex">
                                        {sampleCount !== null ? ( <p className="text-[#F24134] text-[11vw] md:text-[4.5vw] leading-[1] flex"> {Math.floor((sampleCount??0) / 100)*100}<sup className="-top-[.35vw] -translate-x-[1vw] text-[5vw] md:text-[2.5vw]">+</sup></p>):<p></p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative border border-[#EBA78C] aspect-[16/16.5] row-start-2">
                                <div className="absolute inset-0 z-0">
                                    <div className="absolute top-0 left-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full -translate-y-1/2 -translate-x-1/2"></div>
                                    <div className="absolute top-0 right-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full -translate-y-1/2 translate-x-1/2"></div>
                                    <div className="absolute bottom-0 right-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full translate-y-1/2 translate-x-1/2"></div>
                                    <div className="absolute bottom-0 left-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full translate-y-1/2 -translate-x-1/2"></div>
                                </div>
                                <div className="relative z-[1] p-[2vw] md:p-[1vw] pt-[3vw] md:pt-[1.25vw] h-full">
                                    <div className="flex flex-col justify-between h-full">
                                        <div className="text-right font-[350] text-[3vw] md:text-[1vw] leading-[1.09] text-[#1D1D1D]">Total No. of Cell Types</div>
                                        <div className="flex">
                                        {cellTypeCount!==null ? (<p className="text-[#F24134] text-[11vw] md:text-[4.5vw] leading-[1] flex"> {Math.floor((cellTypeCount??0)/ 50) * 50}  <sup className="-top-[.35vw] -translate-x-[1vw] text-[5vw] md:text-[2.5vw]">+</sup></p>):<p></p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative border border-[#EBA78C] aspect-[16/16.5] col-start-3 row-start-2">
                                <div className="absolute inset-0 z-0">
                                    <div className="absolute top-0 left-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full -translate-y-1/2 -translate-x-1/2"></div>
                                    <div className="absolute top-0 right-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full -translate-y-1/2 translate-x-1/2"></div>
                                    <div className="absolute bottom-0 right-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full translate-y-1/2 translate-x-1/2"></div>
                                    <div className="absolute bottom-0 left-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full translate-y-1/2 -translate-x-1/2"></div>
                                </div>
                                <div className="relative z-[1] p-[2vw] md:p-[1vw] pt-[3vw] md:pt-[1.25vw] h-full">
                                    <div className="flex flex-col justify-between h-full">
                                        <div className="text-right font-[350] text-[3vw] md:text-[1vw] leading-[1.09] text-[#1D1D1D]">Total No. Of <br/>Cells</div>
                                        <div className="flex">
                                        {cellCount!==null ? (<p className="text-[#F24134] text-[11vw] md:text-[4.5vw] leading-[1] flex">  {Math.floor((cellCount??0) / 1_000_000)}M<sup className="-top-[.35vw] text-[5vw] md:text-[2.5vw]">+</sup></p>):<p></p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        {/* <!-- Second section --> */}
        <section className="bg-[#FAFAFA] md:pt-[13.5vw] py-[15vw] md:pb-[11vw] relative px-[5vw] md:px-0">
            <div className="bg-[#E7EDF2] w-[36.8vw] h-[37vw] absolute bottom-0 left-0 z-0 hidden md:block"></div>
            <img className="absolute left-0 top-[19%] w-[15.5vw] md:block hidden" src={secondBg} alt="bg-img"/>
            <div className="md:flex-row flex flex-col md:pl-[25vw] md:pr-[7vw]">
                <div className="">
                    <p className="text-[#191919] font-og md:text-[5.8vw] text-[10vw] font-medium leading-[.95] relative z-[3]"><span className="text-[#F14234]">Your Data Partner </span> for accessible scRNA sequencing data</p>
                </div>

                <div className="flex flex-col md:mt-[4vw] mt-[8vw]">
                    <div className=" flex flex-col">
                        <p className="text-[#2B2929] font-og font-light md:text-[1.05vw] text-[3.5vw] md:leading-[1.3] leading-[1.5] md:w-[85%]">We curate publicly available datasets and offer custom curation for datasets of your interest. We harness datasets from public repositories and harmonize them using <span className="font-medium">internationally recognized ontologies</span> and <span className="font-medium">controlled vocabularies</span> to <span className="font-medium">enhance data usability</span> for <span className="font-medium">downstream applications</span>. Each dataset has been curated from NCBI’s GEO platform.</p>
                        <p className="text-[#2B2929] font-og font-light md:text-[1.05vw] text-[3.5vw]  md:leading-[1.3] leading-[1.5] md:mt-[1vw] mt-[5vw] md:w-[78%] ">Our mission is to facilitate innovative research by providing accessible, high-quality data that drives precision insights and advances our understanding of complex diseases.</p>
                    </div>
                    <button data-modal-target="signupModal" className="open-modal-button flex items-center md:mt-[6vw] mt-[8vw]">
                        <div className="about-strand flex items-center rounded-full border-[1.5px] border-[#191919] text-[#FFFFFF] h-[7vw] md:h-[2.65vw] bg-[#191919] font-og text-[3.05vw] md:text-[1.05vw] leading-[1] px-[3vw] py-[1vw] md:px-[1vw] md:py-[.65vw]">
                            <p>SIGN UP TO OUR PORTAL</p>
                        </div>
                        <div className="bg-[#191919] h-[1.5px] md:h-[2.5px] w-[7vw] md:w-[4.75vw]"></div>
                        <div className="rounded-full aspect-square flex items-center justify-center bg-[#191919] h-[7vw] md:h-[2.65vw]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-white w-[3vw] md:w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                    <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                    <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                    <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                </g>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </section>


         {/* <!-- Key Features Section --> */}
        <section className="px-[5.25vw] md:pt-[7vw] py-[15vw] md:pb-[8vw] relative">
            <div className="bg-[#EFE7F3] md:h-[22vw] h-[240vw] w-full absolute left-0 bottom-0 z-0">
            </div>
            <div className="relative z-[5]">
                <div className="flex items-center gap-[2vw]">
                    <p className="text-[#090B0A] font-og font-medium md:text-[5.9vw] text-[10vw] ">Key features</p>
                    <div className="bg-[#F14234] md:w-[8vw] w-[15vw] h-[.5vw] md:h-[.18vw]"></div>
                </div>
                <div className="grid md:grid-cols-3 md:gap-[1.5vw] gap-[5vw] md:px-[3.2vw] px-[5vw] mt-[6.5vw]">
                   
                    <div className="border-[#484543] bg-[white] border md:rounded-[1.5vw] rounded-[4vw] md:px-[1.5vw] px-[3vw] md:pt-[1vw] pt-[5vw] md:pb-[2.25vw] pb-[7vw] flex flex-col">
                        <div>
                            <div className="flex justify-between">
                                <p className="text-[#090B0A] font-og font-light md:text-[1.30vw] text-[5vw]">01</p>
                                <img src={arrowR} className="md:w-[2vw] w-[8vw] mt-[1vw]" alt=""/>
                            </div>
                            <div className="flex gap-[.5vw] items-baseline  pl-[.5vw] border-[#E5E5E5] md:mt-0 mt-[5vw] border-b-[1.5px] md:pb-[2vw] pb-[6vw]">
                                <img src={d} className="md:w-[3.5vw] w-[8vw]" alt="c"/>
                            </div>
                        </div>
                        <div className="flex flex-col flex-1">
                            <div className="md:mt-[4vw] mt-[8vw] px-[1vw]">
                                <p className="text-[#090B0A] font-og font-normal md:text-[2.7vw] text-[8vw] ">Datasets</p>
                                <p className="font-og font-light text-[#2B2929] md:text-[1.15vw] text-[3.5vw] leading-[1.3] md:mt-[1vw] mt-[2vw] ">Meticulous metadata curation and harmonization in 3 disease areas, with over 80+ metadata fields spanning 3 levels of curation.</p>
                            </div>
                            <div className="mt-auto">
                                <Link to={"/study-table"}  onClick={() => handleDiseaseCategoryChange("Inflammatory Bowel Disease")} className="flex items-center md:mt-[2vw] mt-[5vw] px-[1vw]">
                                    <div className="flex items-center rounded-full border border-[#090B0A] text-[#090B0A] md:h-[2.25vw] h-[7vw] bg-white font-og font-medium md:text-[.8vw] text-[3vw] leading-[1] md:px-[1.5vw] px-[4vw] md:py-[.65vw] py-[2vw]">
                                        <p>SEARCH DATABASE</p>
                                    </div>
                                    <div className="bg-[#191919] h-[2px] md:w-[1vw] w-[6vw]"></div>
                                    <div className="rounded-full aspect-square flex items-center justify-center bg-white border border-[#090B0A]  md:h-[2.25vw] h-[7vw]">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-[#F14234] md:w-[.8vw] w-[3vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                            <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                                <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                                <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                                <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                            </g>
                                        </svg>
                                    </div>
                                </Link>
                            </div>
                        </div>

                    </div>
                    <div className="border-[#484543] bg-[white] border md:rounded-[1.5vw] rounded-[4vw] md:px-[1.5vw] px-[3vw] md:pt-[1vw] pt-[5vw] md:pb-[2.25vw] pb-[7vw] flex flex-col">
                        <div>
                            <div className="flex justify-between">
                                <p className="text-[#090B0A] font-og font-light md:text-[1.30vw] text-[5vw]">02</p>
                                <img src={arrowR} className="md:w-[2vw] w-[8vw] mt-[1vw]" alt=""/>
                            </div>
                            <div className="flex gap-[.5vw] items-baseline md:mt-0 mt-[5vw]  pl-[.5vw] border-[#E5E5E5] border-b-[1.5px]  md:pb-[2vw] pb-[6vw]">
                                <img src={mv}className="md:w-[7.25vw] w-[15vw]" alt="c"/>
                            </div>
                        </div>
                        <div className="flex flex-col flex-1">
                            <div className="md:mt-[2.5vw] mt-[8vw] px-[1vw]">
                                <p className="text-[#090B0A] font-og font-normal md:text-[2.7vw] text-[8vw] leading-[1] ">Metadata Visualization</p>
                                <p className="font-og font-light text-[#2B2929] md:text-[1.15vw] text-[3.5vw] leading-[1.3] md:mt-[1vw] mt-[2vw] ">Uncover hidden patterns, correlations and trends in the metadata using charts and graphs in various formats.</p>
                            </div>
                            <div className="mt-auto">
                                <Link to={"/metadata-visualization"} className="flex items-center md:mt-[2vw] mt-[5vw] px-[1vw]">
                                    <div className="flex items-center rounded-full border border-[#090B0A] text-[#090B0A] md:h-[2.25vw] h-[7vw] bg-white font-og font-medium md:text-[.8vw] text-[3vw] leading-[1] md:px-[1.5vw] px-[4vw] md:py-[.65vw] py-[2vw]">
                                        <p>EXPLORE</p>
                                    </div>
                                    <div className="bg-[#191919] h-[2px] md:w-[1vw] w-[6vw]"></div>
                                    <div className="rounded-full aspect-square flex items-center justify-center bg-white border border-[#090B0A]  md:h-[2.25vw] h-[7vw]">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-[#F14234] md:w-[.8vw] w-[3vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                            <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                                <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                                <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                                <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                            </g>
                                        </svg>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="border-[#484543] bg-[white] border md:rounded-[1.5vw] rounded-[4vw] md:px-[1.5vw] px-[3vw] md:pt-[1vw] pt-[5vw] md:pb-[2.25vw] pb-[7vw] flex flex-col">
                        <div>
                            <div className="flex justify-between">
                                <p className="text-[#090B0A] font-og font-light md:text-[1.30vw] text-[5vw]">03</p>
                                <img src={arrowR} className="md:w-[2vw] w-[8vw] mt-[1vw]" alt=""/>
                            </div>
                            <div className="flex gap-[.5vw] items-baseline  pl-[.5vw] border-[#E5E5E5] md:mt-0 mt-[5vw] border-b-[1.5px] md:pb-[2vw] pb-[6vw]">
                                <img src={c} className="md:w-[3.5vw] w-[8vw]" alt="c"/>
                                <img src={r} className="md:w-[1.5vw] w-[4vw]" alt="r"/>
                            </div>
                        </div>
                        <div className="flex flex-col flex-1">
                            <div className="md:mt-[4vw] mt-[8vw] px-[1vw]">
                                <p className="text-[#090B0A] font-og font-normal md:text-[2.7vw] text-[8vw] whitespace-nowrap ">Curation Requests</p>
                                <p className="font-og font-light text-[#2B2929] md:text-[1.15vw] text-[3.5vw] leading-[1.3] md:mt-[1vw] mt-[2vw] ">Submit a dataset of your choice for curation by our expert curation team.</p>
                            </div>
                            {/* <!-- Give the link to create curation request --> */}
                            <Link to= {"/create-curation-request"} className="flex items-center px-[1vw] mt-[5vw] md:mt-auto">
                                <div className="flex items-center rounded-full border border-[#090B0A] text-[#090B0A] md:h-[2.25vw] h-[7vw] bg-white font-og font-medium md:text-[.8vw] text-[3vw] leading-[1] md:px-[1.5vw] px-[4vw] md:py-[.65vw] py-[2vw]">
                                    <p>MAKE A REQUEST</p>
                                </div>
                                <div className="bg-[#191919] h-[2px] md:w-[1vw] w-[6vw]"></div>
                                <div className="rounded-full aspect-square flex items-center justify-center bg-white border border-[#090B0A]  md:h-[2.25vw] h-[7vw]">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-[#F14234] md:w-[.8vw] w-[3vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                        <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                            <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                            <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                            <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                        </g>
                                    </svg>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- Metadata Curation Section --> */}
        <section className="about-strand md:pt-[7vw] py-[15vw] md:pb-[8vw] relative font-og">
            <div className="about-strand px-[5.25vw]">
                <div className="about-strand flex items-center gap-[5vw]  md:gap-[2vw]">
                    <p className="about-strand text-[#090B0A] font-og font-medium md:text-[5.9vw] text-[9.3vw] leading-[1]">Metadata Curation</p>
                    <div className="about-strand bg-[#F14234] md:w-[8vw] w-[10vw] h-[.5vw] md:h-[.18vw]"></div>
                </div>
            </div>

            <div className="about-strand md:mt-[2vw] mt-[7vw] flex md:flex-row flex-col md:items-end md:justify-between gap-[15vw] md:gap-0 md:pr-[8vw] pl-[5vw] md:px-0">
                <div className="about-strand bg-[#F7F7F7] md:pr-[2vw] md:pl-[9.25vw] px-[4vw] md:px-0 md:py-[4.5vw] py-[10vw] md:w-[58%]">
                    <div className="about-strand flex flex-col md:gap-[1.5vw] gap-[3vw]">
                        <div className="about-strand flex items-start md:gap-[.75vw] gap-[3vw]">
                            <div className="about-strand md:w-[.75vw] w-[1.5vw] shrink-0 aspect-square bg-[#F24134] rounded-full md:mt-[.45vw] mt-[1.8vw]"></div>
                            <p className="about-strand text-[3.5vw] md:text-[1.2vw] font-[350]">This is a <span className="font-[500]">disease-focused portal</span> solving a key challenge in dataset acquisition for biomedical research. We artfully extract datasets from public repositories using customized keywords.</p>
                        </div>
                        <div className="about-strand flex items-start md:gap-[.75vw] gap-[3vw]">
                            <div className="about-strand md:w-[.75vw] w-[1.5vw] shrink-0 aspect-square bg-[#F24134] rounded-full md:mt-[.45vw] mt-[1.8vw]"></div>
                            <p className="about-strand text-[3.5vw] md:text-[1.2vw] font-[350]">Data is standardized and harmonized using <span className="font-[500]">internationally recognized ontologies </span>and<span className="font-[500]"> vocabularies.</span></p>
                        </div>
                        <div className="about-strand flex items-start md:gap-[.75vw] gap-[3vw]">
                            <div className="about-strand md:w-[.75vw] w-[1.5vw] shrink-0 aspect-square bg-[#F24134] rounded-full md:mt-[.45vw] mt-[1.8vw]"></div>
                            <p className="about-strand text-[3.5vw] md:text-[1.2vw] font-[350]">We offer extensive metadata curation with <span className="font-[500]">80+ metadata fields spanning 3 levels of curation:</span> study, experiment, and sample. We can customize metadata fields as needed to provide the right inputs for your research.</p>
                        </div>
                        <div className="about-strand flex items-start md:gap-[.75vw] gap-[3vw]">
                            <div className="about-strand md:w-[.75vw] w-[1.5vw] shrink-0 aspect-square bg-[#F24134] rounded-full md:mt-[.45vw] mt-[1.8vw]"></div>
                            <p className="about-strand text-[3.5vw] md:text-[1.2vw] font-[350]">The search feature on our portal helps users <span className="font-[500]">browse using specific keywords</span>. There are 26 filters to help narrow down to a dataset that meets your requirement.</p>
                        </div>

                        <div className="about-strand flex items-start md:gap-[.75vw] gap-[3vw]">
                            <div className="about-strand md:w-[.75vw] w-[1.5vw] shrink-0 aspect-square bg-[#F24134] rounded-full md:mt-[.45vw] mt-[1.8vw]"></div>
                            <p className="about-strand text-[3.5vw] md:text-[1.2vw] font-[350]">These datasets can be <span className="font-[500]">combined with the Strand Bioinformatics tools </span> that support scRNA sequencing analysis, bulk RNA sequencing and multiomics analysis for valuable holistic insights.</p>
                        </div>
                    </div>
                    <div className="about-strand md:mt-[4vw] mt-[7vw] pl-[4vw] md:pl-0">
                        <Link to={"/study-table"} onClick={() => handleDiseaseCategoryChange("Inflammatory Bowel Disease")} className="flex items-center mt-[8vw] md:mt-[2vw]">
                            <div className="about-strand flex items-center rounded-full border-[1.5px] border-[#191919] text-[#FFFFFF] h-[7vw] md:h-[2.65vw] bg-[#191919] font-og text-[3.05vw] md:text-[1.05vw] leading-[1] px-[3vw] py-[1vw] md:px-[1vw] md:py-[.65vw]">
                                <p>EXPLORE DATASETS</p>
                            </div>
                            <div className="about-strand bg-[#191919] h-[1.5px] md:h-[2.5px] w-[7vw] md:w-[4.75vw]"></div>
                            <div className="about-strand rounded-full aspect-square flex items-center justify-center bg-[#191919] h-[7vw] md:h-[2.65vw]">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-white w-[3vw] md:w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                    <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                        <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                        <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                        <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                    </g>
                                </svg>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="about-strand md:w-[30%] pr-[5.25vw] md:pr-0">
                    <div className="about-strand border-[.75px] border-black rounded-[2vw] md:p-[1.5vw] p-[5vw]">
                        <div className="about-strand border-b border-[#E5E5E5] md:pb-[2vw] pb-[5vw]">
                            <p className="about-strand md:text-[2.5vw] text-[6vw] leading-[1] font-[325] tracking-tight w-[80%]">Features of the Database</p>
                        </div>
                        <div className="about-strand md:pt-[3vw] pt-[6vw]">
                            <div className="about-strand flex flex-col md:space-y-[1.25vw] space-y-[2.5vw] pr-[3vw] pb-[2.5vw]">
                                <div className="about-strand flex md:gap-[.8vw] gap-[3vw] items-start">
                                    <img src={arrowR} className="md:w-[1.25vw] w-[3vw] rotate-45" alt="arrow"/>
                                    <p className="about-strand text-[#1D1D1D] md:text-[1.15vw] text-[3.65vw] font-og font-light leading-[1.2]">Custom Keywords To Harness Relevant Datasets</p>
                                </div>
                                <div className="about-strand flex md:gap-[.8vw] gap-[3vw] items-start">
                                    <img src={arrowR}  className="md:w-[1.25vw] w-[3vw] rotate-45" alt="arrow"/>
                                    <p className="about-strand text-[#1D1D1D] md:text-[1.15vw] text-[3.65vw] font-og font-light leading-[1.1]">3 Levels Of Curation</p>
                                </div>
                                <div className="about-strand flex md:gap-[.8vw] gap-[3vw] items-start ">
                                    <img src={arrowR} className="md:w-[1.25vw] w-[3vw] rotate-45" alt="arrow"/>
                                    <p className="about-strand text-[#1D1D1D] md:text-[1.15vw] text-[3.65vw] font-og font-light leading-[1.1]">80+ Metadata Fields</p>
                                </div>
                                <div className="about-strand flex md:gap-[.8vw] gap-[3vw] items-start">
                                    <img src={arrowR} className="about-strand md:w-[1.25vw] w-[3vw] rotate-45" alt="arrow"/>
                                    <p className="about-strand text-[#1D1D1D] md:text-[1.15vw] text-[3.65vw] font-og font-light leading-[1.2]">Standardization Using Recognised Ontologies & Vocabularies</p>
                                </div>

                                <div className="about-strand flex md:gap-[.8vw] gap-[3vw] items-start">
                                    <img src={arrowR} className="md:w-[1.25vw] w-[3vw] rotate-45" alt="arrow"/>
                                    <p className="about-strand text-[#1D1D1D] md:text-[1.15vw] text-[3.65vw] font-og font-light leading-[1.1]">Independent Curation & Review Process</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- About Strand Section --> */}
        <section className="about-strand md:pt-[5vw] py-[15vw] md:pb-[5vw] relative">

            <div className="relative z-[5]">
                <div className="about-strand flex items-center gap-[2vw] px-[5.25vw]">
                    <p className="text-[#090B0A] font-og font-medium md:text-[5.9vw] text-[10vw] ">About Strand</p>
                    <div className="bg-[#F14234] md:w-[8vw] w-[15vw] h-[.5vw] md:h-[.18vw]"></div>
                </div>
                <div className="about-strand pl-[10vw] md:px-0">
                    <div className="about-strand bg-[#E7F1F3] md:w-[88%] ml-auto md:pl-[10vw] px-[8vw] md:px-0 md:pt-[5vw] pt-[10vw] md:pb-[4vw] pb-[10vw] mt-[5vw] relative">
                        <img src={aboutmission} className="md:w-[26vw] w-[35vw] md:top-0 bottom-0 translate-y-[40%] md:-translate-y-[15%] md:absolute right-[5%] hidden md:block" alt="ceo"/>
                        <img src={bg} className="absolute z-0 md:w-[12vw] w-[20vw] left-0 top-[45%] -translate-x-[60%]" alt=""/>
                        <img src={q} className="md:w-[5vw] w-[10vw] absolute top-0 left-[6%] -translate-y-[35%]" alt=""/>
                        <div className="about-strand space-y-[4vw] md:space-y-[1.75vw] relative z-[1]">
                            <p className="text-[#090B0A] font-og font-[325] md:leading-[1.2] leading-[1.4] md:text-[1.5vw] text-[4vw] md:w-[55%]"><a href="https://us.strandls.com/" target="_blank" rel="noopener noreferrer"  className="underline">Strand Life Sciences</a> is a comprehensive bioinformatics company involved in genomic and transcriptomic research. Our focus is on improving the drug discovery process and precision medicine.</p>
                            <p className="text-[#090B0A] font-og font-[325] md:leading-[1.2] leading-[1.4] md:text-[1.5vw] text-[4vw] md:w-[55%]">Our team comprises over 300 highly trained curation scientists, software engineers, testing engineers, bioinformaticians, and clinical researchers. We have 23 years of experience in this domain.</p>
                            <p className="text-[#090B0A] font-og font-[325] md:leading-[1.2] leading-[1.4] md:text-[1.5vw] text-[4vw] md:w-[55%]">We are a Bangalore-based company with close ties with the Bay Area and San Diego, where many of our clientele are based.</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>

          {/* <!-- FAQ Section --> */}
          <section>
          <div className="flex flex-col-reverse md:flex-row gap-[3vw] font-og pb-[15vw] md:pb-0 pt-[10vw] md:pt-[5vw]">
                <div className="pl-[10vw] md:px-0 md:w-[62%]">
                    <div className="overflow-y-scroll  md:max-h-[35vh] lg:max-h-[90vh] relative bg-[#F7F7F7] mt-[5vw] pb-[3vw] rna-scrollbar">
                        <p className="md:px-[5.25vw] px-[5vw] py-[6vw] md:py-[2.5vw] leading-[1] font-[450] text-[5vw] md:text-[3vw] border-b bg-[#F7F7F7] z-[1] border-[#090B0A]/[.28] sticky top-0 left-0 ">
                            General Information
                        </p>
                        <div className="pl-[5.25vw] pr-[4.5vw] relative z-0">
                            <div className="relative w-full mx-auto overflow-hidden divide-y divide-[#090B0A]/[.28] rounded-md">
                                <div className="group cursor-pointer" data-accordion="accordion-1">
                                <button onClick={() => {toggleAccordion('accordion-1')}} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none ">
                                       <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">01</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">What is the purpose of this portal?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]  style={{ display: 'none' }}" style={{ display: 'none' }} >
                                        The portal curates and harmonizes scRNA-seq datasets from public repositories, enabling streamlined access and analysis for research purposes.
                                    </div>
                                </div>
                                <div className="group cursor-pointer" data-accordion="accordion-2">
                                    <button  onClick={() => toggleAccordion('accordion-2')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">02</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">Who can use this portal?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }}  >
                                        The portal is designed for researchers, bioinformaticians, and data scientists involved in single-cell research, biomarker discovery, target validation, foundational model building, etc.
                                    </div>
                                </div>
                                <div className="group cursor-pointer" data-accordion="accordion-3">
                                    <button  onClick={() => toggleAccordion('accordion-3')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">03</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">What types of datasets are available on the portal?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%] " style={{ display: 'none' }}  >
                                        The portal currently hosts scRNA-seq datasets focusing on specific disease areas such as Inflammatory Bowel Disease (Ulcerative colitis, Crohn’s disease) and Neurodegenerative Disorders (Alzheimer’s Disease, Fronto-temporal Dementia, Parkinson’s Disease).
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className="md:px-[5.25vw] px-[5vw] py-[6vw] md:py-[2.5vw] leading-[1] font-[450] text-[5vw] md:text-[3vw] border-y bg-[#F7F7F7] z-[1] border-[#090B0A]/[.28] sticky top-0 left-0">
                            Metadata Harmonization
                        </p>
                        <div className="pl-[5.25vw] pr-[4.5vw] relative z-0">
                            <div className="relative w-full mx-auto overflow-hidden divide-y divide-[#090B0A]/[.28] rounded-md">
                                <div className="group cursor-pointer" data-accordion="accordion-4">
                                    <button  onClick={() => toggleAccordion('accordion-4')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">04</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">What is metadata harmonization, and why is it important?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }}  >
                                        Metadata harmonization involves standardizing dataset annotations to ensure consistency and interoperability, facilitating downstream analysis and cross-dataset comparisons.
                                    </div>
                                </div>
                                <div className="group cursor-pointer" data-accordion="accordion-5">
                                    <button  onClick={() => toggleAccordion('accordion-5')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">05</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">What metadata fields are harmonized on the portal?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }}  >
                                        The portal harmonizes an extensive set of 80+ metadata fields across study, experiment and sample levels, using controlled vocabularies and internationally recognized ontologies.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className="md:px-[5.25vw] px-[5vw] py-[6vw] md:py-[2.5vw] leading-[1] font-[450] text-[5vw] md:text-[3vw] border-y bg-[#F7F7F7] z-[1] border-[#090B0A]/[.28] sticky top-0 left-0">
                            Curation and Data Access
                        </p>
                        <div className="pl-[5.25vw] pr-[4.5vw] relative z-0">
                            <div className="relative w-full mx-auto overflow-hidden divide-y divide-[#090B0A]/[.28] rounded-md">
                                <div className="group cursor-pointer" data-accordion="accordion-6">
                                    <button  onClick={() => toggleAccordion('accordion-6')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">06</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">Where do the datasets come from?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }}  >
                                        The datasets are sourced from NCBI GEO.
                                    </div>
                                </div>
                                <div className="group cursor-pointer" data-accordion="accordion-7">
                                    <button  onClick={() => toggleAccordion('accordion-7')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">07</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">How frequently are new datasets added to the portal?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }}  >
                                        The portal is updated every month.
                                    </div>
                                </div>
                                <div className="group cursor-pointer" data-accordion="accordion-8">
                                    <button  onClick={() => toggleAccordion('accordion-8')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">08</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">What is the expected timeline for metadata curation of a requested dataset?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }}  >
                                        Our average turnaround time is 1-2 days.
                                    </div>
                                </div>
                                <div className="group cursor-pointer" data-accordion="accordion-9">
                                    <button  onClick={() => toggleAccordion('accordion-9')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">09</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">Is it possible to request curation for datasets not related to single-cell RNA sequencing or those outside the Inflammatory Bowel Disease and Neurodegenerative Disease categories?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }}  >
                                        Yes, although our current focus is on these areas, we welcome requests for datasets beyond this scope.
                                    </div>
                                </div>
                                <div className="group cursor-pointer" data-accordion="accordion-10">
                                    <button  onClick={() => toggleAccordion('accordion-10')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">10</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">Can I download curated metadata and data from the portal?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }}  >
                                        Yes, curated metadata can be downloaded from the portal. Currently, we do not host raw data, but we provide direct links to the dataset via our sample level curation page.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className="md:px-[5.25vw] px-[5vw] py-[6vw] md:py-[2.5vw] leading-[1] font-[450] text-[5vw] md:text-[3vw] border-y bg-[#F7F7F7] z-[1] border-[#090B0A]/[.28] sticky top-0 left-0">
                            Technical Features
                        </p>
                        <div className="pl-[5.25vw] pr-[4.5vw] relative z-0">
                            <div className="relative w-full mx-auto overflow-hidden divide-y divide-[#090B0A]/[.28] rounded-md">
                                <div className="group cursor-pointer" data-accordion="accordion-11">
                                    <button  onClick={() => toggleAccordion('accordion-11')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">11</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">Does the portal support data analysis?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }} >
                                        Our in-house pipelines are designed to perform analyses on datasets hosted on the portal. Although the portal doesn’t currently support on-platform analysis, users can reach out via the ‘Contact Us’ page for assistance.
                                    </div>
                                </div>
                                <div className="group cursor-pointer" data-accordion="accordion-12">
                                    <button  onClick={() => toggleAccordion('accordion-12')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">12</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">Can I upload my own datasets to the portal?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }}>
                                        External dataset upload and processing features are not yet available on the portal. However, our in-house pipelines can manage these requirements, with custom requests facilitated through secure FTP server transfers. Interested users can reach out via the ‘Contact us’ page.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className="md:px-[5.25vw] px-[5vw] py-[6vw] md:py-[2.5vw] leading-[1] font-[450] text-[5vw] md:text-[3vw] border-y bg-[#F7F7F7] z-[1] border-[#090B0A]/[.28] sticky top-0 left-0">
                            Applications
                        </p>
                        <div className="pl-[5.25vw] pr-[4.5vw] relative z-0">
                            <div className="relative w-full mx-auto overflow-hidden divide-y divide-[#090B0A]/[.28] rounded-md">
                                <div className="group cursor-pointer" data-accordion="accordion-13">
                                    <button  onClick={() => toggleAccordion('accordion-13')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">13</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">How does the portal help in drug discovery?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }} >
                                        Harmonized datasets accompanied with custom analysis can feed into a lot of application areas including biomarker discovery, target validation, drug repurposing, patient stratification, translational research, supporting companion diagnostics, generating cell atlas, building foundational models, etc.
                                    </div>
                                </div>
                                <div className="group cursor-pointer" data-accordion="accordion-14">
                                    <button  onClick={() => toggleAccordion('accordion-14')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">14</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">Can the portal be used for machine learning applications?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }} >
                                        Yes, harmonized datasets can be directly applied to training and validating AI/ML models.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className="md:px-[5.25vw] px-[5vw] py-[6vw] md:py-[2.5vw] leading-[1] font-[450] text-[5vw] md:text-[3vw] border-y bg-[#F7F7F7] z-[1] border-[#090B0A]/[.28] sticky top-0 left-0">
                            Support and Collaboration
                        </p>
                        <div className="pl-[5.25vw] pr-[4.5vw] relative z-0 md:pb-[19vw] lg:pb-[12vw]">
                            <div className="relative w-full mx-auto overflow-hidden divide-y divide-[#090B0A]/[.28] rounded-md">
                                <div className="group cursor-pointer" data-accordion="accordion-15">
                                    <button  onClick={() => toggleAccordion('accordion-15')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">15</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">How can I provide feedback on the portal?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }} >
                                        Users can submit feedback through the "Contact Us" section on the portal.
                                    </div>
                                </div>
                                <div className="group cursor-pointer" data-accordion="accordion-16">
                                    <button  onClick={() => toggleAccordion('accordion-16')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">16</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">Is collaboration with the portal team possible?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }}>
                                        Yes, the portal team welcomes collaborations for custom dataset curation, pipeline integration, or joint research projects.
                                    </div>
                                </div>
                                <div className="group cursor-pointer" data-accordion="accordion-17">
                                    <button  onClick={() => toggleAccordion('accordion-17')} className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[2vw] w-full text-left select-none">
                                        <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                                            <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">17</span>
                                            <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">Is there a user guide or tutorial available?</p>
                                        </div>
                                        <div className="relative">
                                            <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                                            <div className="line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 rotate-90 top-0"></div>
                                        </div>
                                    </button>
                                    <div className="content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%]" style={{ display: 'none' }}  >
                                        Schedule a demo with us for a customized walkthrough and get all your questions answered.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
              </div>
              <div className="md:w-[38%] pt-[2.5vw] relative px-[5vw] md:px-0">
                  <h2 className="text-[10vw] md:text-[5.9vw] text-[#090B0A] leading-[1] md:leading-[.92] font-[450] relative z-[0]">Frequently Asked Questions <span className="h-[2.5px] w-[10vw] md:w-[4vw] md:mb-[1.5vw] mb-[2vw] bg-[#F14234] inline-block"></span></h2>
                  <img src={faq} className="absolute right-0 z-[1] w-[25vw] hidden md:block" alt="Bg image"/>
              </div>
          </div>
          </section>

      </>
      
    );
};

export default Landing_Features;

