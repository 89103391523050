import { useLocation, useNavigate } from "react-router-dom";
import logo from "../companylogo.png"
import { useEffect } from "react";
import { FcOk } from "react-icons/fc";
import { Button } from "@mui/material";

function BookaDemoSuccess() {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (!location.state) {
            navigate('/');
        }
    });
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh', backgroundColor: 'white', margin: '-8px', paddingBottom: '50vh' }}>
            <div style={{ width:'78%', textAlign: 'left', backgroundColor: 'white', padding: '20px', paddingTop: '20px', paddingBottom: '25px', borderLeft: '0px solid #ccc' }}>
                <img style={{ width: '260px', height: '57.4px', marginLeft: '-8px' }} src={logo} alt="company logo"/>
                <p style={{ fontWeight: 'bold', color: 'seagreen', fontSize: '24px' }}><FcOk size={26} style={{marginBottom: '-4px'}}/>Thank you for booking a demo!</p>
                <p style={{ fontSize: '18px', textAlign: 'left' }}> We're excited to show you how we can help. Someone from our team will be in touch with you shortly to confirm the details and answer any questions you may have.</p>
                <p style={{fontSize: '18px'}}>Click on continue button below to Log in to scRNA Portal</p>
                <Button type="submit" variant="contained" style={{ cursor: 'pointer', height: 38, backgroundColor: 'seagreen', textTransform: 'none', fontSize: '20px', fontWeight: 'bold', marginTop: '5px' }} onClick={() => { navigate('/login'); }}>Continue</Button>
            </div>
        </div>
    );
}

export default BookaDemoSuccess;