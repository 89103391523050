import { Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../store/slices/userSlice";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import NonCurationTables from "../components/NonCurationTables";
import Download_img from "../images/Download_img.svg"
import Page_New from "./NoCuration_Requests";

function MyCurationRequests () {
    
    const dispatch = useDispatch();
     const apiParams = {
        page_name: "curation_request",  
    };

    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const navigate = useNavigate();
    const downloadCurationFile = async (order_id: number, filename: string) => {
        try {
            const response = await axiosConfig.get('/api/curation_file_download', { responseType: 'blob' , params: { "order_id": order_id } });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }catch(error: any) {
            try {
                const errdata = JSON.parse(await new Blob([error.response.data]).text());
                if (errdata.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (errdata.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    document.getElementById(String(order_id))?.click();
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };
    const config = [
        { Id: 'order_id', label: 'Order ID', render: (Datasets: any) => Datasets.order_id, isVisible: true, width: 53 },
        { Id: 'disease_category', label: 'Disease Category', render: (Datasets: any) => Datasets.disease_category, isVisible: true, width: 113 },
        { Id: 'affiliation', label: 'Affiliation', render: (Datasets: any) => Datasets.affiliation, isVisible: true, width: 64 },
        { Id: 'customized_curation', label: 'Customized Curation', render: (Datasets: any) => Datasets.customized_curation, isVisible: true, width: 135 },
        { Id: 'curation_file_name', label: 'Customized Metadata Fields', render: (Datasets: any) => Datasets.customized_curation === "Yes" ? <span id={String(Datasets.order_id)} style={{ textDecoration: 'none', color: '#0C73EB', cursor: 'pointer' }} onClick={() => downloadCurationFile(Datasets.order_id, Datasets.curation_file_name)}>{Datasets.curation_file_name}</span> : "-", isVisible: true, width: 183.5 },
        { Id: 'source_of_dataset', label: 'Source Of Dataset', render: (Datasets: any) => Datasets.source_of_dataset, isVisible: true, width: 117 },
        { Id: 'enter_url', label: 'Accession URL', render: (Datasets: any) => Datasets.source_of_dataset === "Publicly Available Datasets" ? <Link style={{textDecoration:'none', color: '#0C73EB'}} target="_blank" to={Datasets.enter_url}>Link</Link> : "-", isVisible: true, width: 97 },
        { Id: 'curation_status', label: 'Curation Status', render: (Datasets: { curation_status: string; CurationDownload: string }) => <Button size="medium" style={{ backgroundColor: { PENDING: '#BC5D5E', READY: '#3D986D', 'IN PROGRESS': '#CD9235' }[Datasets.curation_status], textTransform: 'none', height: '32px',borderRadius: '4px',fontWeight:'500',color:'white',alignItems:'center'}}>{Datasets.curation_status.charAt(0)+ Datasets.curation_status.toLowerCase().slice(1)}</Button>, isVisible: true, width: 100 },          
        { Id: 'CurationDownload', label: 'Curation Download', render: (Datasets: any) => <div ><Button size="medium" variant="outlined" disabled={Datasets.curation_status === "PENDING"} style={{ color: Datasets.curation_status==='PENDING'?'#969696':'#364098', textTransform: 'none', height: '30px', padding: '15px',border:Datasets.curation_status==='PENDING'?'1px solid #969696':'1px solid #364098' }}><Link style={{textDecoration:'none',pointerEvents: Datasets.curation_status === "PENDING" ? 'none' : 'auto', }} to={Datasets.CurationDownload}><img style={{marginRight:'5px',marginBottom:'2px'}}src={Download_img}></img>Download</Link></Button></div>, isVisible: true, width: 122 }
    ];


    return <>
    <NonCurationTables config={config} requestFor="order_table_read" breadcrumbs={["/","Home","/curation-requests", "My Curation Requests"]} width={0} rowkey="order_id" apiParams={apiParams} curating="null"/> 
</>
}

export default MyCurationRequests;