import React, { useState,useEffect  } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';
import { Box, Checkbox, CircularProgress, Grid, Typography} from '@mui/material';
import WrapCell from './WrapCell';
import { selectUser } from "../store/slices/userSlice";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import { selectDiseaseCategory, selectDiseaseType } from "../store/slices/diseaseCategorySlice";
import {  selectFilters } from "../store/slices/filtersSlice";
import { selectSearchKey } from "../store/slices/searchSlice";
import samplephoto from "../images/ph_eyedropper-sample-light.svg"
import cellphoto from "../images/Cellphoto.svg"
import celltypephoto from "../images/Celltype.svg"
import DownArrow from "../images/iconamoon_arrow-up-2-bold (1).svg"
import InfoCard from './InfoCard';

interface Column {
    Id: string;
    label: string;
    render: (rowData: any) => React.ReactNode;
    isVisible: boolean;
    width: number;
}

interface RowData {
    [key: string]: string | number;
}

interface MUITableProps {
    fetchFunction: (page: number, perPage: number) => void;
    totalRows: number;
    table: RowData[];
    columns: Column[];
    width: number;
    rowkey: string;
    selectedRows: string[] | object;
    handleNavigation: (newSelectedRows: string[] | object) => void;
    apiParams:any|null;
    curation:any|null;
    isExpanded:any|boolean
}

function DataTable({totalRows,fetchFunction, table, columns, width, rowkey, selectedRows, handleNavigation,apiParams,curation,isExpanded}: MUITableProps)
 {
    
    const [orderBy, setOrderBy] = useState<string>(rowkey === "ReferenceDataset" ? "YearOfPublication" : rowkey);
    const [order, setOrder] = useState<'asc' | 'desc' | 'original'>(["order_id","ReferenceDataset"].includes(rowkey) ? 'original' : 'asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [selectAll, setSelectAll] = useState(false);
    const [sampleCount, setSampleCount] = useState<number | null>(null);
    const [cellCount, setCellCount] = useState<number | null>(null);
    const [cellTypeCount, setCellTypeCount] = useState<number | null>(null);
    const [lastUpdated, setLastUpdated] = useState<number | null>(null);
    const diseaseCategory = useSelector(selectDiseaseCategory);
    const diseaseType = useSelector(selectDiseaseType);
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedFilters = useSelector(selectFilters);
    const searchKey = useSelector(selectSearchKey);

    const [isSticky, setIsSticky] = useState(false);
    const tableContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            const tableContainer = tableContainerRef.current;
            if (tableContainer) {
                if (tableContainer.scrollLeft > 0) {
                    setIsSticky(true); 
                } else {
                    setIsSticky(false); 
                }
            }
        };

        const tableContainer = tableContainerRef.current;
        if (tableContainer) {
            tableContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableContainer) {
                tableContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    
    useEffect(() => {
        fetchFooterDetails();
    }, [diseaseCategory, diseaseType]);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectAll(!selectAll);
            handleNavigation(rowkey === "SampleAccessionId" ? {} : []);
        }else {
            setSelectAll(!selectAll);
            let newSelectedRows: any;
            if (rowkey === "SampleAccessionId") {
                table.forEach((row) => {
                    newSelectedRows = {...newSelectedRows,[row[rowkey]]:row["GeoAccessionId"]};
                });
            }else {
            newSelectedRows = table.map((row) => { return row['GEOAccession'] } );
            }
            handleNavigation(newSelectedRows);
        }
    }

    const handleRowSelection = (rowKey: string, rowData: any) => {
        let newSelectedRows;
        if ("SampleAccessionId" in rowData) {
            newSelectedRows = rowData[rowkey] in selectedRows ? Object.fromEntries(Object.entries(selectedRows).filter(([key]) => key !== rowData[rowkey])) : {...selectedRows, [rowKey]: rowData["GeoAccessionId"] };
            setSelectAll(table.length === Object.keys(newSelectedRows).length ? true : false);
        }else {
            newSelectedRows = (selectedRows as string[]).includes(rowData['GEOAccession']) ? (selectedRows as string[]).filter((key) => key !== rowData['GEOAccession']) : [...(selectedRows as string[]), rowData['GEOAccession']];
            setSelectAll(table.length === newSelectedRows.length ? true : false);
        }
        handleNavigation(newSelectedRows);
    };

    const handleSort = (columnId: string) => {
        if (orderBy === columnId) {
            if (order === 'asc') {
                setOrder('desc');
            }else if (order === 'desc') {
                setOrder('original');
            }else {
                setOrder('asc');
            }
        }else {
            setOrderBy(columnId);
            setOrder('asc');
        }
    };

    const sortedTable = [...table].sort((a, b) => {
        if (orderBy === '' || order === 'original') {
            return 0;
        }
        const aValue = a[orderBy];
        const bValue = b[orderBy];
        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return order === 'asc' ? (aValue - bValue) : -(aValue - bValue);
        }else {
            return order === 'asc' ? String(aValue).localeCompare(String(bValue)) : String(bValue).localeCompare(String(aValue));
        }
    });

    const renderedHeaders = (
        <TableRow>{ sortedTable.length > 0 && !(["order_id","ReferenceDataset"].includes(rowkey)) ? 
            <TableCell
            style={{
                position: 'sticky',
                left: 0, 
                zIndex: 2, 
                background: '#fff', 
            }}
        >

                <span style={{display: 'flex', alignItems: 'center', marginBottom: '-5px', marginTop: '-6px'}}>
                <Checkbox size="small" sx={{width: "18px",height: "18px",borderRadius: "3px",border: "1px #959AC7",cursor: "pointer",transform: "scale(0.95)",padding: 0,color: selectAll ? "#364098" : "#959AC7","&.Mui-checked": {color: "#364098",},}}
                checked={selectAll}
                onChange={handleSelectAll}/>
                </span>
            </TableCell> : null }
        {columns.map((column) => (
            <TableCell
            key={column.Id}
            onClick={() => handleSort(column.Id)}
            style={
                
                sortedTable.length > 0 
            ? {
                  cursor: 'pointer',
                  color:"#000000",
                  fontSize:"18px",
                  lineHeight:"20px",
                  position: ['GEOAccession', 'GeoAccessionId', 'SampleAccessionId'].includes(column.Id)
                      ? 'sticky'
                      : 'relative',
                  left: column.Id === 'SampleAccessionId'
                      ? '11.5rem'
                      : ['GEOAccession', 'GeoAccessionId'].includes(column.Id)
                      ? '40px'
                      : '0px',
                  backgroundColor: ['GEOAccession', 'GeoAccessionId', 'SampleAccessionId'].includes(column.Id)
                      ? '#fff'
                      : undefined,
                  zIndex: ['GEOAccession', 'GeoAccessionId', 'SampleAccessionId'].includes(column.Id) ? 2 : undefined,
                  whiteSpace: 'nowrap',
                  paddingTop: '8px',
                  paddingBottom: '8px',
              }
            : {} 
    }>

                <div style={{ display: 'flex', alignItems: 'center',fontWeight:'600'}}>
                  <Typography variant="h5" sx={{fontWeight:'600'}}>  {column.label}</Typography>
                    {orderBy === column.Id && (
                    order === 'original' || (order === 'asc' ? 
                        <span style={{ marginBottom:'-5px', marginLeft: '3px' }}><GoArrowUp size={16}/></span> : 
                        <span style={{ marginBottom:'-2px', marginLeft: '3px' }}><img src={DownArrow} alt="down-arrow"></img> </span>
                    ))}
                </div>
            </TableCell>
        ))}
        </TableRow> 
    );

    const renderWrappedCell = (column: any) => {
        return typeof column === 'string' ? <WrapCell column={column}/> : column ;
    };

    const renderedRows = sortedTable.map((rowData, index) => {
        const alternatingColor = index % 2 === 0 ? '#F9F9FC' : 'white';
        let isSelected;
        if (!(["order_id","ReferenceDataset"].includes(rowkey))) {
            isSelected = ("SampleAccessionId" in rowData) ? (rowData[rowkey].toString() in selectedRows) : (selectedRows as string[]).includes(rowData['GEOAccession'].toString());
        }
        const renderedCells = columns.map((column) => (
            <TableCell
            key={column.Id}
            style={{ 
                color:"#242424",
                position: ['GEOAccession', 'GeoAccessionId', 'SampleAccessionId'].includes(column.Id) ? 'sticky' : 'relative',
                left: (column.Id === 'SampleAccessionId'
                    ? '11.5rem'
                    : ['GEOAccession', 'GeoAccessionId'].includes(column.Id)
                    ? '2.5rem'
                    : '0px'),                
                    backgroundColor: ['GEOAccession', 'GeoAccessionId', 'SampleAccessionId'].includes(column.Id) && isSticky ? '#ffff' : undefined,
                zIndex: ['GEOAccession', 'GeoAccessionId', 'SampleAccessionId'].includes(column.Id) && isSticky ? 1 : undefined,
               minWidth: column.width, 
               maxWidth: 'auto',
            }}
        >

             <Typography variant='body1'> {column.render(rowData) === '' || column.render(rowData) === null ? "null" : renderWrappedCell(column.render(rowData)) }</Typography>  
            </TableCell>
        ));

        return (
            <TableRow style={{ backgroundColor: alternatingColor}} key={rowData[rowkey]}>
                { !(["order_id","ReferenceDataset"].includes(rowkey)) ? 
                <TableCell   style={{
                    position: 'sticky',
                    left: 0, 
                    zIndex:1,
                    backgroundColor:  isSticky ? '#ffff' : '',
                }}>
                                    <Checkbox size="small" sx={{width: "18px",height: "18px",borderRadius: "3px",cursor: "pointer",transform: "scale(0.95)",padding: 0,color: isSelected ? "#364098" : "#959AC7","&.Mui-checked": {color: "#364098",},}}checked={isSelected} onChange={() => handleRowSelection(rowData[rowkey].toString(),rowData)}/>
                </TableCell> : null }
                {renderedCells}
            </TableRow>);
    });
  
    const [loading, setLoading] = useState(false);
    const [loadNumber,setloadNumber]=useState(true);

    const handleFetchFunction = async (page: number, perPage: number) => {
        setLoading(true);
        if (rowkey === "order_id" || rowkey === "ReferenceDataset") {
            await fetchFunction(page, perPage);
            setLoading(false);
            return;
        }
        setSelectAll(false);
        handleNavigation(rowkey === "SampleAccessionId" ? {} : []);
        await fetchFunction(page, perPage);
        setLoading(false);
    }

     const fetchFooterDetails = async (page: number = 1, perPage: number = 15) => {
        let query=''
        if (apiParams?.page_name === "public_dataset") 
        {
           query ='api/public_dataset_details?page_name=Public Dataset';
        }

        else if(apiParams?.page_name === "curation_request")
            return;
        else{
        query = (searchKey.length > 0) ? `/api/${curation}_search?page=${page}&per_page=${perPage}&search_string=${encodeURIComponent(searchKey)}&DiseaseCategory=${diseaseCategory}&` : 
                    `/api/${curation}?page=${page}&per_page=${perPage}&DiseaseCategory=${diseaseCategory}&`;
        if (Object.values(selectedFilters).some((value) => value.length > 0)) {
            Object.keys(selectedFilters).forEach(filter => {
                selectedFilters[filter].forEach(value => {
                    query += `${filter}=${encodeURIComponent(value)}&`;
                });
            });
        }
        query = query.slice(0,-1);
        if (diseaseCategory !== "Inflammatory Bowel Disease") {
            query += `&DiseaseType=${diseaseType}`;
        }
    }
            try {
                const response = await axiosConfig.get(query);
                        const res = apiParams?.page_name === "public_dataset" 
                            ? response.data.data
                            : response.data.footer_detail;
                        setLastUpdated(response.data.last_updated)
                        setSampleCount(res.no_of_samples);
                        setCellCount(res.no_of_cells);
                        setCellTypeCount(res.no_of_distinct_cell_types);
            }catch(error: any) {
                try {
                    if (error.response.data.msg === "Token has been revoked") {
                        handleLogout(dispatch, navigate);
                    } 
                    if (error.response.data.msg === "Token has expired") {
                        const response = await handleRefresh(loggedUser.refreshToken);
                        refreshAccessToken(dispatch, loggedUser, response);
                        const refreshedResponse = await axiosConfig.get(query);
                        const res = apiParams?.page_name === "public_dataset" 
                            ? refreshedResponse.data.data
                            : refreshedResponse.data.footer_detail;

                        setLastUpdated(response.data.last_updated)
                        setSampleCount(res.no_of_samples);
                        setCellCount(res.no_of_cells);
                        setCellTypeCount(res.no_of_distinct_cell_types);
                    }
                }catch(error: any) {
                    if (error.response.data.msg === "Token has expired") {
                        handleLogout(dispatch, navigate);
                    }
                }
            }
            finally
            {
                setloadNumber(false);
            }
        };
    const lastUpdatedText = lastUpdated ? `Last updated ${lastUpdated} days ago` : "";

    const infoCardsData = [
        {
            title: "Total Number of Samples",
            count: sampleCount,
            imageSrc: samplephoto
        },
        {
            title: "Total Number of Cells",
            count: cellCount,
            imageSrc: cellphoto
        },
        {
            title: "Total Number of Distinct Cell Types",
            count: cellTypeCount,
            imageSrc: celltypephoto
        }
    ];

return (
    <>
        {apiParams?.page_name === "curation_request" ? null : (
            <div 
                style={{
                    maxWidth: apiParams?.page_name === 'public_dataset' ? '80%' : 'auto',
                    marginBottom: '10px',
                    minHeight: '20px'
                }}
            >
                <Box>
                    <Grid container spacing={1} marginTop={1} paddingLeft={1} alignItems="center" maxWidth={'1300px'}>
                        
                        {infoCardsData.map((card, index) => (
                            <Grid key={index} item xs={12} sm={6} md={3}>
                                <InfoCard 
                                    title={card.title} 
                                    count={card.count} 
                                    imageSrc={card.imageSrc} 
                                />
                            </Grid>
                        ))}
                        {lastUpdated && (
                            <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
                                <div 
                                    style={{
                                        display: "inline-block",
                                        backgroundColor: "#E2E5F9",
                                        color: "#555",
                                        padding: "6px 12px",
                                        borderRadius: "20px",
                                        fontSize: "14px",
                                        fontWeight: "500"
                                    }}
                                >
                                    ⏳ {lastUpdatedText}
                                </div>
                            </Grid>
                        )}

                    </Grid>
                </Box>
            </div>
        )}
    <TableContainer ref={tableContainerRef} 
        
        sx={{
            width: (apiParams?.page_name==='curation_request')||(apiParams?.page_name==='public_dataset')? '97%' :(isExpanded? 'min(81vw,calc(100vw - 312px))':'max(94.5vw,800px)'),
            height:  (apiParams?.page_name==='curation_request')?'calc(100vh - 226px)':(apiParams?.page_name === 'public_dataset')? 'calc(100vh - 380px)':'calc(100vh - 360px)',
            minWidth:'770px',
            position:'relative',
            minHeight:'565px',
        }}
    >
          {loading && 
                <CircularProgress size={80} thickness={4.5} style={{ display:'flex',justifyContent:'center',position: 'absolute', top: (apiParams?.page_name==='curation_request'?'38%':'40%'), left:(apiParams?.page_name==='curation_request'?'43%':(apiParams?.page_name === 'public_dataset') ? '47%' : '45%'), color: '#E2E5F9' }}/>

            }
        <Table sx={{ minWidth: width }}>
            <TableHead sx={{ position: 'sticky', zIndex: 2, top: 0, backgroundColor: 'white' }}>
                {renderedHeaders}
            </TableHead>
            <TableBody>{renderedRows}</TableBody>
        </Table>
    </TableContainer>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

    <div style={{ 
    marginTop:'6px',
    width: "98.5%",
    marginBottom:'6px',
    display: "flex", 
    justifyContent: "flex-end", 
}}>
    <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => {
            setPage(newPage);
            handleFetchFunction(newPage + 1, rowsPerPage);
        }}
        onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
            handleFetchFunction(1, parseInt(event.target.value, 10));
        }}
        sx={{
            "& .MuiIconButton-root": {
                border: "1px solid #E2E5F9", 
                borderRadius: "8px",
                width: "35px", 
                height: "35px",
                boxShadow:"2px 2px 8px -5px #0000003D"
            }
        }}
    />
</div>

   
</div>

</>

    );
}

export default DataTable;
