import { TextField, Button, Stack, Alert, Paper } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import logo from "../companylogo.png";
import { axiosConfig, setAccessToken } from "../axiosConfig";
import { useDispatch } from "react-redux";
import { login } from "../store/slices/userSlice";
import { useState } from "react";
import { hashSync } from "bcrypt-ts";
import { Link } from 'react-router-dom';

const schema = yup.object({
    email: yup.string().email("Enter a valid email").required("Enter an email"),
    password: yup.string().required("Enter a password"),
});

type FormValues = {
    email: string
    password: string
}

function LoginForm() {
    const form = useForm<FormValues>({
        defaultValues: {
            email: '',
            password: '',
        },
        resolver: yupResolver(schema)
    });
    const { register, handleSubmit, reset, formState, setError } = form;
    const { errors } = formState;

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState<string>('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const onSubmit = async (data: FormValues) => {
        try {
            setDisableSubmit(true);
            const passwordHash = hashSync(data.password, process.env.REACT_APP_API_SALT);
            const response = await axiosConfig.post('/api/login', { "email": data.email, "user_password": passwordHash } );
            if (response.data.status === "SUCCESS") {
                setFeedback('Login successful');
                setAccessToken(response.data.access_token);
                dispatch(login({"username": response.data.username, "email": data.email, "isAuthenticated": true, "accessToken": response.data.access_token, "refreshToken": response.data.refresh_token}));
                navigate('/',{replace:true});
                setDisableSubmit(false);
                reset();
            }else if (response.data.data === "Password change is required") {
                dispatch(login({"username": null, "email": response.data.email, "isAuthenticated": false, "accessToken": null, "refreshToken": null}));
                navigate('/change-password',{replace:true});
                setDisableSubmit(false);
            }
        }catch(error: any) {
            if (error?.response?.data?.data === "Invalid credentials") {
                setDisableSubmit(false);
                setError("password", {type: "required", message: "Invalid credentials"});
                setError("email", { type: "required", message: "Invalid credentials" });
                setFeedback("Invalid credentials")
            }
        }
    }

    return (
        <div className="table" style={{ overflow: 'auto', marginTop: '-7px', marginLeft: '-8px', marginRight: '-8px', marginBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#F1F3F6', margin: '0px' }}>
                <Paper elevation={12} style={{ borderRadius:'10px' }}>
                    <div style={{ textAlign: 'center', backgroundColor: 'white', padding: '20px', paddingTop: '20px', paddingBottom: '15px', border: '1px solid #ccc', borderRadius: '10px' }}>
                                <Link to="/">
                                  <img style={{ width: '240px', height: '49.19px', marginBottom: '-10px' }} src={logo} alt="company logo" />
                                </Link>                    
                        <p style={{ fontSize: '20px', marginTop: '26px', marginBottom: '26px' }}>Sign in to scRNA data curation portal v1.0</p>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Stack spacing={2} width={390}>
                                <TextField autoComplete="off" label="Email address" type="email" {...register("email")}
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                />
                                <TextField label="Password" type="password" {...register("password")}
                                    error={!!errors.password}
                                    helperText={errors.password?.message}
                                />
                                {feedback && <Alert style={{fontSize: '16px'}} severity={feedback!=="Login successful"?"error":"success"}>{feedback}</Alert>}
                                <Button disabled={disableSubmit} type="submit" variant="contained" style={{ height: 54, backgroundColor: '#0C73EB', textTransform: 'none', fontSize: '23px', color: 'white' }}><b>Log in</b></Button>
                            </Stack>
                        </form>
                        <p style={{fontSize: '16px'}}>Don't have an account? <span style={{ color: '#0C73EB', cursor: 'pointer' }} 
                            onMouseEnter={(e) => {(e.target as HTMLElement).style.textDecoration = 'underline'}}
                            onMouseLeave={(e) => {(e.target as HTMLElement).style.textDecoration = 'none'}} onClick={() => { navigate('/signup'); }}>Sign up</span></p>
                        <p style={{fontSize: '16px'}}> Forgot Password? <span style={{ color: '#0C73EB', cursor: 'pointer' }} 
                            onMouseEnter={(e) => {(e.target as HTMLElement).style.textDecoration = 'underline'}}
                            onMouseLeave={(e) => {(e.target as HTMLElement).style.textDecoration = 'none'}} onClick={() => { navigate('/forgot-password'); }}>Reset Password</span></p>
                    </div>
                </Paper>
            </div>
        </div>
    );
}

export default LoginForm;