import { createTheme,responsiveFontSizes} from "@mui/material";

const theme= createTheme({

    typography:{
        h1:{
           fontSize:'1.5rem',
           color: "#242424",
           letterSpacing: "-0.84px",
            "@media (min-width:1200px)": {
                fontSize: "1.6rem", 
            },
            "@media (min-width:1400px)": {
                fontSize: "1.8rem", 
            },
        },
        h2:{
            fontSize: "1.2rem", 
            "@media (min-width:1200px)": {
                fontSize: "1.3rem", 
            },
            "@media (min-width:1400px)": {
                fontSize: "1.5rem", 
            },
        },
      
        h3:{
            fontSize: "1.1rem", 
            "@media (min-width:1200px)": {
                fontSize: "1.2rem", 
            },
            "@media (min-width:1400px)": {
                fontSize: "1.3rem", 
            },
        }
       ,
       
        h5:{
            fontSize: "0.9rem", 
            "@media (min-width:1400px)": {
                fontSize: "1rem", 
            },
           
        },
        h6:{
            fontSize:'0.9rem',
        },
        body2:{
            fontSize:'0.8rem',
            "@media (min-width:1200px)": {
                fontSize: "0.8rem", 
            },
            "@media (min-width:1400px)": {
                fontSize: "0.9rem", 
            },
        },
        subtitle1:{
            fontSize: "0.8rem", 
            "@media (min-width:1200px)": {
                fontSize: "0.9rem", 
            },
            "@media (min-width:1400px)": {
                fontSize: "1rem", 
            },
        },
        subtitle2:{
            fontSize: "0.8rem", 
            "@media (min-width:1200px)": {
                fontSize: "1.3rem", 
            },
            "@media (min-width:1400px)": {
                fontSize: "1.43rem", 
            },
        },
        body1:{
            fontSize:'0.8rem',
            "@media (min-width:1400px)": {
                fontSize: "0.95rem", 
            },
        }
        

    },
    components:{
       MuiTablePagination:{
        styleOverrides:{
           root:{
            border: "1px solid #E2E5F9",
            width: "450px",
            height: "55px",
            boxShadow: "2px 2px 8px -5px rgba(0, 0, 0, 0.24)", 
            borderRadius: "8px",
            justifyContent:"center",
            alignItems:"center",
            overflow:'hidden',
           },
           spacer:{
            display:'none'
           },
           toolbar:{
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center",
            padding: "8px 10px",
           },
           select:{
            width:'10px',
            border: "1px solid #E2E5F9", 
            borderRadius: "8px",
            padding: "4px 12px",
           },
           selectLabel:{
             fontWeight: "500",fontSize:"0.93rem"
           },
           displayedRows:{
            fontWeight: "500",fontSize:"0.93rem",
            ":before":{content:'"Rows "'}
           },
           
        }
       },
       MuiTableContainer:{
        styleOverrides:{
            root:{
                border:"1px solid", 
                overflowY: 'auto', 
                borderRadius:"12px",
                borderColor:"#E2E5F9",
                boxShadow: '2px 2px 8px -5px #0000003D', 
               
                '&::-webkit-scrollbar': { displayWebkitAppearance: 'none' },

            }
        }
       },
    MuiSwitch: {
        styleOverrides: {
            root: {
                width: 75,
                height: 36,
                padding: 0,
            },
            switchBase: {
                "&.Mui-checked": {
                    transform: "translateX(45px)",
                    color: "#FFFFFF",
                    "& + .MuiSwitch-track": {
                        backgroundColor: "black",
                        opacity: 1,
                    }
                }
            },
            thumb: {
                backgroundColor: "#fff",
                borderRadius: "21px"
            },
            track: {
                borderRadius: 20,
                backgroundColor: "#bfbfbf",
                opacity: 1,
            }
        }
    },
    MuiFormControlLabel:{
        styleOverrides:{
            label:{
                fontSize:'1rem',
                fontWeight:'500'
            }
        }
    },
    MuiTabs:{
        styleOverrides:{
            root:{
                    display:'flex',
                    minHeight: '40px',
                    "& .MuiTabs-indicator": { display: "none" },
                    overflowX:'auto',
                    flexWrap: "nowrap",
                    "& .MuiTabs-scrollButtons": {
                    width: "9px", 
                    padding: "6px",
                    },
                    scrollbarWidth: "none"
                },
            flexContainer:{
                  minHeight: "40px",
                  marginTop:'8px'
            },
        }
    },
    MuiTab:{
        styleOverrides:{
            root:{
                fontSize: "1.125rem",
                borderRadius: "8px",
                textTransform: "none",
                color: "#000",
                marginLeft: "13px",
                marginRight:'19px',
                overflowX:'auto',
                minHeight: "32px", 
                padding: "6px 12px",
            },
            }
        },
      MuiCard:{
        styleOverrides:{
            root:{
                height: "140px",
                border:"1px solid #E2E5F9",
                borderRadius: "12px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                position: "relative",
            }
        }
      },
      MuiTableCell:{
        styleOverrides:{
            root:{
                fontSize:"18px",
                fontWeight:"400",
                lineHeight:"26px",
                letterSpacing:" -0.01rem",
                paddingTop: '8px',
                paddingBottom: '8px',
            }
        }
      },
     
    
    },
    

})


export default theme;