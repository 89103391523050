import { useLocation, useNavigate } from "react-router-dom";
import logo from "../companylogo.png"
import { useEffect } from "react";
import { FcOk } from "react-icons/fc";
import { Button, Paper } from "@mui/material";
import dna from "../images/dna-cr.png"
import ellipse from "../images/Ellipse 3.png"

function BookaDemoSuccess() {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (!location.state) {
            navigate('/');
        }
    });
    return (
        <Paper elevation={3} style={{ padding: "10px",margin:'20px',borderRadius: "12px",background: "white",border: '1px solid #E2E5F9',boxShadow: '2px 2px 8px -5px #0000003D',position:'relative',height:'100%'}}>
        <img src={dna} alt="landingBgg" style={{position: "absolute",top: "-20px",right:"-60px",width:'230px'}}  />
        <img src={ellipse} alt="landing" style={{position: "absolute",top: "-30px",right:"-80px",width:'230px'}}  />
        <div style={{ display: 'flex',alignItems: 'center',backgroundColor: 'white', margin: '8px', paddingBottom: '50vh',marginLeft:'30px'}}>
            <div style={{ width:'78%', textAlign: 'left', backgroundColor: 'white', padding: '20px', paddingTop: '20px', paddingBottom: '25px', borderLeft: '0px solid #ccc' }}>
                <img style={{ width: '260px', height: '57.4px', marginLeft: '-8px',marginBottom:'25px' }} src={logo} alt="company logo"/>
                <p style={{ fontWeight: 'bold', color: 'seagreen', fontSize: '24px',marginBottom:'20px'}}><FcOk size={26} style={{marginRight:'3px'}}/>Thank you for booking a demo!</p>
                <p style={{ fontSize: '18px', textAlign: 'left'}}> We're excited to show you how we can help. Someone from our team will be in touch with you shortly to confirm the details and answer any questions you may have.</p>
                <p style={{fontSize: '18px',marginBottom:'20px'}}>Click on continue button below to Log in to scRNA Portal</p>
                <Button type="submit" variant="contained" style={{ cursor: 'pointer', height: 38, backgroundColor: 'seagreen', textTransform: 'none', fontSize: '20px', fontWeight: '500', marginTop: '5px',borderRadius:'7px' }} onClick={() => { navigate('/login'); }}>Continue</Button>
            </div>
        </div>
        </Paper>
    );
}

export default BookaDemoSuccess;