import { Button, Paper, Stack, TextField, Tooltip } from "@mui/material";
import BreadcrumbNavigation from "../components/BreadcrumbNavigation";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, selectUser } from "../store/slices/userSlice";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import { Fragment, useEffect, useState } from "react";
import NavigationAppBar from "../components/NavigationAppBar";
import pencil from '../images/Pencil.png'
import dna from "../images/dna-cr.png"
import ellipse from "../images/Ellipse 3.png"

function UserProfile() {
    const navigate = useNavigate();
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const dispatch = useDispatch();
    const [editable, setEditable] = useState(false);
    const [profile, setProfile] = useState({ name: '', work_email: '', academic_affiliation: '', uid: '' });
    const fetchProfile = async () => {
        try {
            const response = await axiosConfig.get('/api/profile');
            setProfile(response.data.data);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    setProfile((await axiosConfig.get("/api/profile")).data.data);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };
    useEffect(() => {
        fetchProfile();
    // eslint-disable-next-line
    }, []);
    const handleSaveProfile = async () => {
        if (profile.name.length > 4 && profile.academic_affiliation.length > 4 ) {
            setEditable(false);
            try {
                const response = await axiosConfig.post('/api/profile_update', { "name": profile.name, "academic_affiliation": profile.academic_affiliation } );
                if (response.data.status === "SUCCESS"){
                    dispatch(login({"username": profile.name, "email": loggedUser.email, "isAuthenticated": true, "accessToken": loggedUser.accessToken, "refreshToken": loggedUser.refreshToken}));
                }
            }catch(error: any) {
                try {
                    if (error.response.data.msg === "Token has been revoked") {
                        handleLogout(dispatch, navigate);
                    }
                    if (error.response.data.msg === "Token has expired") {
                        const response = await handleRefresh(loggedUser.refreshToken);
                        refreshAccessToken(dispatch, loggedUser, response);
                        setProfile((await axiosConfig.get("/api/profile")).data.data);
                    }
                }catch(error: any) {
                    if (error.response.data.msg === "Token has expired") {
                        handleLogout(dispatch, navigate);
                    }
                }
            }
        }
    };
    return (
        <Fragment>
            <div style={{ marginBottom: "60px" }}><NavigationAppBar/></div>
            <BreadcrumbNavigation breadcrumbs={[{path:'/',text:'Home'},{path:'/user-profile',text:'User Profile'},]}/>
            <div className="center-container">
                        <div className="modal-content-visible bg-white rounded-[3vw] md:rounded-[.85vw] px-[4vw] md:px-[2.5vw] py-[5vw] md:py-[2.5vw] w-auto md:w-auto relative shadow-xl" 
                                style={{ width: "max(35vw, 600px)", border: "1px solid #E2E5F9", borderRadius: "20px", top: "9.5vh",paddingBottom:'25px'}}>
                    <img src={dna} alt="landingBgg" style={{position: "absolute",top: "-50px",right:"-50px", maxWidth: "154px"}}  />
                    <img  src={ellipse} alt="Ellipse decoration" style={{  position: "absolute",  top: "-81px",  right: "-78px", maxWidth: "237px", height: "auto"}}/>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ width: '100%' }} >
                                <h2 style={{ textAlign: "left", fontWeight: "600", fontSize: "2.5rem" ,marginBottom:'2rem'}}>My Profile</h2>
                            </Stack>
                            <Stack spacing={2.5} width="100%">
                                <Stack spacing={0.5}>
                                    <label style={{ fontSize: '0.875rem', marginLeft: '0.188rem',fontWeight:'500',color:'#364098'}}>Full Name</label>
                                    <TextField autoComplete="off" disabled={!editable} onChange={(event) => setProfile({...profile, name: event.target.value})} value={profile.name} error={profile.name.length < 5} helperText={profile.name.length > 0 && profile.name.length < 5 && "Full Name must be at least 5 characters long"} sx={{"& .MuiInputBase-root": {height: {xs: "36px",sm: "40px",md: "52px"}},borderRadius: "12px", width: "100%", "& label": { color: "#364098", fontWeight: "500",fontSize:"18px" }, "& label.Mui-focused": { color: "#364098" }, "& .MuiOutlinedInput-root": {borderRadius: "12px", "& fieldset": { borderWidth:"2px",borderColor: "#CCCFEA" }, "&:hover fieldset": { borderColor: "#364098" }, "&.Mui-focused fieldset": { borderColor: "#364098" } } }}></TextField>
                                </Stack>
                                <Stack spacing={0.5}>
                                    <label style={{ fontSize: '0.875rem', marginLeft: '0.188rem',fontWeight:'500',color:'#364098'}}>Work Email ID</label>
                                    <TextField disabled value={profile.work_email} sx={{height: {xs: "36px",sm: "40px",md: "52px"},borderRadius: "12px", width: "100%", "& label": { color: "#364098", fontWeight: "500",fontSize:"18px" }, "& label.Mui-focused": { color: "#364098" }, "& .MuiOutlinedInput-root": {borderRadius: "12px", "& fieldset": { borderWidth:"2px",borderColor: "#CCCFEA" }, "&:hover fieldset": { borderColor: "#364098" }, "&.Mui-focused fieldset": { borderColor: "#364098" } } }}/>
                                </Stack>
                                <Stack spacing={0.5}>
                                    <label style={{fontSize: '0.875rem', marginLeft: '0.188rem',fontWeight:'500',color:'#364098'  }}>Work/Academic Affiliation</label>
                                    <TextField autoComplete="off" disabled={!editable} onChange={(event) => setProfile({...profile, academic_affiliation: event.target.value})} value={profile.academic_affiliation} error={profile.academic_affiliation.length < 5} helperText={profile.academic_affiliation.length >0  && profile.academic_affiliation.length < 5 && "Academic affiliation must be at least 5 characters long"} sx={{height: {xs: "36px",sm: "40px",md: "52px"},borderRadius: "12px", width: "100%", "& label": { color: "#364098", fontWeight: "500",fontSize:"18px" }, "& label.Mui-focused": { color: "#364098" }, "& .MuiOutlinedInput-root": {borderRadius: "12px", "& fieldset": { borderWidth:"2px",borderColor: "#CCCFEA" }, "&:hover fieldset": { borderColor: "#364098" }, "&.Mui-focused fieldset": { borderColor: "#364098" } } }}/>
                                </Stack>
                                <Stack spacing={0.5}>
                                    <label style={{ fontSize: '0.875rem', marginLeft: '0.188rem',fontWeight:'500',color:'#364098'  }}>Customer ID</label>
                                    <TextField disabled value={profile.uid} sx={{height: {xs: "36px",sm: "40px",md: "52px"},borderRadius: "12px", width: "100%", "& label": { color: "#364098", fontWeight: "500",fontSize:"18px" }, "& label.Mui-focused": { color: "#364098" }, "& .MuiOutlinedInput-root": {borderRadius: "12px", "& fieldset": { borderWidth:"2px",borderColor: "#CCCFEA" }, "&:hover fieldset": { borderColor: "#364098" }, "&.Mui-focused fieldset": { borderColor: "#364098" } } }}/>
                                </Stack>
                                <hr style={{ border: "1.5px solid #E2E5F9", width: "100%" }} />

                            </Stack>
                            <div style={{display:'flex',justifyContent:'space-between',marginTop:'1.5rem',gap:'30px'
                            }}>
                                    <div style={{ textAlign: 'center', fontSize: '1.15rem',marginTop:'1.5rem' }}>
                                        <span>Want to Change password? </span>
                                        <span style={{ color: '#2577C9', cursor: 'pointer' ,textDecoration: 'underline'}} 
                                        onMouseEnter={(e) => {(e.target as HTMLElement).style.textDecoration = 'underline'}}
                                        onMouseLeave={(e) => {(e.target as HTMLElement).style.textDecoration = 'none'}} onClick={() => { navigate('/change-password'); }}>Click here</span>
                                    </div>
                                    {editable ? 
                                    <Button  onClick={handleSaveProfile} sx={{ backgroundColor:'#364098',border: '1.5px solid #364098',height: '60px',width:{sm:'162px',md:'167px'}, textTransform: 'none', fontSize: {xs:'1rem',sm:'1.125rem',md:'1.2rem'}, fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px',borderRadius:'12px',color:"white",marginTop:'-2px','&:hover': {backgroundColor: '#2c357e',border: '1.5px solid #2c357e', color: 'white',marginBottom:'30px'}}}>
                                       Save Profile
                                    </Button>:
                                     <Button onClick={() => setEditable(true)} sx={{ border: '1.5px solid #364098',height: {xs:'52px',sm:'56px',md:'60px'},width:{sm:'162px',md:'167px'}, textTransform: 'none', fontSize: {xs:'1rem',sm:'1.125rem',md:'1.2rem'}, fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px',borderRadius:'12px',color:'#364098',marginBottom:'30px'}}>
                                        <img src={pencil} alt='edit_icon'></img> Edit Profile
                                      </Button>
                                    }

                            </div>
                </div>
                </div>
        </Fragment>
    );
}

export default UserProfile;