import React from "react";
import { Card, CardContent, Box, Typography} from "@mui/material";

interface InfoCardProps {
  title: string;
  count: number|null;
    imageSrc?: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ title, count, imageSrc }) => {
  return (
    <Card
      sx={{
        width: { xs: "100%", sm: "320px", md: "100%" },
        height:'70px',
        gap:'5px',
      }}
    >
       {imageSrc && (
        <img
          src={imageSrc}
          alt="icon"
          style={{
            position: "absolute",
            top: 12,
            right: 12,
            width: 26,
            height: 29,
          }}
        />
      )}
       <CardContent sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '11px 15px 3px 15px'}}>
        <Box>
          <Typography
            variant="body2" 
            sx={{ 
            fontWeight: 500, letterSpacing: "-0.18px" }}
          >
            {title}
          </Typography>
          <Typography
            variant="h3"
              sx={{ 
              fontWeight: 600,
              lineHeight: "23px",
              letterSpacing: "-0.72px",
              marginTop:'4px',
            }}
          >
            {count}
          </Typography>
        </Box>
       
      </CardContent>
    </Card>
  );
};

export default InfoCard;

