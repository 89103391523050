import { IoClose } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters } from "../store/slices/filtersSlice";
import { selectSearchKey, setSearchKey } from "../store/slices/searchSlice";
import logo from "../images/Vector.svg";

function SearchBar() {
    const [searchString, setSearchString] = useState<string>('');
    const dispatch = useDispatch();
    const searchKey = useSelector(selectSearchKey);
    const settingSearchKey = (key: string) => {
        dispatch(setSearchKey(key));
        dispatch(resetFilters());
    };
    const handleKeyDown = (event: any) => {
        if (event.key === "Enter" && searchString.length > 0) {
            settingSearchKey(searchString.trim());
        }
    };
    const handleChange = (event: any) => {
        setSearchString(event.target.value);
        if ((event.target.value).trim().length === 0 && searchKey !== '') {
            settingSearchKey('');
        }
    };
    useEffect(() => {
        setSearchString(searchKey);
    }, [searchKey])
    const handleClear = () => {
        setSearchString('');
        settingSearchKey('');
    }
    const inputStyle = { 
        height: "42px", 
        minWidth: "5vw", 
        width: "20vw", 
        maxWidth: "25vw", 
        paddingLeft: "38px", 
        fontSize: "14px", 
        flex: 1, 
        backgroundColor: "transparent", 
        border: "1px solid rgba(204, 207, 234, 1)", 
        borderRadius: "10px", 
        background: "rgba(248, 249, 255, 1)", 
        outline: "none" 
    };
    const iconStyle: React.CSSProperties = {
        position: 'absolute',
        top: '50%',
        left: '10px',
        transform: 'translateY(-50%)',
        width: '18px',
        height: '19px',
        pointerEvents: 'none' 
    };
    return (
        <>
        <div style={{ position: 'relative',  marginLeft: 'auto',backgroundColor: 'white', overflow: 'hidden', display: 'flex',  borderRadius: '10px', maxWidth:'25vw'}}>
            <input value={searchString} onKeyDown={handleKeyDown} onChange={handleChange} type='text' placeholder='Search here' 
                style={inputStyle}/>
                 <img className="absolute left-3"
            src={logo}
            alt="Search Icon"
            style={iconStyle}/>
            {searchString.length > 0 && (
                <div style={{ position: 'absolute', right: '10px',top:'10px', backgroundColor: 'white', border: 'none', zIndex: 1 }}>
                    <IoClose size={20.5} color='black' style={{ cursor: 'pointer' }} onClick={handleClear}/>
                </div>
            )}
            </div>
            </>
    );
}

export default SearchBar;