import { useState,useEffect,useRef,Fragment } from "react";
import NavigationAppBar from "../components/NavigationAppBar";
import { Paper, Stack, Typography } from "@mui/material";
import BreadcrumbNavigation from "../components/BreadcrumbNavigation";
import dna from '../images/dna-cr.png'
import ellipse from '../images/Ellipse 3.png'
import down_arrow from '../images/iconamoon_arrow-up-2-bold (1).svg'

function StatementOfPurpose () {
    const studyReference = [
        {'label': 'StudyID:', 'points': ['Source the last name of the first author and the GSE ID from the GEO dataset', 'Standardize using the given format: ‘Last name of the first author-GSE ID’', 'Example: Tanemoto-GSE210955']}, 
        {'label': 'Study Name:', 'points': ['Sourced directly from the GEO dataset', 'Example: Single cell RNA-seq in small intestine']}, 
        {'label': 'Study Description:', 'points': ['Sourced directly from the GEO dataset', 'Example: Identify cytotoxic CD4+ T cells in human intestine and analyze characteristics of the population in IBD patients by using single cell RNA-seq']}, 
        {'label': 'Organism:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using NCBI taxonomy', 'Example: Homo sapiens']}, 
        {'label': 'GEO Accession:', 'points': ['Sourced directly from the GEO dataset', 'Example: GSE210955']}, 
        {'label': 'PubMedID:', 'points': ['Sourced directly from the GEO dataset', 'Example: 36353619']}, 
        {'label': 'Publication Date:', 'points': ['Sourced from the publication linked to the dataset and formatted as shown in the example below', 'Example: 24 October, 2022']}, 
        {'label': 'Last Update Date:', 'points': ['Sourced directly from the GEO dataset and formatted as shown in the example below', 'Example: 29 November, 2022']}, 
        {'label': 'Dataset External URL:', 'points': ['Sourced from the GEO dataset', 'Example: https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE210955']}, 
        {'label': 'Publication External URL:', 'points': ['Sourced from the PMID provided in the GEO dataset', 'Example: https://www.frontiersin.org/articles/10.3389/fimmu.2022.977117/full']}, 
        {'label': 'Reference Publication:', 'points': ['Sourced from the linked publication provided in the GEO dataset', 'Example: “Single-cell transcriptomics of human gut T cells identifies cytotoxic CD4+CD8A+ T cells related to mouse CD4 cytotoxic T cells”']}
    ];
    const experimentReference = [
        {'label': 'StudyID:', 'points': ['Sourced from the GEO dataset and homogenized using the given format: ‘Last name of the first author - GSE ID’', 'Example: Tanemoto-GSE210955']}, 
        {'label': 'Experiment Tag:', 'points': ['Sourced from the GEO dataset and standardized using the given format: ‘anatomy-type of experiment’', 'The anatomy and type of experiment are homogenized using UBERON and EFO ontology respectively', 'Example: Colon-scRNA-seq', 'If no close matches between a term and the reference ontology is found, then that term is used as provided in the publication (Example: J Pouch-scRNA-seq)']}, 
        {'label': 'Experiment Description:', 'points': ['Sourced directly from the GEO dataset', 'Example: CD4+CD8A+ T cells in human intestine of IBD patients are extracted and compared with control samples and with mouse CD4CTLs']}, 
        {'label': 'Measurement Type:', 'points': ['Sourced directly from the GEO dataset', 'Example: Expression profiling by high throughput sequencing']}, 
        {'label': 'Technology Type:', 'points': ['Sourced from the GEO dataset', 'Homogenized using EFO ontology', 'Example: Single-cell RNA sequencing']}, 
        {'label': 'Platform ID:', 'points': ['Sourced directly from the GEO dataset', 'Example: GPL30209']}, 
        {'label': 'Sequencing Platform:', 'points': ['Sourced from the GEO dataset', 'Homogenized using either EFO, GENEPIO or OBI ontology, in the order of preference', 'Example: Illumina NovaSeq 6000', 'If no close matches to the reference ontology are found, then the term given in the publication is used as an input (Example: MGISEQ-2000RS)']}, 
        {'label': 'Cell Sorting Technique:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using CL or NCIT ontology, in the order of preference', 'Example: Fluorescence-Activated Cell Sorting']}, 
        {'label': 'Library Preparation Platform:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Example: 10x Technology']}, 
        {'label': 'Library Processing Protocol:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using EFO ontology', "Example: 10x 5' Version 1.1"]}, 
        {'label': 'Sequencer:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using either EFO, GENEPIO or OBI ontology, in the order of preference', 'Example: Illumina NovaSeq 6000', 'If no close matches to the reference ontology are found, then the term given in the publication is used as an input (Example: DNBSEQ)']}, 
        {'label': 'Sequencer Data Aligner:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Example: Cell Ranger']}, 
        {'label': 'Sequence Data Processor:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Example: Seurat Version 4.04']}, 
        {'label': 'Number of Samples:', 'points': ['Sourced directly from the GEO dataset', 'Example: 2']}, 
        {'label': 'Anatomical Entity:', 'points': ['Sourced from the dataset or the linked publication', 'Homogenized using UBERON ontology', 'Example: Colon', 'If no close matches to the reference ontology are found, then the term given in the publication is used as an input (Example: J Pouch)']}, 
        {'label': 'Disease:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using DOID or MONDO ontology, in the order of preference', "Example: Crohn's Disease, Ulcerative Colitis"]}, 
        {'label': 'Disease Category:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using DOID or MONDO ontology, in the order of preference', 'Example: Inflammatory Bowel Disease']}, 
        {'label': 'Created At:', 'points': ['Sourced directly from the GEO dataset', 'Example: RIKEN Center for Integrative Medical Sciences']}, 
        {'label': 'Contributor:', 'points': ['Sourced directly from the GEO dataset', 'Example: Jonathan Moody']}, 
        {'label': 'Experiment Year:', 'points': ['Sourced directly from the GEO dataset', 'Example: 2022']}, 
        {'label': 'Cell Lines:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using CLO ontology', 'Example: CCD-18Co cell', 'If no close matches to the reference ontology are found, then the term given in the publication is used as an input (Example:Human WT iPSC cell line (SA2411))']}, 
        {'label': 'Cell Type:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using CL or NCIT ontology, in the order of preference','Example: CD4-Positive T-Lymphocyte','If no close matches to the reference ontology are found, then the term given in the publication is used as an input (Example: CD45-Positive T-Lymphocyte)']}, 
        {'label': 'Age:', 'points': ['Sourced from the GEO dataset or the linked publication', 'In the absence of specific ages, minimum and maximum ages from the dataset are provided', 'Example: 25 years, 27 years, 68 years, 81 years']}, 
        {'label': 'Minimum Age:', 'points': ['Sourced from the GEO dataset or the linked publication', 'A value specification that specifies the youngest age when specifying an age range', 'Example: 21 years']}, 
        {'label': 'Maximum Age:', 'points': ['Sourced from the GEO dataset or the linked publication', 'A value specification that specifies the oldest age when specifying an age range', 'Example: 68 years']}, 
        {'label': 'Gender:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using PATO ontology', 'Example: Female, Male']}, 
        {'label': 'Sample Acquisition Method:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Example: Surgical']}, 
        {'label': 'Disease Status:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Example: Non-inflamed']}, 
        {'label': 'Treatment:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using CHEBI, NCIT, or EFO ontologies in order of preference', 'Example: Dextran Sulfate Sodium', 'If no close matches to the reference ontology are found, then the term given in the publication is used as an input (Example: Recombinant IL36)']}, 
        {'label': 'Timepoint:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Example: 1 day, 3 days, 5 days']}, 
        {'label': 'Number of Cells:', 'points': ['Sourced from the barcode files in the GEO dataset or the linked publication, in the order of preference', 'Example: 13,084']}, 
        {'label': 'SRA ID:', 'points': ['Sourced directly from the GEO dataset', 'Example: SRP390933']}, 
        {'label': 'Keywords:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Example: Cytotoxic CD4 T Cells, Human CD4+CD8A+ T Cells, Inflammatory Bowel Disease, Intestinal Inflammation, Single-cell RNA Sequencing']}
    ];
    const sampleReference = [
        {'label': 'GEO Accession ID:', 'points': ['Sourced directly from the GEO dataset', 'Example: GSE210955']}, 
        {'label': 'Sample Accession ID:', 'points': ['Sourced directly from the GEO dataset', 'Example: GSM6443304']}, 
        {'label': 'Organism:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using NCBI taxonomy', 'Example: Homo sapiens']}, 
        {'label': 'Strain:', 'points': ['Sourced from the GEO dataset', 'Homogenized using NCIT ontology', 'Example: C57BL/6 Mouse']}, 
        {'label': 'Gender:', 'points': ['Sourced directly from the dataset or the linked publication', 'Homogenized using PATO ontology', 'Example: Female, Male']}, 
        {'label': 'Age:', 'points': ['Sourced from the dataset or the linked publication', 'In the absence of specific ages, this field is left blank and the minimum and maximum ages from the dataset are provided instead', 'Example: 27,68']}, 
        {'label': 'Age Unit:', 'points': ['Unit for age depending on experiment type', 'Example: Years']}, 
        {'label': 'Minimum Age:', 'points': ['Sourced from the GEO dataset or the linked publication', 'A value specification that specifies the youngest age when specifying an age range', 'Example: 27']}, 
        {'label': 'Maximum Age:', 'points': ['Sourced from the GEO dataset or publication', 'A value specification that specifies the oldest age when specifying an age range', 'Example: 68']}, 
        {'label': 'Tissue:', 'points': ['Sourced from the dataset or the linked publication', 'Homogenized using UBERON or FMA ontology, in the order of preference', 'Example: Intestine', 'If no close matches to the reference ontology are found, then the term given in the publication is used as an input (Example: J Pouch)']}, 
        {'label': 'Disease:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using DOID or MONDO ontology, in the order of preference', 'Example: Ulcerative Colitis']}, 
        {'label': 'Cell Type:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using CL or NCIT ontology, in the order of preference', 'Example:  CD4-Positive T-Lymphocyte, CD8-Positive T-Lymphocyte', 'If no close matches to the reference ontology are found, then the term given in the publication is used as an input  (Example: CD45-Positive T-Lymphocyte)']}, 
        {'label': 'Disease Status:', 'points': ['Sourced from the dataset or the linked publication', 'Example: Inflamed, Non-inflamed']}, 
        {'label': 'Treatment:', 'points': ['Sourced from the dataset or the linked publication', 'Homogenized using CHEBI, NCIT, or EFO ontologies in order of preference', 'Example: Dextran Sulfate Sodium', 'If no close matches to the reference ontology are found, then the term given in the publication is used as an input (Example: Recombinant IL36)']}, 
        {'label': 'Treatment Response:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Example: Intestinal Inflammation']}, 
        {'label': 'Cancer Stage:', 'points': ['Sourced from the GEO dataset or the linked publication']}, 
        {'label': 'Cancer Grade:', 'points': ['Sourced from the GEO dataset or the linked publication']}, 
        {'label': 'Sampling Site:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using UBERON or FMA ontology, in the order of preference', 'Example: Intestine']}, 
        {'label': 'Development Stage:', 'points': ['Sourced from the dataset or the linked publication', 'Homogenized using HSAPDV or MMUSDV ontology for human and mice samples, respectively']}, 
        {'label': 'Development Stage Unit:', 'points': ['Unit for developmental stage information', 'Example: Days']}, 
        {'label': 'Extracted Molecule:', 'points': ['Sourced directly from the GEO dataset', 'Example: PolyA RNA']}, 
        {'label': 'Library Strategy:', 'points': ['Sourced from the GEO dataset', 'Homogenized using EFO ontology', 'Example: RNA-Seq']}, 
        {'label': 'Technology Type:', 'points': ['Sourced from the GEO dataset or the linked publication', 'Homogenized using EFO ontology', 'Example: Single-cell RNA sequencing']}, 
        {'label': 'Library Source:', 'points': ['Sourced directly from the GEO dataset', 'Example: Transcriptomic']}, 
        {'label': 'Library Selection:', 'points': ['Sourced directly from the GEO dataset', 'Example: cDNA']}, 
        {'label': 'Instrument Model:', 'points': ['Sourced from the GEO dataset', 'Homogenized using EFO, GENEPIO or OBI ontology, in the order of preference', 'Example: Illumina HiSeq 4000', 'If no close matches to the reference ontology are found, then the term given in the publication is used as an input (Example: MGISEQ-2000RS)']}, 
        {'label': 'Status:', 'points': ['Sourced directly from the GEO dataset', 'Example: Public on Dec 01 2020']}, 
        {'label': 'Title:', 'points': ['Sourced directly from the GEO dataset', 'Example: Control (non lesion from cancer patient) and Ulcerative colitis TFHS000025']}, 
        {'label': 'Sample Type:', 'points': ['Sourced directly from the GEO dataset', 'Example: SRA']}, 
        {'label': 'Source Name:', 'points': ['Sourced directly from the GEO dataset', 'Example: CD4+CD8A+ T cells']}, 
        {'label': 'Treatment Protocol:', 'points': ['Sourced directly from the GEO dataset', 'Example: “All biopsies were collected in ice cold complete RPMI 1640 (10% FBS, 100x penicillin/streptomycin/glutamine, 50uM 2-mercaptoethanol, Sigma) and subsequently cryopreserved in freezing media (90% FBS + 10% DMSO) for long-term storage. We found that there was considerable variation in the recovery of cells from the freezing medium based on the “batch/lot” of FBS that was used to generate the freezing medium. Hence, it was extremely important to “batch/lot” test different FBS from different vendors to optimize the yield of immune cells. Cryopreserved biopsies were gently thawed at 37°C and enzymatically digested in collagenase VIII (Sigma) and DNase (Sigma) for 1h to obtain single cell suspensions. After live/dead cell staining with near-IR stain (Invitrogen), cell surface markers were labeled with the following antibodies: CD45 PE-Cy7, CD3 PerCP-Cy5.5, CD19 PE, CD14 FITC, and CD16 Pacific Blue (BioLegend). Sorted CD45+ cellular suspensions were isolated using the Sony SY3200 cell sorter and prepared for scRNA-seq.”']}, 
        {'label': 'Growth Protocol:', 'points': ['Sourced directly from the GEO dataset', 'Example: “ Approximately four to ten mucosal pinch biopsies were obtained from each patient. For UC patients, all biopsies were obtained from the rectum. For J-pouch patients, all biopsies were obtained from the pouch body or inlet.  If active endoscopic inflammation was present, this area was targeted. For each location sampled, one biopsy was collected for standard histopathology assessment and read by two expert pathologists for the PDAI histology subscore and histologic pouch activity score (PAS). In most instances 6 pinch biopsies were performed for each patient, however, at the time of endoscopy fewer pinches were taken in severely inflamed patients to prevent the risk of risk of excess bleeding and perforation. We observe a slight decrease in cell viability reflected in the number of cells recovered per sample.”']}, 
        {'label': 'Extraction Protocol:', 'points': ['Sourced directly from the GEO dataset', "Example: “Dissected mucosa of small intestine or large intestine was cut into 5-mm pieces. Tissue was incubated with Ca2+, Mg2+-free HBSS containing 1 mM DTT (Invitrogen) and 5 mM EDTA (Thermo Fisher Scientific) at 37 °C for 30 minutes, then used as Intestinal epithelial lymphocytes (IELs). The rest of the supernatant was digested with 1 mg/mL collagenase (Wako) and 0.1 mg/mL DNase (Sigma-Aldrich) for 45 minutes. The dissolved solution was centrifuged at 1,700 rpm for 5 minutes. The pellet was resuspended in 40% Percoll (GE healthcare) and overlaid on 75% Percoll. Percoll gradient separation was performed by centrifugation at 2,000 rpm for 20 minutes at 20 C. Cells at the interface were LP mononuclear cells.,Library was performed according to the manufacturer's instructions (single cell 5’ v1.1 protocol, 10x Genomics).”"]}, 
        {'label': 'Data Processing:', 'points': ['Sourced directly  from the GEO dataset', 'Example: “Reads were aligned to the refdata-gex-GRCh38-2020-A reference using Cell Ranger v5.0.0,Freemuxlet was used to demultiplex the individual cells using a reference vcf of SNPs within exons with  MAF>0.05 within East Asians from 1000genomes file ALL.wgs.shapeit2_integrated_v1a.GRCh38.20181129.sites.vcf.gz,Y chromosome gene RPS4Y1 expression within cells was used to assign freemuxlet ID with patient sample,Cells were processed with Seurat to filter cells >6 median absolute deviations in UMI or gene count from the mean, or mitochondrial UMI > 25% and integrated with Harmony.,Assembly: hg38,Supplementary files format and content: Tab or comma separated values files and matrix files”']}
    ];
    const [activeSection, setActiveSection] = useState("");

    const renderedReference = (reference: any,referenceName: string) => {
        return reference.map((row: any ,index: any) => {
            const uniqueId = `${referenceName}-${row.label.replace(/\s+/g, '-')}`;
            const renderedPoints = row.points.map((point: any ,subIndex: any) => (
                <li key={String(index)+String(subIndex)} style={{marginTop:'10px'}}>{point}</li>
            ));
            return (
                <Fragment key={index}>
                    <li id={uniqueId} style={{marginTop:'30px'}}><Typography variant="h2" sx={{fontWeight:'500'}}>{row.label}</Typography></li>
                    <ul style={{listStyleType:'circle',color: '#4D4D4D',marginTop:'20px'}}><Typography variant="h3"sx={{fontWeight:'400'}}>{renderedPoints}</Typography></ul>
                </Fragment>
            );
        });
    };
    const [openMenus, setOpenMenus] = useState({
        prologue: false,
        studyLC: false,
        experimentLC: false,
        sampleLC: false
    });
  const toggleMenu = (menu:any) => {
    setOpenMenus((prev:any) => ({ ...prev, [menu]: !prev[menu] }));
  };
const menuStyle = {
    cursor: "pointer",
    padding: "10px",
    fontWeight: "500",
    marginBottom: "5px",
    borderRadius: "5px",
};

const submenuStyle = {
    display:'grid',
    transition:'300ms ease-in-out',
    fontWeight:'400',
    listStyle: "none",
    paddingLeft: "20px",
    color: "#4D4D4D",
    paddinBottom:'30px',
};
const sectionRefs = useRef<{ [key: string]: HTMLElement | null }>({});

useEffect(() => {
  studyReference.forEach((item) => {
    const sectionId = `study-${item.label.replace(/\s+/g, '-')}`;
    sectionRefs.current[sectionId] = document.getElementById(sectionId);
  });
  experimentReference.forEach((item) => {
    const sectionId = `experiment-${item.label.replace(/\s+/g, '-')}`;
    sectionRefs.current[sectionId] = document.getElementById(sectionId);
    });

  sampleReference.forEach((item) => {
    const sectionId = `sample-${item.label.replace(/\s+/g, '-')}`;
    sectionRefs.current[sectionId] = document.getElementById(sectionId);
    });

   const prologueSections = ["Purpose", "Scope", "Data QC", "General Guidelines"];
    prologueSections.forEach((id) => {
      sectionRefs.current[id] = document.getElementById(id);
    });

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    },
    {
        rootMargin: "0px 0px -70% 0px",
        threshold: 0.9
    }
  );

  Object.values(sectionRefs.current).forEach((section) => {
    if (section) observer.observe(section);
  });

  return () => observer.disconnect();
}, []);

    return (
        <Fragment>
            <div style={{marginBottom:"65px"}}><NavigationAppBar/></div>
            <BreadcrumbNavigation breadcrumbs={[{path:'/', text:'Home'},{path:'/documentation', text:'Documentation' },]}/>
            <div className="container">
                <div className="document" style={{
                minWidth:'20vw',
                background: '#F8F9FA',
                padding: '25px',
                border: '1px solid #E2E5F9',
                borderRadius:'12px',
                margin:'20px',
                backgroundColor: "white",
                overflowY:'auto'
              }}>
              <Typography variant='h5' style={{ marginBottom: '35px', color: "#242424",fontWeight:'600',fontSize:'1.5rem',marginLeft:'5px'}}>Table of Contents </Typography>
              <Stack spacing={3}>

                {/* Menu Item - Prologue */}
                <div>
                    <Typography variant="h3" style={menuStyle} onClick={() => toggleMenu("prologue")}>
                        <a href="#Prologue" style={{ textDecoration: "none", color: "#242424" }}>
                            Prologue
                        </a>
                    <img src={down_arrow} style={{marginBottom:'4px', transform: openMenus["prologue"] ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 300ms ease",}} alt="arrow"></img>
                    </Typography>
                    {openMenus.prologue && (
                    <ul style={submenuStyle}>
                    {["Purpose", "Scope", "Data QC", "General Guidelines"].map((item, index) => {
                        const sectionId = item; 
                        return (
                        <li key={index} style={{ marginBottom: "6px" }}>
                            <a 
                            className="vector-link" 
                            href={`#${sectionId}`} 
                            style={{
                                textDecoration: "none",
                                fontWeight: '500',
                                color: activeSection === sectionId ? "#F14234" : "gray",
                            }}
                            >
                           <Typography variant="h5"> {item}</Typography>
                            </a>
                        </li>
                        );
                    })}
                    </ul>
                )}
                </div>
                <hr style={{ border: "1.5px solid #E2E5F9", width: "100%",}} />

                {/* Menu Item - Study Level Curation */}
                <div>
                    <Typography  variant="h3" style={menuStyle} onClick={() => toggleMenu("studyLC")}>
                        <a className="vector-link" href="#Study-Level-Curation" style={{ textDecoration: "none",color: "#242424"}}>
                            Study Level Curation
                            <img src={down_arrow} style={{marginBottom:'4px', transform: openMenus["studyLC"] ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 300ms ease",}} alt="arrow"></img>
                        </a>
                    </Typography>
                    {openMenus.studyLC && (
                    <ul style={submenuStyle}>
                        
                        {studyReference.map((item, index) => {
                        const sectionId = `study-${item.label.replace(/\s+/g, '-')}`;
                        
                        return (
                            <li key={index} style={{ marginBottom: '6px' }}>
                                <a className="vector-link" href={`#${sectionId}`}   style={{
                  textDecoration: "none",
                  fontWeight:"500",
                  color: activeSection === sectionId ? "#F14234" : "gray",
                }}
              >
                                   <Typography variant="h5">{item.label.replace(/:/g, ' ')}</Typography>
                                </a>
                            </li>
                        );
                     })}
                    </ul>
                     )}
                </div>
                <hr style={{ border: "1.5px solid #E2E5F9", width: "100%",}} />

                {/* Menu Item - Experiment Level Curation */}
                <div>
                    <Typography  variant="h3" style={menuStyle} onClick={() => toggleMenu("experimentLC")}>
                        <a className="vector-link" href="#Experiment-Level-Curation" style={{ textDecoration: "none",color: "#242424"}}>
                            Experiment Level Curation
                            <img src={down_arrow} style={{marginBottom:'4px', transform: openMenus["experimentLC"] ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 300ms ease",}} alt="arrow"></img>
                        </a>
                    </Typography>
                {openMenus.experimentLC && (
                <ul style={submenuStyle}>
                    <div style={{ overflow: "hidden" }}>
                    {experimentReference.map((item, index) => {
                        const sectionId = `experiment-${item.label.replace(/\s+/g, "-")}`;

                        return (
                        <li key={index} style={{ marginBottom: "6px" }}>
                            <a
                            className="vector-link"
                            href={`#${sectionId}`}
                            style={{
                                textDecoration: "none",
                                fontWeight: "500",
                                color: activeSection === sectionId ? "#F14234" : "gray",
                            }}
                            >
                            <Typography variant="h5">{item.label.replace(/:/g, ' ')}</Typography>
                            </a>
                        </li>
                        );
                    })}
                    </div>
                </ul>
                )}

                </div>
                <hr style={{ border: "1.5px solid #E2E5F9", width: "100%",}} />

                {/* Menu Item - Sample Level Curation */}
                <div>
                    <Typography variant="h3" style={menuStyle} onClick={() => toggleMenu("sampleLC")}>
                        <a className="vector-link" href="#Sample-Level-Curation" style={{ textDecoration: "none",color: "#242424" }}>
                            Sample Level Curation
                            <img src={down_arrow} style={{marginBottom:'4px', transform: openMenus["sampleLC"] ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 300ms ease",}} alt="arrow"></img>
                        </a>
                    </Typography>
                    {openMenus.sampleLC && (
                    <ul style={submenuStyle}>
                            {sampleReference.map((item, index) => {
                            const sectionId = `sample-${item.label.replace(/\s+/g, "-")}`;

                            return (
                            <li key={index} style={{ marginBottom: "6px" }}>
                                <a
                                className="vector-link"
                                href={`#${sectionId}`}
                                style={{
                                    textDecoration: "none",
                                    fontWeight: activeSection === sectionId ? "500" : "normal",
                                    color: activeSection === sectionId ? "#F14234" : "gray",
                                }}
                                >
                                   <Typography variant="h5">{item.label.replace(/:/g, ' ')}</Typography>
                                </a>
                            </li>
                            );
                        })}
                    </ul>
                     )}
                </div>
            </Stack>
        </div>

            <Paper elevation={3} style={{padding: "10px",margin:'20px',borderRadius: "12px",background: "white",border: '1px solid #E2E5F9',boxShadow: '2px 2px 8px -5px #0000003D',position:'relative',overflow:'hidden'}}>
            <img src={dna} alt="landingBgg" style={{position: "absolute",top: "-20px",right:"-60px",width:'230px'}}  />
            <img src={ellipse} alt="landing" style={{position: "absolute",top: "-30px",right:"-80px",width:'230px'}}  />

            <div className="table">
                <Typography variant="h1" id="Prologue" style={{fontWeight:'600',marginTop:'40px',marginLeft:'30px',marginRight:'30px'}}>Prologue</Typography>
                <Stack spacing={4} style={{marginTop:'20px',marginBottom:'20px',marginLeft:'30px',marginRight:'30px'}}>
                <hr style={{ border: "1.5px solid #E2E5F9", width: "100%",marginTop:"-9px"}} />
                    <div style={{width:'70%'}}>
                        <Stack spacing={2}>
                            <Typography variant="h2" sx={{fontWeight:'500'}} id="Purpose">Purpose</Typography>
                            <Typography variant="h3" sx={{fontWeight:'400',color: '#4D4D4D'}} style={{marginBottom:"40px"}}>Creation, organization and maintenance of datasets for easy accession, usage and analysis.</Typography>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography variant="h2"  sx={{fontWeight:'500'}} id="Scope">Scope</Typography>
                            <Typography variant="h3" sx={{fontWeight:'400',color: '#4D4D4D'}} style={{marginBottom:"40px"}}>Data curation of publicly available datasets in primary and secondary repositories. Currently, all the datasets belong to Ulcerative Colitis and are sourced from GEO. We will expand to other disease categories and platforms as a next step.</Typography>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography variant="h2"  sx={{fontWeight:'500'}} id="Data QC">Data QC</Typography>
                            <Typography variant="h3" sx={{fontWeight:'400',color: '#4D4D4D'}} style={{marginBottom:"40px"}}>All datasets have undergone two levels of validation through curation and review processes respectively.</Typography>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography variant="h2"  sx={{fontWeight:'500'}} id="General Guidelines">General Guidelines</Typography>
                            <Typography variant="h3" sx={{fontWeight:'400',color: '#4D4D4D'}} style={{marginBottom:"40px"}}>The datasets have been curated into extensive sets of metadata fields across three levels namely study, experiment and sample level curation. The data harmonization for specific metadata fields have been performed using thoroughly defined ontologies. A complete reference for data homogeneity has been provided below:</Typography>
                        </Stack>
                    </div>
                    <ol>
                        <li id="Study-Level-Curation"><Typography variant="h1" fontWeight="600">Study Level Curation</Typography></li>
                        <hr style={{ border: "1.5px solid #E2E5F9", width: "100%",marginTop:"15px"}} />

                        <ul style={{marginLeft:'30px',marginRight:'30px',listStyleType:'disc'}}>
                            {renderedReference(studyReference,"study")}
                        </ul>
                        <li style={{marginTop:'40px'}}></li>

                        <li id="Experiment-Level-Curation"><Typography variant="h1" fontWeight="600">Experiment Level Curation</Typography></li>
                        <hr style={{ border: "1.5px solid #E2E5F9", width: "100%",marginTop:"15px"}} />

                        <ul style={{marginLeft:'30px',marginRight:'30px',listStyleType:'disc'}}>
                            {renderedReference(experimentReference,"experiment")}
                        </ul>
                        <li style={{marginTop:'40px'}}></li>

                        <li id="Sample-Level-Curation"><Typography variant="h1" fontWeight="600">Sample Level Curation</Typography></li>
                        <hr style={{ border: "1.5px solid #E2E5F9", width: "100%",marginTop:"20px"}} />

                        <ul style={{marginLeft:'30px',marginRight:'30px',listStyleType:'disc'}}>
                            {renderedReference(sampleReference,"sample")}
                        </ul>
                        <li><Typography variant="h1" fontWeight="600" style={{marginTop:'20px'}}>Additional Categories (wherever applicable):</Typography></li>
                        <ul style={{listStyleType:'circle'}}>
                        <li  style={{marginLeft:'30px',marginRight:'30px',marginTop:'20px'}}>
                          <Typography variant="h3" sx={{fontWeight:'400',marginTop:'20px',color:'#4D4D4D'}}> Additional Metadata fields are incorporated wherever applicable. These fields are sourced directly from the GEO dataset and are documented as free-flow text.</Typography> 
                        </li>
                        </ul>
                    </ol>
                </Stack>
            </div>
            </Paper>
            </div>
        </Fragment>
    );
}

export default StatementOfPurpose;