import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import BreadcrumbNavigation from "../components/BreadcrumbNavigation";
import { Button, CircularProgress, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Stack, Switch, TextField, Typography } from "@mui/material";
import isURL from "validator/lib/isURL";
import NavigationAppBar from "../components/NavigationAppBar";
import { IoMail } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../store/slices/userSlice";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import dna from "../images/dna-cr.png"
import ellipse from "../images/Ellipse 3.png"

type FormValues = {
    diseaseCategory: string
    affiliation: string
    customizedCuration: boolean
    curationFile: FileList | undefined
    datasetSource: string
    url: string
}

function CurationRequest() {
    const form = useForm<FormValues>({
        defaultValues: {
            diseaseCategory: '', affiliation: '', customizedCuration: false, curationFile: undefined, datasetSource: "Publicly Available Datasets", url: '',
        }
    });
    const { register, handleSubmit, reset, formState } = form;
    const { errors } = formState;
    const [showDatasetUpload, setShowDatasetUpload] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [customCuration, setCustomCuration] = useState(false);

    const onSubmit = async (data: FormValues) => {
        try {
            setDisableSubmit(true);
            const formData = new FormData();
            formData.append("disease_category", data.diseaseCategory);
            formData.append("affiliation", data.affiliation);
            formData.append("customized_curation", customCuration ? 'true' : 'false');
            if (data.curationFile !== undefined) {
                formData.append("curation_file", data.curationFile[0]);
            }
            formData.append("source_of_dataset", data.datasetSource === "Publicly Available Datasets" ? 'false' : 'true');
            if (data.datasetSource === "Publicly Available Datasets") {
                formData.append("enter_url", data.url);
            }
            const response = await axiosConfig.post("/api/order_table_write", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (response.data.status === "SUCCESS") {
                setDisableSubmit(false);
                navigate('/curation-requests');
                reset();
            }
        } catch (error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    setDisableSubmit(false);
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    document.getElementById("submit")?.click();
                }
            } catch (error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };

    const textFieldStyles = {
      borderRadius: "12px",
      width: "100%", 
      "& label": { color: "#364098", fontWeight: "500", fontSize: "1.125rem" },
      "& label.Mui-focused": { color: "#364098" },
      "& .MuiOutlinedInput-root": {
        borderRadius: "12px",
        "& fieldset": { borderWidth: "2px", borderColor: "#CCCFEA" },
        "&:hover fieldset": { borderColor: "#364098" },
        "&.Mui-focused fieldset": { borderColor: "#364098" }
      }
    };

return (
    <Fragment>
    <div style={{ marginBottom: "60px" }}><NavigationAppBar /></div>
    <BreadcrumbNavigation breadcrumbs={[
      { path: '/', text: 'Home' }, 
      { path: '/create-curation-request', text: 'Create Curation Request' }
    ]} />
<div className='center-container'>
         
<div className="modal-content-visible bg-white rounded-[3vw] md:rounded-[.85vw] px-[4vw] md:px-[2.5vw] py-[5vw] md:py-[2.5vw] w-[90vw] md:w-[37vw] relative shadow-xl" 
  style={{ width: "max(37vw, 650px)", border: "1px solid #E2E5F9", borderRadius: "20px", top: "9vh"}}>
      <img 
        src={dna} 
        alt="DNA decoration" 
        style={{
          position: "absolute",
          top: "-40px",
          right: "-60px",
          maxWidth: "150px",
          height: "auto",
        }}
      />
      <img 
        src={ellipse} 
        alt="Ellipse decoration" 
        style={{
          position: "absolute",
          top: "-80px",
          right: "-120px",
          maxWidth: "220px",
          height: "auto",
        }}
      />

      <Typography variant="h4" style={{ 
        textAlign: "left", 
        marginBottom: "1.8rem", 
        fontWeight: "600", 
      }}>
        Create Curation Request
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} noValidate style={{height:'auto'}}>
        <Stack spacing={'1rem'} width="100%">
                                    <TextField sx={textFieldStyles}
                                        autoComplete="off" label="Disease Category" type="text"
                                        {...register("diseaseCategory", {
                                            required: {
                                                value: true,
                                                message: 'Enter disease category',
                                            },
                                        })}
                                        error={!!errors.diseaseCategory}
                                        helperText={errors.diseaseCategory?.message} />
                                    <TextField sx={textFieldStyles}
                                        autoComplete="off" label="Affiliation" type="text"
                                        {...register("affiliation", {
                                            required: {
                                                value: true,
                                                message: 'Enter an affiliation',
                                            },
                                        })}
                                        error={!!errors.affiliation}
                                        helperText={errors.affiliation?.message} />
        
          <hr style={{ border: "1.5px solid #E2E5F9",width:'100%'}} />

          <Stack 
            direction={{ xs: "column", sm: "row" }} 
            spacing={'1rem'} 
            alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <div style={{ flex: 1 }}>
              <FormLabel sx={{
                marginLeft: '6px',
                color: 'black',
                fontSize: {
                  xs: "0.975rem",
                  sm: "1.2rem"
                },
                fontWeight: "600"
              }}>
                Customized Curation
              </FormLabel>
              <div style={{
                marginLeft: '6px',
                color: 'black',
                fontSize: "0.93rem",
                fontWeight: "400",
                marginTop: "0.4rem",marginBottom:'1rem'
              }}>
                This feature allows users to request tailored dataset curation<br></br> based on their specific research needs.
              </div>
            </div>
             <Switch checked={customCuration} size="medium" onChange={() => setCustomCuration(!customCuration) }/>
          </Stack>

          {customCuration && (
            <Stack 
              direction={{ xs: "column", sm: "row" }} 
              spacing={'1rem'}
              alignItems={{ xs: "flex-start", sm: "center" }}
              sx={{ mt: 0.5 }}
              justifyContent={"space-between"}
            >
              <FormLabel sx={{
                marginLeft: '6px',
                color: 'black',
                fontSize: {
                  xs: "0.75rem",
                  sm: "1rem"
                },
                fontWeight: "400"
              }}>
                Customized Metadata Fields
              </FormLabel>
              <div style={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                <label
                  htmlFor="curationFile"
                  style={{
                    backgroundColor: "#364098",
                    color: 'white',
                    padding: '4px 10px',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    fontSize: '0.913rem',
                    fontWeight: '500',
                    textAlign: 'center',
                    minWidth: '90px'
                  }}
                >
                  Choose File
                </label>

                <input
                  style={{ display: 'none' }}
                  id="curationFile"
                  type="file"
                  {...register("curationFile", {
                    validate: (curationFile) => {
                      const formats = [".txt", ".csv", ".xls", ".xlsx", ".TXT", ".CSV", ".XLS", ".XLSX"];
                      if (curationFile && curationFile.length > 0) {
                        const file = curationFile[0];
                        const ext = file.name.slice(file.name.lastIndexOf('.'));
                        if (!formats.includes(ext)) {
                          return "Valid file formats (.txt, .csv, .xls or .xlsx)";
                        }
                        if (file.size > 5242880) {
                          return "File size should be less than 5MB";
                        }
                      }
                      return true;
                    },
                    onChange: (e) => {
                      const fileName = e.target.files?.[0]?.name || "No file chosen";
                      document.getElementById("file-name")!.textContent = fileName;
                    }
                  })}
                />
                <span id="file-name" style={{ 
                  fontSize: '0.788rem', 
                  color: '#666',
                  marginTop: '2px' 
                }}>
                  No file chosen
                </span>
                {errors.curationFile && (
                  <div style={{
                    color: '#ef5350',
                    fontSize: '0.688rem',
                    marginTop: '1px'
                  }}>
                    {errors.curationFile?.message}
                  </div>
                )}
              </div>
            </Stack>
          )}

          <hr style={{ border: "1px solid #E2E5F9", margin: "0.25rem 0" }} />

          <Stack direction="column" spacing={'1.2vw'}>
            <FormLabel sx={{
              marginLeft: '6px',
              color: 'black',
              fontSize: {
                xs: "0.9rem",
                sm: "1.2rem"
              },
              fontWeight: "600"
            }}>
              Source of Dataset
            </FormLabel>
            <RadioGroup
              defaultValue="Publicly Available Datasets"
              sx={{
                marginLeft: '6px',
                marginTop: '2px',
                flexDirection: "row",
                gap: { xs: 0.25, sm: 2 }
              }}
              name="datasetSource"
              onChange={(event) => {
                setShowDatasetUpload(event.target.value === 'In-house Datasets');
              }}
            >
              <FormControlLabel
                value="Publicly Available Datasets"
                control={<Radio size="small" />}
                label="Publicly Available Datasets"
                {...register("datasetSource")}
              />
              <FormControlLabel
                value="In-house Datasets"
                control={<Radio size="small" />}
                label="In-house Datasets"
                {...register("datasetSource")}
              />
            </RadioGroup>
          </Stack>

          <hr style={{ border: "1px solid #E2E5F9", width:'100%' }} />

          {showDatasetUpload ? (
            <Button
              variant="contained"
              href="mailto:bioinformatics@strandls.com"
              sx={{
                height: {
                  xs: "36px",
                  sm: "40px",
                  md: "44px"
                },
                backgroundColor: '#364098',
                textTransform: 'none',
                fontSize: {
                  xs: "0.813rem",
                  sm: "0.875rem",
                  md: "1rem"
                },
                fontWeight: '600',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '4px',
                borderRadius: '10px',
                "&:hover": {
                  backgroundColor: '#2a3275'
                }
              }}
            >
              <IoMail style={{ marginRight: '6px', marginTop: '-2px' }} size={18} />
              Contact Us
            </Button>
          ) : (
            <TextField sx={textFieldStyles}
                                        autoComplete="off" label="Accession URL" type="text"
                                        {...register("url", {
                                            required: {
                                                value: true,
                                                message: 'Enter an URL for source of dataset',
                                            },
                                            validate: (url) => {
                                                return isURL(url, { require_protocol: true }) || "Enter a valid URL";
                                            },
                                        })}
                                        error={!!errors.url}
                                        helperText={errors.url?.message} />)}

          {!showDatasetUpload && (
            <Button
              disabled={disableSubmit}
              id="submit"
              type="submit"
              variant="contained"
              sx={{
                height: {
                  xs: "36px",
                  sm: "40px",
                  md: "44px"
                },
                backgroundColor: '#364098',
                textTransform: 'none',
                fontSize: {
                  xs: "0.813rem",
                  sm: "0.875rem",
                  md: "1rem"
                },
                fontWeight: '600',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '4px',
                borderRadius: '10px',
                "&:hover": {
                  backgroundColor: '#2a3275'
                }
              }}
            >
              {disableSubmit ? "Submitting..." : "Submit Request"}
              {disableSubmit && <CircularProgress size={16} style={{ color: 'white' }} />}
            </Button>
          )}
        </Stack>
      </form>
    </div>
    </div>
  </Fragment>
    );
}

export default CurationRequest;
