import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../store/slices/userSlice";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import NavigationAppBar from "./NavigationAppBar";
import BreadcrumbNavigation from "./BreadcrumbNavigation";
import DataTable from "./DataTable";
import DiseaseTabs from "./DiseaseTabs";
import { CurationRequestLoader } from "./ComponentLoader";
import { Box, Button, Typography } from "@mui/material";
import plus from '../images/tabler_plus.svg'
import dna from '../images/dna-cr.png'
import ellipse from "../images/Ellipse 3.png"
import NoRequests from "../pages/NoCuration_Requests";

interface ColumnConfig {
    Id: string;
    label: string;
    render: (Datasets: any) => any;
    isVisible: boolean;
    width: number;
}

interface TableProps {
    config: ColumnConfig[];
    requestFor: string;
    breadcrumbs: string[];
    width: number;
    rowkey: string;
    apiParams:any|null;
    curating: string;
}

function NonCurationTables({ config, requestFor, breadcrumbs, width, rowkey ,apiParams,curating}: TableProps) {
    const [rows, setRows] = useState([]);
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [totalRows, setTotalRows] = useState(0);
    const [requestsFetched, setRequestsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    
    const fetchRequests = async (page: number = 1, perPage: number = 15) => {
        setIsLoading(true);
        try {
            const response = await axiosConfig.get(`/api/${requestFor}?page=${page}&per_page=${perPage}`);
            setTotalRows(response.data.no_of_records);
            setRows(response.data.data);
            setRequestsFetched(true);
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    const refreshedResponse = await axiosConfig.get(`/api/${requestFor}?page=${page}&per_page=${perPage}`);
                    setTotalRows(refreshedResponse.data.no_of_records);
                    setRows(refreshedResponse.data.data);
                    setRequestsFetched(true);
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchRequests();
    // eslint-disable-next-line
    }, []);
    let dataTableProps = {
        fetchFunction: fetchRequests,
        totalRows: totalRows,
        table: rows,
        columns: config.filter((column) => column.isVisible),
        width: width,
        rowkey: rowkey,
        selectedRows: [],
        handleNavigation: () => {},
        apiParams: apiParams,
        curation:curating,
        isExpanded:false
    };

    return (
        <Fragment>
            <div><NavigationAppBar includeSearch={false}/></div>
          
            <div className="flex-1 flex flex-col"  style={{marginTop: "75px",position:'relative',overflowX:'hidden'}}>
              {apiParams.page_name === 'public_dataset' ? (
        <>
            <img 
                src={dna} 
                alt="landingBgg" 
                style={{ 
                    position: "absolute", 
                    top: "-1vw", 
                    right: "-3vw", 
                    maxWidth: "18vw", 
                    minWidth: "100px", 
                    height: "auto", 
                    objectFit: "contain"
                }} 
            />
            <img 
                src={ellipse} 
                alt="landing" 
                style={{ 
                    position: "absolute", 
                    top: "-1vw", 
                    right: "-5vw", 
                    maxWidth: "22vw", 
                    minWidth: "120px",  
                    height: "auto", 
                    objectFit: "contain"
                }} 
            />
        </>
    ) : null}

                    <div style={{marginLeft:"35px"}}>
                        <BreadcrumbNavigation breadcrumbs={[{path: breadcrumbs[0], text: breadcrumbs[1]}, {path: breadcrumbs[2], text: breadcrumbs[3]}]}/>
                    </div>

                    <div style={{ display: 'flex',justifyContent:'space-between'}}>
                    
                        <div>
                        <Typography variant="h1" component="h1" style={{ 
                            marginTop:"5px",                  
                            marginBottom: "20px",
                            minWidth: "150px",
                            lineHeight: "23px",
                            letterSpacing: "-0.84px",
                            marginLeft:"43px",
                            fontWeight:'600'
                        }}>
                            {apiParams.page_name==='curation_request'?"My Curation Requests":"Summary of Datasets"}
                        </Typography>  
                        </div>

                        {apiParams.page_name==='curation_request'?
                        <div style={{marginRight:'41px',marginTop:'-10px'}}>
                            <Button  onClick={()=>{navigate('/create-curation-request');}} variant="contained" style={{backgroundColor: '#364098', textTransform: 'none', fontSize: '18px', fontWeight: '500', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px',borderRadius:'10px' }}>
                                <img src={plus} alt="plus"></img>
                                New Curation Request
                            </Button>
                        </div>:
                        <></>
                        }
                    
                    </div>
                     <div>
                        {apiParams.page_name!=='curation_request'?
                            <Box
                                sx={{
                                    marginLeft:'35px',
                                    borderBottom: 1,
                                    borderColor: "divider",
                                    bgcolor: "#EEEFFF",
                                    p: '2px',
                                    borderRadius: "12px",
                                    overflowX:'auto',
                                    width:'79vw',
                                    maxWidth:'95vw',
                                    minHeight:'50px'
                                }}
                                >
                            <DiseaseTabs/>
                        </Box>:
                        <div style={{marginBottom:'3px'}}></div>}

                    </div>

                     <div className="flex-1" style={{marginLeft:'30px'}}>
                
                {requestsFetched ? (
                    <DataTable {...dataTableProps} />
                ) : (
                    <CurationRequestLoader />
                )}
                
            </div>

            </div>

        </Fragment>
    );
}

export default NonCurationTables;




