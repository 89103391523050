import './assets/css/output.css'
import './assets/css/input.css'
import logo from "../companylogo.png";
import aboutmission from "../images/aboutmission.png";
import arrowR from "../images/arrow-r.svg";
import bg from "../images/bg.png";
import c from "../images/c.svg";
import d from "../images/d.svg";
import dna from "../images/dna.png";
import faq from "../images/faq.png";
import footerLogo from "../images/footer-logo.png";
import footer from "../images/footer.png";
import landingBgg from "../images/landing-bgg.png";
import mv from "../images/mv.svg";
import q from "../images/q.png";
import r from "../images/r.svg";
import secondBg from "../images/second-bg.png";
import { useState,useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { updateDiseaseCategory } from '../store/slices/diseaseCategorySlice';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilters } from '../store/slices/filtersSlice';
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { login } from "../store/slices/userSlice";
import { hashSync } from "bcrypt-ts";
import { selectUser} from '../store/slices/userSlice'; 
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from '../axiosConfig';
import { Avatar, Divider, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import { loadCaptchaEnginge, validateCaptcha, LoadCanvasTemplateNoReload  } from "react-simple-captcha";
import { TbReload } from "react-icons/tb";
import { isPhoneValid } from "../pages/SignupForm";
import { CustomPhone } from "../components/CustomPhone";
import { useFormContext } from 'react-hook-form';
import { Controller } from "react-hook-form";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Snackbar, Alert } from '@mui/material';

const LandingNew: React.FC = () => { 
 
    const user = useSelector(selectUser);
    useEffect(() => {
        const openNavMenu = document.getElementById('openNavMenu');
        const closeNavMenu = document.getElementById('closeNavMenu');
        const overlay = document.getElementById('overlay');
        const menuNav = document.getElementById('menuNav');
      
      
    function openMenu() {
        if (menuNav && overlay) {
          menuNav.style.transform = 'translateX(0)';
          overlay.style.opacity = '1';
          overlay.style.pointerEvents = 'auto';
        }
      }
    
      function closeMenu() {
        if (menuNav && overlay) {
          menuNav.style.transform = 'translateX(100%)';
          overlay.style.opacity = '0';
          overlay.style.pointerEvents = 'none';
        }
      }
    
      function init() {
        if (menuNav && overlay) {
          menuNav.style.transform = 'translateX(100%)';
          overlay.style.opacity = '0';
          overlay.style.pointerEvents = 'none';
        }
      }
    
      init();
      
        openNavMenu?.addEventListener('click', openMenu);
        closeNavMenu?.addEventListener('click', closeMenu);
        overlay?.addEventListener('click', closeMenu);
      
        // Modal functions
        const openModal = (modalId: string) => {
          document.querySelectorAll('.modal-overlay.show').forEach(openModal => {
            openModal.classList.remove('show');
          });
      
          const modal = document.getElementById(modalId);
          if (modal) {
            modal.classList.add('show');
          }
      
          // Reattach event listeners for buttons inside the newly opened modal
          attachModalEventListeners();
        };
      
        const closeModal = (modalId: string) => {
          const modal = document.getElementById(modalId);
          if (modal) {
            modal.classList.remove('show');
          }
        };
      
        const attachModalEventListeners = () => {
          document.querySelectorAll('.open-modal-button').forEach(button => {
            button.addEventListener('click', () => {
              const modalId = button.getAttribute('data-modal-target');
              openModal(modalId!);
            });
          });
      
          document.querySelectorAll('.close-modal-button').forEach(button => {
            button.addEventListener('click', () => {
              const modalId = button.getAttribute('data-modal-close');
              closeModal(modalId!);
            });
          });
      
          // Handle Escape key to close modals
          document.addEventListener('keydown', handleEscapeKey);
        };
      
        const handleEscapeKey = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            document.querySelectorAll('.modal-overlay.show').forEach(modal => {
              modal.classList.remove('show');
            });
          }
        };
      
        // Attach initial modal event listeners
        attachModalEventListeners();
      
        return () => {
          openNavMenu?.removeEventListener('click', openMenu);
          closeNavMenu?.removeEventListener('click', closeMenu);
          overlay?.removeEventListener('click', closeMenu);
          
          // Clean up modal event listeners
          document.querySelectorAll('.open-modal-button').forEach(button => {
            button.removeEventListener('click', () => {
              const modalId = button.getAttribute('data-modal-target');
              openModal(modalId!);
            });
          });
      
          document.querySelectorAll('.close-modal-button').forEach(button => {
            button.removeEventListener('click', () => {
              const modalId = button.getAttribute('data-modal-close');
              closeModal(modalId!);
            });
          });
      
          // Remove Escape key listener
          document.removeEventListener('keydown', handleEscapeKey);
        };
      }, [user.isAuthenticated]);

    const schema = yup.object({
        email: yup.string().email("Enter a valid email").required("Enter an email"),
        password: yup.string().required("Enter a password"),
    });
    
    type FormValues = {
        email: string
        password: string
    }
    const dispatch = useDispatch();
    const linkStyles: React.CSSProperties = { textDecoration: 'none', color: 'black', cursor: 'pointer' };


    function LogoutMenu() {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const loggedUser = useSelector(selectUser);
        setAccessToken(loggedUser.accessToken);
        const handleLogoutButton = async () => {
            try {
                const response = await axiosConfig.post('/api/logout');
                if (response.data.status === "SUCCESS") {
                    handleLogout(dispatch,navigate);
                }
            }catch(error: any) {
                try{
                    if (error.response.data.msg === "Token has been revoked") {
                        handleLogout(dispatch, navigate);
                    }
                    if (error.response.data.msg === "Token has expired") {
                        const response = await handleRefresh(loggedUser.refreshToken);
                        refreshAccessToken(dispatch, loggedUser, response);
                        if((await axiosConfig.post('/api/logout')).data.status === "SUCCESS") {
                            handleLogout(dispatch, navigate);
                        }
                    }
                }catch(error: any) {
                    if (error.response.data.msg === "Token has expired") {
                        handleLogout(dispatch,navigate);
                    }
                }
            }
        }
        return (
            <>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <IconButton onClick={handleClick} size="small" sx={{ ml: 2, mb: 0.5 }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
                        <Avatar sx={{width: 48, height: 48, fontSize: '30px', fontWeight: 'bold'}}>{String(loggedUser.username)[0].toUpperCase()}</Avatar>
                    </IconButton>
                </Box>
                <Menu anchorEl={anchorEl} id="logoutmenu" open={open} onClose={(handleClose)} onClick={handleClose} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <MenuItem style={{paddingRight: '50px'}} onClick={handleClose}>
                        <ListItemText primary={loggedUser.username} secondary={loggedUser.email}/>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={() => { navigate('/user-profile'); }}>
                        <Link key="profile" to={'/user-profile'} style={linkStyles}>Profile</Link>
                    </MenuItem>
                    <MenuItem onClick={handleLogoutButton}>
                        <Link key="Logout" to={'/login'} style={linkStyles}>Logout</Link>
                    </MenuItem>
                </Menu>
            </>
        );
    }

function LoginForm() {
    const form = useForm<FormValues>({
      defaultValues: {
        email: '',
        password: '',
      },
      resolver: yupResolver(schema),
    });
  
    const { register, handleSubmit, reset, formState, setError } = form;
    const { errors } = formState;
  
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState<string>('');
    const [disableSubmit, setDisableSubmit] = useState(false);
  
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Modify the onSubmit function to open the modal if login is successful
    const onSubmit = async (data: FormValues) => {
      try {
        setDisableSubmit(true);
        const passwordHash = hashSync(data.password, process.env.REACT_APP_API_SALT!);
        const response = await axiosConfig.post('/api/login', { email: data.email, user_password: passwordHash });
    
        if (response.data.status === 'SUCCESS') {
          setFeedback('Login successful');
          // Open the modal here
          setIsModalOpen(true); 
    
          dispatch(login({
            username: response.data.username,
            email: data.email,
            isAuthenticated: true,
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
          }));
          navigate('/', { replace: true });
          reset();
        } else if (response.data.data === 'Password change is required') {
          dispatch(login({
            username: null,
            email: response.data.email,
            isAuthenticated: false,
            accessToken: null,
            refreshToken: null,
          }));
          navigate('/change-password', { replace: true });
        }
      } catch (error: any) {
        if (error?.response?.data?.data === 'Invalid credentials') {
          setDisableSubmit(false);
          setError('password', { type: 'required', message: 'Invalid credentials' });
          setError('email', { type: 'required', message: 'Invalid credentials' });
          setFeedback('Invalid credentials');
        }
      } finally {
        setDisableSubmit(false);
      }
    };
    const handleModalToggle = () => {
      const modal = document.getElementById('loginModal');
      if (modal) {
        modal.classList.toggle('hidden'); // Toggle visibility of the modal
      }
    };
  
    return (
      <div id="loginModal" className="modal-overlay hidden">
        <div className="modal-background"></div>
        <div className="modal-content bg-white rounded-[3vw] md:rounded-[.85vw] px-[4vw] md:px-[2.5vw] py-[5vw] md:py-[2.5vw] w-[90vw] md:w-[37vw] relative">
          <button data-modal-close="loginModal" className="close-modal-button absolute top-[2vw] right-[2vw]" onClick={handleModalToggle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
              <path fill="#000000" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" />
            </svg>
          </button>
  
          <div className="font-og">
            <div className="flex justify-center">
              <img src={logo} className="w-[25vw] md:w-[10vw]" alt="Logo" />
            </div>
            <div className="mt-[6vw] md:mt-[3vw]">
              <p className="text-[5vw] md:text-[2.5vw] leading-[1] text-center">
                Log in to ScRNA data <br /> curation portal v1.0
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-[3vw] md:gap-[1vw] mt-[7vw] md:mt-[4vw]">
              <div className="relative">
                <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                <input
            type="email"
            {...register("email", {
                required: {
                    value: true,
                    message: "Enter email Id",
                },
                pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Enter a valid email",
                }
            })}
            placeholder="Email ID"
            className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
          />
        </div>
        {errors.email && <span style={{ color: 'red' }}>{errors.email.message}</span>}
      </div>
              <div className="relative">
                <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                  <input
                    required
                    type="password"
                    placeholder="Password"
                    {...register('password')}
                    className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
                  />
                  </div>
                  {errors.password && <span style={{ color: 'red' }}>{errors.password.message}</span>}
              </div>
              <div className="relative mt-[2vw]">
                <button
                  type="submit"
                  className="rounded-[2vw] md:rounded-[.5vw] overflow-hidden bg-black text-white w-full text-[4vw] md:text-[1.1vw] px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw]"
                  disabled={disableSubmit}
                >
                  <p>Log In</p>
                </button>
              </div>
            </form>
  
            <div className="mt-[5vw] md:mt-[2vw]">
              <div className="flex justify-center text-[3.25vw] md:text-[1.1vw] gap-[1.5vw] md:gap-[.5vw]">
                Don’t have an account?
                <button data-modal-target="signupModal" className="open-modal-button border border-black text-[3vw] md:text-[.85vw] leading-[1] rounded-full px-[2vw] md:px-[.75vw] py-[.75vw] md:py-[.2vw]">
                  Sign Up
                </button>
              </div>
            </div>
            <div className="mt-[3vw] md:mt-[1vw]">
              <div className="flex justify-center text-[3.25vw] md:text-[1.1vw] gap-[.5vw]">
                Forgot Password?
                {/* <button data-modal-target="resetPasswordModal" className="text-[#F14234] open-modal-button" onClick={handleModalToggle}>
                  Reset Password
                </button> */}
                <Link to="/forgot-password" className="text-[#F14234] open-modal-button" >
                  Reset Password
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
function PasswordReset(){

    type FormValue = {
        email: string
        phoneNumber: string
    }
    
    const form = useForm<FormValue>({
        defaultValues: {
            email: '', phoneNumber: '',
        }
    });
    useEffect(() => {
        loadCaptchaEnginge(6);
    },[]);

    const navigate = useNavigate();
    const { register, handleSubmit, reset, formState, setError } = form;
    const { errors } = formState;
    const [feedback, setFeedback] = useState<string>('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [muiPhone, setMuiPhone] = useState("+91");

    const onSubmit = async (data: FormValue) => {
        try {
            setDisableSubmit(true);
            const response = await axiosConfig.post('/api/forget_password', {"email": data.email, "phone": data.phoneNumber});
            if (response.data.status === "SUCCESS") {
                setDisableSubmit(false);
                setFeedback("Password successfully sent to email");
                setTimeout(() => { 
                    navigate('/login');
                    reset();
                }, 1000);
            }
        }catch(error: any) {
            if (error.response.data.data === "User not found") {
                setDisableSubmit(false);
                setError("email", { type: "required", message: "User not found" });
                setError("phoneNumber",{ type: "required", message: "User not found" });
            }
        }
    };

    return(
        <div id="resetPasswordModal" className="modal-overlay hidden">
        <div className="modal-background"></div>
        <div className="modal-content bg-white rounded-[3vw] md:rounded-[.85vw] px-[4vw] md:px-[2.5vw] py-[5vw] md:py-[2.5vw] w-[90vw] md:w-[37vw] relative">
            <button data-modal-close="resetPasswordModal" className="close-modal-button absolute top-[2vw] right-[2vw] md:top-[1vw] md:right-[1vw]">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
                    <path fill="#000000" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" />
                </svg>
            </button>


            <div className="font-og">
                <div className="flex justify-center">
                    <img src={logo} className="w-[25vw] md:w-[10vw]" alt="Logo"/>
                </div>
                <div className="mt-[6vw] md:mt-[3vw]">
                    <p className="text-[5vw] md:text-[2.5vw] leading-[1] text-center">Forgot Password</p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} noValidate className="flex flex-col gap-[3vw] md:gap-[1vw] mt-[7vw] md:mt-[4vw]">
                    <div className="relative">
                        <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                            <input required type="email" placeholder="Email Address" className="text-[4vw] md:text-[1.1vw] w-full h-full  px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
                            {...register("email", {
                                required: {
                                    value: true,
                                    message: "Enter your email",
                                },
                                pattern: {
                                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    message: "Enter a valid email",
                                }
                            })}/>
                        </div>
                         {errors.email&& <span style={{ color: 'red' }}>{errors.email.message}</span>}
                        </div>
                    <div className="relative">
                        <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                            {/* <input required type="tel"  placeholder="Phone" className="text-[4vw] md:text-[1.1vw] w-full h-full  px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
                             {...register('phoneNumber', {
                                required: 'Phone number is required',
                                pattern: {
                                  value: /^\d{10}$/,
                                  message: 'Enter a valid phone number',
                                },
                              })}/> */}
                               <Controller name="phoneNumber" control={form.control} defaultValue="" 
                                    render={({ field }) => ( <CustomPhone value={muiPhone} onChange={(value) => { setMuiPhone(value); field.onChange(value); }}
                                    helperText={errors.phoneNumber?.message} error={!!errors.phoneNumber} size="medium"/> )}
                                    rules={{ required: 'Enter phone number',
                                        validate: (muiPhone) => {
                                            return isPhoneValid(muiPhone) || "Enter a valid phone number";
                                        },
                                }}/>
                        </div>
                    </div>
                   
                      {/* <div style={{display:'flex'}}>
                                <LoadCanvasTemplateNoReload/>
                           <div style={{cursor:'pointer'}} onClick={() => loadCaptchaEnginge(6)}><TbReload/></div>
                        </div>
                      <div className="relative">
                      <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                        <input
                        maxLength={6} // Limit captcha to 6 characters
                        autoComplete="off"
                        {...register('captcha', {
                            required: 'Enter the captcha text',
                            validate: (userCaptcha) =>
                            validateCaptcha(userCaptcha, false) || 'Captcha does not match', // Custom CAPTCHA validation function
                        })}
                        placeholder="Enter Captcha"
                        className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
                        />
                    </div>
                    {errors.captcha && <span style={{ color: 'red' }}>{errors.captcha.message}</span>}
                    </div> */}

                    
                    <div className="relative mt-[5vw] md:mt-[2vw]">
                        <button disabled={disableSubmit} type="submit" className="rounded-[2vw] md:rounded-[.5vw] overflow-hidden bg-black text-white w-full text-[4vw] md:text-[1.1vw] px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw]">
                            <p>Continue</p>
                        </button>
                    </div>
                </form>

                <div className="mt-[5vw] md:mt-[2vw]">
                    <div className="flex justify-center text-[3.25vw] md:text-[1.1vw] gap-[1.5vw] md:gap-[.5vw]">
                        Already have an account?
                        <button data-modal-target="loginModal" className=" open-modal-button border border-black text-[3vw] md:text-[.85vw] leading-[1] rounded-full px-[2vw] md:px-[.75vw] py-[.75vw] md:py-[.2vw]">
                            Log In
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

function SignUp(){

    const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

    type FormValues = {
        fullName: string
        personalEmailId: string
        workEmailId: string
        phoneNumber: string
        work: string
        designation: string
        about: string
        captcha: string
    }
        useEffect(() => {
            loadCaptchaEnginge(6);
        },[]);
        const form = useForm<FormValues>({
            defaultValues: {
                fullName: '',
                personalEmailId: '',
                workEmailId: '',
                phoneNumber: '',
                work: '',
                designation: '',
                about: '',
                captcha: '',
            }
        });
        const phoneUtil = PhoneNumberUtil.getInstance();
        const isPhoneValid = (phone: string) => {
            try {
                return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
            }catch(error: any) {
                return false;
            }
        };
        const [muiPhone, setMuiPhone] = useState("+91");
                    // Handle modal close
        const closeModal = () => {
            setIsSignUpModalOpen(false);
            reset(); // Reset form fields when closing the modal
        };
        const { register, handleSubmit, reset, formState, setError } = form;
        const { errors } = formState;
        const navigate = useNavigate();
        const [feedback, setFeedback] = useState<number | null>(null);
        const [disableSubmit, setDisableSubmit] = useState(false);

        const resetform = () => {
            reset(); // Reset form fields when opening the modal
        };

        
        const onSubmit = async (data: FormValues) => {
            try {
                setDisableSubmit(true);
                const response = await axiosConfig.post('/api/signup', {
                        "name": data.fullName,
                        "personal_email": data.personalEmailId,
                        "work_email": data.workEmailId,
                        "phone": data.phoneNumber,
                        "academic_affiliation": data.work,
                        "designation": data.designation,
                        "purpose": data.about,
                        "strand_restriction": process.env.REACT_APP_STRAND_RESTRICTION,
                    }
                );
                if (response.data.status === "SUCCESS") {
                    setDisableSubmit(false);
                    setFeedback(1);
                    setTimeout(() => { 
                        navigate('/signup-success', { state: 'signup' } );
                        reset();
                    }, 0);
                }
            }catch(error: any){
                if (error.response.data.data === "Email already exists") {
                    setDisableSubmit(false);
                    setError("personalEmailId", { type: "required", message: "Email already exists" });
                    setError("workEmailId", { type: "required", message: "Email already exists" });
                    setFeedback(2);
                }
            }
        };
  
        
    return(
<div id="signupModal" className={`modal-overlay overflow-y-scroll !items-start ${isSignUpModalOpen ? '' : 'hidden'}`}>
   <div className="modal-background"></div>
    <div className="modal-content bg-white rounded-[3vw] md:rounded-[.85vw] px-[4vw] md:px-[2.5vw] py-[5vw] md:py-[2.5vw] w-[90vw] md:w-[37vw] relative my-[20vw] md:my-[1vw]">
        <button onClick={resetform} data-modal-close="signupModal" className="close-modal-button absolute top-[2vw] right-[2vw] md:top-[1vw] md:right-[1vw]">
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
                <path fill="#000000" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" />
            </svg>
        </button>
    
        <div className="font-og">
            <div className="">
                <p className="text-[5vw] md:text-[2.5vw] leading-[1] text-center">Sign up to ScRNA data<br/>curation portal v1.0</p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} noValidate className="flex flex-col gap-[3vw] md:gap-[1vw] mt-[7vw] md:mt-[4vw]">
      {/* Full Name */}
      <div className="relative">
        <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
          <input
            type="text"
            {...register('fullName', { required: 'Full Name is required' })}
            placeholder="Full Name"
            className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
          />
        </div>
        {errors.fullName && <span style={{ color: 'red' }}>{errors.fullName.message}</span>}
      </div>

      {/* Personal Email */}
      <div className="relative">
        <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
          <input
            type="email"
            {...register("personalEmailId", {
                required: {
                    value: true,
                    message: "Enter personal email",
                },
                pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Enter a valid email",
                }
            })}
            placeholder="Personal Email ID"
            className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
          />
        </div>
        {errors.personalEmailId && <span style={{ color: 'red' }}>{errors.personalEmailId.message}</span>}
      </div>

      {/* Work Email */}
      <div className="relative">
        <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
          <input
            type="email"
            {...register("workEmailId", {
                required: {
                    value: true,
                    message: "Enter work email",
                },
                pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Enter a valid email",
                },
                validate: (workEmailId) => {
                    let domain = workEmailId.split('@')[1].toLowerCase();
                    if ( domain === "gmail.com" || domain === "yahoo.com" || domain === "hotmail.com" || domain === "outlook.com") {
                        return "Enter a valid work email";
                    }
                    if (process.env.REACT_APP_STRAND_RESTRICTION === "Y" && domain !== "strandls.com") {
                        return "Only @strandls domain allowed";
                    }
                },
            })}
            placeholder="Work Email ID"
            className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
          />
        </div>
        {errors.workEmailId && <span style={{ color: 'red' }}>{errors.workEmailId.message}</span>}
      </div>

      {/* Phone Number */}
      <Controller name="phoneNumber" control={form.control} defaultValue="" 
                                    render={({ field }) => ( <CustomPhone value={muiPhone} onChange={(value) => { setMuiPhone(value); field.onChange(value); }}
                                    helperText={errors.phoneNumber?.message} error={!!errors.phoneNumber}  className="w-full p-2 text-base no-highlight"/> )}
                                    rules={{ required: 'Enter phone number',
                                        validate: (muiPhone) => {
                                            return isPhoneValid(muiPhone) || "Enter a valid phone number";
                                        },
                                }}/>
      {/* Work / Academic Affiliation */}
      <div className="relative">
        <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
          <input
            type="text"
            {...register("work", {
                required: {
                    value: true,
                    message: "Enter work/academic affiliation",
                },
                validate: (work) => {
                    return work.length >= 5 || "Academic Affiliation must be at least 5 characters long" ; 
                },
            })}            placeholder="Work/Academic Affiliation"
            className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
          />
        </div>
        {errors.work && <span style={{ color: 'red' }}>{errors.work.message}</span>}
      </div>

      {/* Designation */}
      <div className="relative">
        <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
          <input
            type="text"
            {...register("designation", {
                required: {
                    value: true,
                    message: "Enter your designation",
                },
                validate: (designation) => {
                    return designation.length >= 5 || "Designation must be at least 5 characters long" ; 
                },
            })}
            placeholder="Designation"
            className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
          />
        </div>
        {errors.designation && <span style={{ color: 'red' }}>{errors.designation.message}</span>}
      </div>

      {/* About */}
      <div className="relative">
        <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
          <textarea
            {...register('about', { required: 'This field is required' })}
            placeholder="What brings you to this portal?"
            className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
          ></textarea>
        </div>
        {errors.about && <span style={{ color: 'red' }}>{errors.about.message}</span>}
      </div>
 <div style={{display:'flex'}}>
                                    <LoadCanvasTemplateNoReload/>
                                    <div style={{cursor:'pointer'}} onClick={() => loadCaptchaEnginge(6)}><TbReload/></div>
                                </div>
        <div className="relative">
                    <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                        <input
                        maxLength={6} // Limit captcha to 6 characters
                        autoComplete="off"
                        {...register('captcha', {
                            required: 'Enter the captcha text',
                            validate: (userCaptcha) =>
                            validateCaptcha(userCaptcha, false) || 'Captcha does not match', // Custom CAPTCHA validation function
                        })}
                        placeholder="Enter Captcha"
                        className="text-[4vw] md:text-[1.1vw] w-full h-full px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"
                        />
                    </div>
                    {errors.captcha && <span style={{ color: 'red' }}>{errors.captcha.message}</span>}
                    </div>

      {/* Submit Button */}
      <div className="relative mt-[5vw] md:mt-[2vw]">
        <button type="submit" className="rounded-[2vw] md:rounded-[.5vw] bg-black text-white w-full text-[4vw] md:text-[1.1vw] px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw]">
          <p>Sign Up</p>
        </button>
      </div>
    </form>
            <div className="mt-[5vw] md:mt-[2vw]">
                <div className="flex justify-center text-[3.25vw] md:text-[1.1vw] gap-[.5vw]">
                    Already have an account?
                    <button data-modal-target="loginModal" className=" open-modal-button border border-black text-[3vw] md:text-[.85vw] leading-[1] rounded-full px-[2vw] md:px-[.75vw] py-[.75vw] md:py-[.2vw]">
                        Log In
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
    );
}

function ToggleAccordion(){
    const [openAccordion, setOpenAccordion] = useState<string | null>(null);

    // Function to toggle the accordion state
    const toggleAccordion = (accordionId: string) => {
        // If the clicked accordion is already open, close it. Otherwise, open the clicked one.
        setOpenAccordion(openAccordion === accordionId ? null : accordionId);
    };

    return (
        <div>
            {/* Accordion 1 */}
            <div className="group cursor-pointer" data-accordion="accordion-1">
                <button
                    onClick={() => toggleAccordion('accordion-1')}
                    className="flex items-center justify-between gap-[4vw] pb-[4vw] md:pb-[1.5vw] w-full text-left select-none"
                >
                    <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                        <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">01</span>
                        <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">
                            How long will it take to receive a custom-curated dataset?
                        </p>
                    </div>
                    <div className="relative">
                        <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                        <div className={`line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 ${openAccordion === 'accordion-1' ? '' : 'rotate-90'} top-0`}></div>
                    </div>
                </button>
                <div
                    className={`content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%] ${
                        openAccordion === 'accordion-1' ? '' : 'hidden'
                    }`}
                >
                    Our average turnaround time is 1-2 days.
                </div>
            </div>

            {/* Accordion 2 */}
            <div className="group cursor-pointer" data-accordion="accordion-2">
                <button
                    onClick={() => toggleAccordion('accordion-2')}
                    className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[1.5vw] w-full text-left select-none"
                >
                    <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                        <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">02</span>
                        <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">
                            Which open sources are used to harness the datasets?
                        </p>
                    </div>
                    <div className="relative">
                        <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                        <div className={`line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 ${openAccordion === 'accordion-2' ? '' : 'rotate-90'} top-0`}></div>
                    </div>
                </button>
                <div
                    className={`content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%] ${
                        openAccordion === 'accordion-2' ? '' : 'hidden'
                    }`}
                >
                    Each dataset has been curated from NCBI’s GEO platform.
                </div>
            </div>

            {/* Accordion 3 */}
            <div className="group cursor-pointer" data-accordion="accordion-3">
                <button
                    onClick={() => toggleAccordion('accordion-3')}
                    className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[1.5vw] w-full text-left select-none"
                >
                    <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                        <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">03</span>
                        <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">
                            Is this database revised regularly?
                        </p>
                    </div>
                    <div className="relative">
                        <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                        <div className={`line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 ${openAccordion === 'accordion-3' ? '' : 'rotate-90'} top-0`}></div>
                    </div>
                </button>
                <div
                    className={`content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%] ${
                        openAccordion === 'accordion-3' ? '' : 'hidden'
                    }`}
                >
                    We update our database every month.
                </div>
            </div>

            {/* Accordion 4 */}
            <div className="group cursor-pointer" data-accordion="accordion-4">
                <button
                    onClick={() => toggleAccordion('accordion-4')}
                    className="flex items-center justify-between gap-[4vw] pt-[4vw] md:pt-[2.5vw] pb-[4vw] md:pb-[1.5vw] w-full text-left select-none"
                >
                    <div className="flex gap-[3vw] md:gap-[1.75vw] flex-1">
                        <span className="text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] leading-[1]">04</span>
                        <p className="text-[3.85vw] md:text-[1.55vw] font-medium md:w-[70%] leading-[1.2] mt-[1vw]">
                            What are some of the applications of scRNA sequencing data?
                        </p>
                    </div>
                    <div className="relative">
                        <div className="line w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234]"></div>
                        <div className={`line-rotated w-[3vw] md:w-[1.5vw] h-[1.5px] bg-[#F14234] absolute left-0 ${openAccordion === 'accordion-4' ? '' : 'rotate-90'} top-0`}></div>
                    </div>
                </button>
                <div
                    className={`content text-[3.15vw] md:text-[1.3vw] text-[#2B2929] font-[350] pl-[5.5vw] md:pl-[3vw] pb-[5vw] md:pb-[2vw] leading-[1.3] md:leading-normal md:w-[75%] w-[90%] ${
                        openAccordion === 'accordion-4' ? '' : 'hidden'
                    }`}
                >
                    scRNA sequencing data can support AI/ML Applications, helps in Biomarker Discovery, Target Validation, in building Foundational Models and is advantageous in Patient Stratification and Disease Subtyping.
                </div>
            </div>
        </div>
    );
};


function BookDemo() {

    type FormValues = {
        firstName: string;
        lastName: string;
        email: string;
        organizationName: string;
    }
    

    const form = useForm<FormValues>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            organizationName: '',
        },
    });

    const { register, handleSubmit, reset, formState:{errors} } = form;
    const navigate = useNavigate();
    const [disableSubmit, setDisableSubmit] = useState(false);
    
    // useEffect(() => {
    //     loadCaptchaEnginge(6);
    // }, []);
    const resetform = () => {
        reset(); // Reset form fields when opening the modal
    };
    const onSubmit = async (data: FormValues) => {
        try {
            setDisableSubmit(true);
            const response = await axiosConfig.post('/api/book_a_demo', {
                    "fname": data.firstName,
                    "lname": data.lastName,
                    "email": data.email,
                    "organisation": data.organizationName,
                }
            );
            if (response.data.status === "SUCCESS") {
                setDisableSubmit(false);
                console.log("Demo booked");
                navigate('/book-demo-success',{state:'demo_booked'});
                reset();
            }
        }catch(error: any){
                console.error("An error occurred:", error);
                setDisableSubmit(false);   
        }        
    };
    return (
        <div id="bookModal" className="modal-overlay overflow-y-scroll !items-start hidden">
        <div className="modal-background"></div>
        <div className="modal-content bg-white rounded-[3vw] md:rounded-[.85vw] px-[4vw] md:px-[2.5vw] py-[5vw] md:py-[2.5vw] w-[90vw] md:w-[37vw] relative my-[40vw] md:my-[2vw]">
    <button onClick={resetform} data-modal-close="bookModal" className="close-modal-button absolute top-[2vw] right-[2vw] md:top-[1vw] md:right-[1vw]">
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
            <path fill="#000000" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" />
        </svg>
    </button>

    <div >
        <div className="flex justify-center">
            <img src={logo} className="w-[25vw] md:w-[10vw]" alt="Logo"/>
        </div>
        <div className="mt-[6vw] md:mt-[3vw]">
            <p className="text-[5vw] md:text-[2.5vw] leading-[1] text-center">Book a Demo</p>
        </div>
 
        <form onSubmit={handleSubmit(onSubmit)} noValidate className="flex flex-col gap-[3vw] md:gap-[1vw] mt-[7vw] md:mt-[4vw]">
            <div className="relative">
                <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                    <input required type="text"  {...register("firstName", { required: "First name is required" })} placeholder="First Name" className="text-[4vw] md:text-[1.1vw] w-full h-full  px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"/>
                    </div>
                    {errors.firstName && (
                     <span style={{ color: 'red' }}>{errors.firstName.message}</span>)}
                
            </div>
            <div className="relative">
                <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                    <input required type="text"  {...register("lastName", { required: "Last name is required" })} placeholder="Last Name" className="text-[4vw] md:text-[1.1vw] w-full h-full  px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"/>
                    </div>
                    {errors.lastName && (
                     <span style={{ color: 'red' }}>{errors.lastName.message}</span>)}
                
            </div>
            <div className="relative">
                <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                    <input required type="email"  {...register("email", { 
                                    required: "Email is required", 
                                    pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message: "Enter a valid email"} })}
                                    placeholder="Email ID" className="text-[4vw] md:text-[1.1vw] w-full h-full  px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"/>
                                     </div>
                                    {errors.email && (
                                    <span style={{ color: 'red' }}>
                                    {errors.email.message}
                                    </span>
                )}
               
            </div>
            <div className="relative">
                <div className="border border-[#A8A8A8] rounded-[2vw] md:rounded-[.5vw] overflow-hidden focus-within:border-black">
                    <input required type="text" {...register("organizationName", { required: "Organization name is required" })}
                    placeholder="Organization Name" className="text-[4vw] md:text-[1.1vw] w-full h-full  px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw] focus:outline-none focus:border-0 focus:ring-0"/>
                     </div>
                     {errors.organizationName && (
                     <span style={{ color: 'red' }}>{errors.organizationName.message}</span>)}
            </div>
            {/* <!-- Add the Captcha here -->
            <!-- ------------------- --> */}
            
            <div className="relative mt-[5vw] md:mt-[2vw]">
                <button disabled={disableSubmit} className="rounded-[2vw] md:rounded-[.5vw] overflow-hidden bg-black text-white w-full text-[4vw] md:text-[1.1vw] px-[4vw] md:px-[1vw] py-[2vw] md:py-[.75vw]">
                    <p>Submit</p>
                </button>
            </div>
        </form>
    </div>
</div>
    </div>


    );
}


function HandleCoversation(){

    type FormValues = {
        name: string;
        phone: string;
        email: string;
        customer_inquiries_message: string;
    }

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false); 

    const form = useForm<FormValues>({
        defaultValues: {
            name: '',
            phone: '',
            email: '',
            customer_inquiries_message: '',
        },
    });

    const showAlert = (message: string, type: 'success' | 'error') => {
        if (type === 'success') {
            setSuccessMessage(message);
            setErrorMessage('');
        } else if (type === 'error') {
            setErrorMessage(message);
            setSuccessMessage('');
        }
        
        setOpenSnackbar(true); 

        setTimeout(() => {
            setOpenSnackbar(false);
        }, 3000);
    };

    const { register, handleSubmit, reset, formState: { errors } } = form;
    const navigate = useNavigate();
    const [disableSubmit, setDisableSubmit] = useState(false);

    const onSubmit = async (data: FormValues) => {
        try {
            setDisableSubmit(true);
            setSuccessMessage('');
            setErrorMessage('');
            const response = await axiosConfig.post('/api/customer_inquiries', {
                "name": data.name,
                "phone": data.phone,
                "email": data.email,
                "message": data.customer_inquiries_message,
            });

            if (response.data.status === "SUCCESS") {
                setDisableSubmit(false);
                showAlert('Your message has been sent successfully!', 'success');
                reset();
            }
        } catch (error: any) {
            console.error("An error occurred:", error);
            showAlert('An error occurred while sending your message. Please try again.', 'error');
            setDisableSubmit(false);
        }
    };

         return(
            <div className="bg-[#171717] py-[15vw] md:py-[5.25vw] p-[5.25vw] md:w-[40%]">
                    <h2 className="text-[10vw] md:text-[3.5vw] text-[#ffffff] leading-[1] md:leading-[.92] font-[350] relative z-[0] pt-[1vw]">Let’s start<br/>A Conversation <span className="h-[2.5px] w-[10vw] md:w-[4vw] md:mb-[.9vw] mb-[2vw] bg-[#F14234] inline-block"></span></h2>
                    <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setOpenSnackbar(false)}
            >
                {successMessage ? (
                    <Alert severity="success">{successMessage}</Alert>
                ) : (
                    <Alert severity="error">{errorMessage}</Alert>
                )}
            </Snackbar>


                    <div className="mt-[6vw] md:mt-[3vw]">
                        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-[8vw] md:gap-[3.5vw]">
                            <div className="relative">
                                <div className="border-b border-white">
                                    <input required type="text"  {...register("name", { required: "Name is required" })}  placeholder="Name" className="text-[4vw] pb-[.35vw] md:text-[1.235vw] placeholder:text-[3.5vw] md:placeholder:text-[1.235vw] bg-transparent text-white font-[350] focus:outline-none focus:ring-0 w-full"/>
                                    </div>
                                    {errors.name && (
                                    <span style={{ color: 'red' }}>{errors.name.message}</span>)}
                                
                            </div>
                            <div className="relative">
                                <div className="border-b border-white">
                                <input
                               required
                                type="tel"
                                placeholder="Phone Number"
                                {...register("phone", {
                                    required: "Phone Number is required",
                                    pattern: {
                                        value: /^[0-9]+$/, 
                                        message: "Please enter a valid phone number "
                                    }
                                })}
                                className="text-[4vw] pb-[.35vw] md:text-[1.235vw] placeholder:text-[3.5vw] md:placeholder:text-[1.235vw] bg-transparent text-white font-[350] focus:outline-none focus:ring-0 w-full"
                            />
                        </div>
                        {errors.phone && (
                            <span style={{ color: 'red' }}>{errors.phone.message}</span>
                        )}
                                                        
                                </div>
                            <div className="relative">
                                <div className="border-b border-white">
                                <input required type="email"  {...register("email", { 
                                    required: "Email is required", 
                                    pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message: "Enter a valid email"} })}
                                    placeholder="Email ID" className="text-[4vw] pb-[.35vw] md:text-[1.235vw] placeholder:text-[3.5vw] md:placeholder:text-[1.235vw] bg-transparent text-white font-[350] focus:outline-none focus:ring-0 w-full"/>
                                     </div>
                                    {errors.email && (
                                    <span style={{ color: 'red' }}>
                                    {errors.email.message}
                                    </span>
                                )}
                            </div>
                            <div className="relative">
                                <div className="border-b border-white">
                                    <input required type="text" {...register("customer_inquiries_message", { required: "Message is required" })}  placeholder="Type your message here" className="text-[4vw] pb-[.35vw] md:text-[1.235vw] placeholder:text-[3.5vw] md:placeholder:text-[1.235vw] bg-transparent text-white font-[350] focus:outline-none focus:ring-0 w-full"/>
                                </div>
                                {errors.customer_inquiries_message&& (
                                    <span style={{ color: 'red' }}>
                                    {errors.customer_inquiries_message.message}
                                    </span>
                                )}   
                            </div>

                 <div className="relative mt-[1vw]">
                        <button type="submit" disabled={disableSubmit}>
                            <div className={`flex items-center ${disableSubmit ? 'opacity-50' : ''}`}>
                                <div className="flex items-center rounded-full border-[1.5px] border-[#FFFFFF] text-[#191818] h-[7vw] md:h-[2.65vw] bg-[#FFFFFF] font-og text-[3.05vw] md:text-[1.05vw] leading-[1] px-[3vw] py-[1vw] md:px-[1.25vw] md:py-[.65vw]">
                                    <p>{disableSubmit ? 'Submitting...' : 'SUBMIT MESSAGE'}</p>
                                </div>
                                <div className="bg-[#FFFFFF] h-[1.5px] md:h-[2.5px] w-[7vw] md:w-[2.75vw]"></div>
                                <div className="rounded-full aspect-square flex items-center justify-center bg-[#FFFFFF] h-[7vw] md:h-[2.65vw]">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-[#F14234] w-[3vw] md:w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                    <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                                    <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                                    <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                                    <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                    
                </div>
 );
}




const handleDiseaseCategoryChange = (selectedDiseaseCategory: string) => {
    dispatch(resetFilters());
    dispatch(updateDiseaseCategory(selectedDiseaseCategory));
};
useEffect(() => {
    // Reset filters when the component mounts
    dispatch(resetFilters());
}, [dispatch]); 

    return (
        <div className="custom-component">
        <header className="px-[5vw] md:px-[5.25vw] md:pb-[.5vw] py-[2.5vw] md:pt-[.8vw] border-b-[1.5px] border-[#191919] relative z-[1]">
        <div className="flex items-center justify-between">
            <a href="/" aria-label="Home Link">
                <img src={logo} className="w-[25vw] md:w-[10.8vw]" alt="logo" />
            </a>
            <div className="md:flex items-center gap-[3vw] hidden">
                <div className="flex gap-[2.25vw]">
                    <div className="group relative">
                        <a href="#" className="flex items-center gap-[.25vw] py-[1vw]">
                            <p className="font-og text-[#242424] text-[1.05vw]">Curation Request</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[135deg] text-[#F14234]" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
                            </svg>
                        </a>
                        <div className="top-full flex flex-col font-og text-[#242424] text-[1.05vw] absolute bg-white shadow w-max whitespace-nowrap duration-300 group-hover:opacity-100 opacity-0 group-hover:pointer-events-auto pointer-events-none">
                            <Link to={"/create-curation-request"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50">Create Curation Request</Link>
                            <Link to={"/curation-requests"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50">My Curation Requests</Link>
                        </div>
                    </div>
                    <div className="group relative">
                        <a href="#" className="flex items-center gap-[.25vw] py-[1vw]">
                            <p className="font-og text-[#242424] text-[1.05vw]">Datasets</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[135deg] text-[#F14234]" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
                            </svg>
                        </a>

                        <div className="top-full flex flex-col font-og text-[#242424] text-[1.05vw] absolute bg-white shadow w-max whitespace-nowrap duration-300 group-hover:opacity-100 opacity-0 group-hover:pointer-events-auto pointer-events-none">
                        <Link to={"/publicly-curated-datasets"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50">Curated Datasets from Public Resources</Link>
                
                <div className="group/first relative">
                    <div className="px-[1vw] flex items-center cursor-pointer justify-between py-[.5vw] w-full duration-300 hover:bg-gray-50">
                        <p>Study Level Curation</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[90deg] text-[#F14234]" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
                        </svg>
                    </div>
                    <div className="left-full w-max whitespace-nowrap group-hover/first:opacity-100 group-hover/first:pointer-events-auto absolute top-0 flex flex-col duration-300 bg-white shadow opacity-0 pointer-events-none">
                        <Link to={"/study-table"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50" onClick={() => handleDiseaseCategoryChange("Inflammatory Bowel Disease")}>
                            Inflammatory Bowel Disease
                        </Link>
                        <Link to={"/study-table"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50" onClick={() => handleDiseaseCategoryChange("Neurodegenerative Disease")}>
                            Neurodegenerative Disease
                        </Link>
                    </div>
                </div>
    
                <div className="group/second relative">
                    <div className="px-[1vw] flex items-center cursor-pointer justify-between py-[.5vw] w-full duration-300 hover:bg-gray-50">
                        <p>Experiment Level Curation</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[90deg] text-[#F14234]" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
                        </svg>
                    </div>
                    <div className="left-full w-max whitespace-nowrap group-hover/second:opacity-100 group-hover/second:pointer-events-auto absolute top-0 flex flex-col duration-300 bg-white shadow opacity-0 pointer-events-none">
                        <Link to={"/experiment-table"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50" onClick={() => handleDiseaseCategoryChange("Inflammatory Bowel Disease")}>
                            Inflammatory Bowel Disease
                        </Link>
                        <Link to={"/experiment-table"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50" onClick={() => handleDiseaseCategoryChange("Neurodegenerative Disease")}>
                            Neurodegenerative Disease
                        </Link>
                    </div>
                </div>
    
                <div className="group/third relative">
                    <div className="px-[1vw] flex items-center cursor-pointer justify-between py-[.5vw] w-full duration-300 hover:bg-gray-50">
                        <p>Sample Level Curation</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-[90deg] text-[#F14234]" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
                        </svg>
                    </div>
                    <div className="left-full w-max whitespace-nowrap group-hover/third:opacity-100 group-hover/third:pointer-events-auto absolute top-0 flex flex-col duration-300 bg-white shadow opacity-0 pointer-events-none">
                        <Link to={"/sample-table"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50" onClick={() => handleDiseaseCategoryChange("Inflammatory Bowel Disease")}>
                            Inflammatory Bowel Disease
                        </Link>
                        <Link to={"/sample-table"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50" onClick={() => handleDiseaseCategoryChange("Neurodegenerative Disease")}>
                            Neurodegenerative Disease
                        </Link>
                    </div>
                </div>
    
                <Link to={"/documentation"} className="inline-block px-[1vw] py-[.5vw] duration-300 hover:bg-gray-50">Documentation</Link>
                        </div>
                    
                    </div>
                    
                 
                    <Link to="/metadata-visualization" className="flex items-center gap-[.25vw] py-[1vw]">
                        <p className="font-og text-[#242424] text-[1.05vw]">Metadata Visualization</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.15vw] rotate-45 text-[#F14234]" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M13 20V7.83l5.59 5.59L20 12l-8-8l-8 8l1.41 1.41L11 7.83V20z" />
                        </svg>
                    </Link>
                </div>
                <div className="flex items-center gap-[1vw]">
               {user.isAuthenticated ?
                   
               <></>: <button data-modal-target="loginModal" className="open-modal-button flex h-[2.65vw] items-center rounded-full border-[1.5px] border-[#F14234] text-[#F14234] font-og text-[1.05vw] leading-[1] px-[1vw] py-[.65vw]">
                   <p>LOGIN</p>
               </button>}
                    <button data-modal-target="bookModal" className="open-modal-button flex items-center">
                        <div className="about-strand flex items-center rounded-full border-[1.5px] border-[#191919] text-[#FFFFFF] h-[2.65vw] bg-[#191919] font-og text-[1.05vw] leading-[1] px-[1vw] py-[.65vw]">
                            <p>BOOK A DEMO</p>
                        </div>
                        <div className="bg-[#191919] h-[2px] w-[.5vw]"></div>
                        <div className="rounded-full aspect-square flex items-center justify-center bg-[#191919] h-[2.65vw]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-white w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                    <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                    <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                    <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                </g>
                            </svg>
                        </div>
                    </button>
                    {user.isAuthenticated ?
                <LogoutMenu/>:<></>}
                </div>
            </div>

            {/* <!-- Mobile hamburger --> */}
            <button id="openNavMenu" className="md:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-[5.5vw] text-[#F14234]" viewBox="0 0 24 24">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M4 7h3m13 0h-9m9 10h-3M4 17h9m-9-5h16" />
                </svg>
            </button>
        </div>

        <div id="overlay" className="bg-opacity-10 fixed inset-0 bg-black"></div>

        <div id="menuNav" className="fixed z-[999] inset-y-0 right-0 md:hidden  flex max-w-[90%] translate-x-full">
            <div className="w-screen max-w-md">
                <div className="flex flex-col h-[100dvh] py-5 bg-white border-l shadow-lg border-neutral-100/70 relative">
                    <div className="px-[7vw]">
                        <div className="flex items-center justify-between py-3">
                            <h2 className="text-base font-semibold leading-6 text-gray-900" id="slide-over-title">
                                <img src={logo} className="w-[30vw]" alt="Logo"/>
                            </h2>
                            <div className="flex items-center h-auto ml-3">
                                <button aria-label="Close Menu" id="closeNavMenu" className="aspect-square text-neutral-600 hover:bg-neutral-100 z-30 flex items-center justify-center rounded-full">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-[7vw] h-[7vw]">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 relative overflow-y-scroll mt-[10vw]">
                        <div className="relative h-full flex flex-col gap-[5vw] font-medium justify-between">
                            <div className="flex flex-col text-[5vw] text-blackColor px-[3vw]">
                                <div className="accordion relative border-b">
                                    <div className="px-[5vw] py-[5vw] flex items-center justify-between accordion-toggle">
                                        <span className="relative">Curation Request</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                            <path fill="none" stroke="#396bd0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7" />
                                        </svg>
                                    </div>

                                    <div className="accordion-content">
                                        <div className="dropdown flex flex-col pl-[7vw] pb-[4vw] gap-[2vw]">
                                            <a href="#" className="text-[3.75vw]">
                                                Create Curation Request
                                            </a>
                                            <a href="#" className="text-[3.75vw]">
                                                My Curation Requests
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion relative border-b">
                                    <div className="px-[5vw] py-[5vw] flex items-center justify-between accordion-toggle">
                                        <span className="relative">Datasets</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                            <path fill="none" stroke="#396bd0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7" />
                                        </svg>
                                    </div>

                                    <div className="accordion-content">
                                        <div className="dropdown flex flex-col pl-[7vw] pb-[4vw] gap-[2vw]">
                                            <a href="#" className="text-[3.75vw]">
                                                Curated Datasets from Public Resources
                                            </a>
                                            <div className="accordion relative">
                                                <div className="flex items-center justify-between pr-[7vw] accordion-toggle">
                                                    <p className="text-[3.75vw]">Study Level Curation</p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                        <path fill="none" stroke="#396bd0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7" />
                                                    </svg>
                                                </div>
                                                <div className="accordion-content">
                                                    <div className="pl-[3vw] flex flex-col gap-[2vw] py-[2vw]">
                                                        <a href="#" className="text-[3.75vw]">
                                                            Inflammatory Bowel Disease
                                                        </a>
                                                        <a href="#" className="text-[3.75vw]">
                                                            Neurodegenerative Disease
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion relative">
                                                <div className="flex items-center justify-between pr-[7vw] accordion-toggle">
                                                    <p className="text-[3.75vw]">Experiment Level Curation</p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                        <path fill="none" stroke="#396bd0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7" />
                                                    </svg>
                                                </div>
                                                <div className="accordion-content">
                                                    <div className="pl-[3vw] flex flex-col gap-[2vw] py-[2vw]">
                                                        <a href="#" className="text-[3.75vw]">
                                                            Inflammatory Bowel Disease
                                                        </a>
                                                        <a href="#" className="text-[3.75vw]">
                                                            Neurodegenerative Disease
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion relative">
                                                <div className="flex items-center justify-between pr-[7vw] accordion-toggle">
                                                    <p className="text-[3.75vw]">Sample Level Curation</p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                        <path fill="none" stroke="#396bd0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7" />
                                                    </svg>
                                                </div>
                                                <div className="accordion-content">
                                                    <div className="pl-[3vw] flex flex-col gap-[2vw] py-[2vw]">
                                                        <a href="#" className="text-[3.75vw]">
                                                            Inflammatory Bowel Disease
                                                        </a>
                                                        <a href="#" className="text-[3.75vw]">
                                                            Neurodegenerative Disease
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Link aria-label="Metadata Visualization" to="/metadata-visualization" className="border-b">
                                    <div className="px-[5vw] py-[5vw]">
                                        <span className="relative">Metadata Visualization</span>
                                    </div>
                                </Link>
                                <button data-modal-target="loginModal" aria-label="Login Button" className="open-modal-button text-left border-b">
                                    <div className="px-[5vw] py-[5vw]">
                                        <span className="relative">Login</span>
                                    </div>
                                </button>
                                <button data-modal-target="bookModal" aria-label="Book a Demo Button" className="open-modal-button text-left border-b ">
                                    <div className="px-[5vw] py-[5vw]">
                                        <span className="relative">Book a Demo</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </header>
            
        <LoginForm/>
     
        {/* <!-- Signup Modal --> */}

        <SignUp/>

        {/* <!-- Reset Password Modal --> */}
        {/* <PasswordReset/> */}
        <BookDemo/>
       

        <main className="about-strand relative z-[0]">
        {/* <!-- Landing section --> */}
        <section className="about-strand font-og relative w-screen overflow-x-hidden">
            <img src={landingBgg} className="absolute top-1/2 right-0 w-[50vw]  translate-x-1/4 -translate-y-[1vw] md:hidden" alt="Image"/>
            <div className="about-strand absolute bg-[#F7F4FF] w-[45.5%] h-[calc(100%-2.5vw)] right-0 bottom-0 z-0 hidden md:block">
                <img src={landingBgg} className="absolute top-0 right-0 w-[30vw] -translate-y-[20%] translate-x-1/4" alt="Image"/>
            </div>
            <div className="about-strand pb-[15vw] md:pb-[3.5vw] pt-[10vw] md:pt-[0vw] px-[5.25vw] relative z-[1]">
                <div className="about-strand flex flex-col gap-[1vw]">
                    <div className="about-strand pt-[6vw]">
                        <h1 className="about-strand text-[#191919] text-[13.5vw] md:text-[8.15vw] leading-[.875] font-[550] tracking-tight md:w-[60%]">Strand’s scRNA Portal</h1>
                    </div>
                    <div className="about-strand text-[3.75vw] md:text-[1.55vw] text-[#F14234] pt-[3vw] md:pt-[0vw] font-[325]">
                        Refining Precision Research, One Cell at a Time
                    </div>
                </div>

                <div className="about-strand md:items-end md:justify-between md:flex-row flex flex-col mt-[10vw] md:mt-[-6vw]">
                    <div className="about-strand md:w-[36%]">
                        <div>
                            <p className="about-strand text-[3.5vw] md:text-[1.235vw] text-[#363535] font-[350] leading-[1.3]">This portal is your gateway to <span className="font-[500]">meticulously collected single-cell RNA sequencing data</span> empowering precision research.</p>
                            <p className="about-strand text-[3.5vw] md:text-[1.235vw] text-[#363535] font-[350] leading-[1.3] mt-[3vw] md:mt-[1vw]">This platform offers <span className="font-[500]">harmonized single-cell RNA sequencing datasets</span> focused on complex diseases with significant unmet clinical needs, including <span className="font-[500]">Ulcerative Colitis, Alzheimer’s Disease,</span> and <span className="font-[500]">Frontotemporal Dementia.</span></p>
                        </div>

                        <button data-modal-target="bookModal" className="about-strand open-modal-button flex items-center mt-[8vw] md:mt-[2vw]">
                            <div className="about-strand flex items-center rounded-full border-[1.5px] border-[#191919] text-[#FFFFFF] h-[7vw] md:h-[2.65vw] bg-[#191919] font-og text-[3.05vw] md:text-[1.05vw] leading-[1] px-[3vw] py-[1vw] md:px-[1vw] md:py-[.65vw]">
                                <p className="about-strand">BOOK A DEMO</p>
                            </div>
                            <div className="about-strand bg-[#191919] h-[1.5px] md:h-[2.5px] w-[7vw] md:w-[4.75vw]"></div>
                            <div className="about-strand rounded-full aspect-square flex items-center justify-center bg-[#191919] h-[7vw] md:h-[2.65vw]">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-white w-[3vw] md:w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                    <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                        <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                        <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                        <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                    </g>
                                </svg>
                            </div>
                        </button>
                    </div>
                    <div className="md:w-[40%] md:mt-0 mt-[10vw]">
                        <div className="grid grid-cols-3">
                            <div className="relative border border-[#EBA78C] aspect-[16/16.5] col-start-2">
                                <div className="absolute inset-0 z-0">
                                    <div className="absolute top-0 left-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full -translate-y-1/2 -translate-x-1/2"></div>
                                    <div className="absolute top-0 right-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full -translate-y-1/2 translate-x-1/2"></div>
                                </div>
                                <div className="relative z-[1] p-[2vw] md:p-[1vw] pt-[3vw] md:pt-[1.25vw] h-full">
                                    <div className="flex flex-col justify-between h-full">
                                        <div className="text-right font-[350] text-[3vw] md:text-[1vw] leading-[1.09] text-[#1D1D1D]">Total No. of<br/>Samples</div>
                                        <div className="flex">
                                            <p className="text-[#F24134] text-[11vw] md:text-[4.5vw] leading-[1] flex">2200 <sup className="-top-[.35vw] -translate-x-[1vw] text-[5vw] md:text-[2.5vw]">+</sup></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative border border-[#EBA78C] aspect-[16/16.5] row-start-2">
                                <div className="absolute inset-0 z-0">
                                    <div className="absolute top-0 left-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full -translate-y-1/2 -translate-x-1/2"></div>
                                    <div className="absolute top-0 right-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full -translate-y-1/2 translate-x-1/2"></div>
                                    <div className="absolute bottom-0 right-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full translate-y-1/2 translate-x-1/2"></div>
                                    <div className="absolute bottom-0 left-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full translate-y-1/2 -translate-x-1/2"></div>
                                </div>
                                <div className="relative z-[1] p-[2vw] md:p-[1vw] pt-[3vw] md:pt-[1.25vw] h-full">
                                    <div className="flex flex-col justify-between h-full">
                                        <div className="text-right font-[350] text-[3vw] md:text-[1vw] leading-[1.09] text-[#1D1D1D]">Total No. of Cell Types</div>
                                        <div className="flex">
                                            <p className="text-[#F24134] text-[11vw] md:text-[4.5vw] leading-[1] flex">200 <sup className="-top-[.35vw] -translate-x-[1vw] text-[5vw] md:text-[2.5vw]">+</sup></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative border border-[#EBA78C] aspect-[16/16.5] col-start-3 row-start-2">
                                <div className="absolute inset-0 z-0">
                                    <div className="absolute top-0 left-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full -translate-y-1/2 -translate-x-1/2"></div>
                                    <div className="absolute top-0 right-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full -translate-y-1/2 translate-x-1/2"></div>
                                    <div className="absolute bottom-0 right-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full translate-y-1/2 translate-x-1/2"></div>
                                    <div className="absolute bottom-0 left-0 bg-[#FFFFFF] md:bg-[#F7F4FF] w-[2vw] md:w-[.85vw] aspect-square rounded-full translate-y-1/2 -translate-x-1/2"></div>
                                </div>
                                <div className="relative z-[1] p-[2vw] md:p-[1vw] pt-[3vw] md:pt-[1.25vw] h-full">
                                    <div className="flex flex-col justify-between h-full">
                                        <div className="text-right font-[350] text-[3vw] md:text-[1vw] leading-[1.09] text-[#1D1D1D]">Total No. Of <br/>Cells</div>
                                        <div className="flex">
                                            <p className="text-[#F24134] text-[11vw] md:text-[4.5vw] leading-[1] flex">18M <sup className="-top-[.35vw] text-[5vw] md:text-[2.5vw]">+</sup></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- Second section --> */}
        <section className="bg-[#FAFAFA] md:pt-[13.5vw] py-[15vw] md:pb-[11vw] relative px-[5vw] md:px-0">
            <div className="bg-[#E7EDF2] w-[36.8vw] h-[37vw] absolute bottom-0 left-0 z-0 hidden md:block"></div>
            <img className="absolute left-0 top-[19%] w-[15.5vw] md:block hidden" src={secondBg} alt="bg-img"/>
            <div className="md:flex-row flex flex-col md:pl-[25vw] md:pr-[7vw]">
                <div className="">
                    <p className="text-[#191919] font-og md:text-[5.8vw] text-[10vw] font-medium leading-[.95] relative z-[3]"><span className="text-[#F14234]">Your Data Partner </span> for accessible scRNA sequencing data</p>
                </div>

                <div className="flex flex-col md:mt-[4vw] mt-[8vw]">
                    <div className=" flex flex-col">
                        <p className="text-[#2B2929] font-og font-light md:text-[1.05vw] text-[3.5vw] md:leading-[1.3] leading-[1.5] md:w-[85%]">We curate publicly available datasets and offer custom curation for datasets of your interest. We harness datasets from public repositories and harmonize them using <span className="font-medium">internationally recognized ontologies</span> and <span className="font-medium">controlled vocabularies</span> to <span className="font-medium">enhance data usability</span> for <span className="font-medium">downstream applications</span>. Each dataset has been curated from NCBI’s GEO platform.</p>
                        <p className="text-[#2B2929] font-og font-light md:text-[1.05vw] text-[3.5vw]  md:leading-[1.3] leading-[1.5] md:mt-[1vw] mt-[5vw] md:w-[78%] ">Our mission is to facilitate innovative research by providing accessible, high-quality data that drives precision insights and advances our understanding of complex diseases.</p>
                    </div>
                    <button data-modal-target="signupModal" className="open-modal-button flex items-center md:mt-[6vw] mt-[8vw]">
                        <div className="about-strand flex items-center rounded-full border-[1.5px] border-[#191919] text-[#FFFFFF] h-[7vw] md:h-[2.65vw] bg-[#191919] font-og text-[3.05vw] md:text-[1.05vw] leading-[1] px-[3vw] py-[1vw] md:px-[1vw] md:py-[.65vw]">
                            <p>SIGN UP TO OUR PORTAL</p>
                        </div>
                        <div className="bg-[#191919] h-[1.5px] md:h-[2.5px] w-[7vw] md:w-[4.75vw]"></div>
                        <div className="rounded-full aspect-square flex items-center justify-center bg-[#191919] h-[7vw] md:h-[2.65vw]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-white w-[3vw] md:w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                    <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                    <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                    <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                </g>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </section>

        {/* <!-- Key Features Section --> */}
        <section className="px-[5.25vw] md:pt-[7vw] py-[15vw] md:pb-[8vw] relative">
            <div className="bg-[#EFE7F3] md:h-[22vw] h-[240vw] w-full absolute left-0 bottom-0 z-0">
            </div>
            <div className="relative z-[5]">
                <div className="flex items-center gap-[2vw]">
                    <p className="text-[#090B0A] font-og font-medium md:text-[5.9vw] text-[10vw] ">Key features</p>
                    <div className="bg-[#F14234] md:w-[8vw] w-[15vw] h-[.5vw] md:h-[.18vw]"></div>
                </div>
                <div className="grid md:grid-cols-3 md:gap-[1.5vw] gap-[5vw] md:px-[3.2vw] px-[5vw] mt-[6.5vw]">
                    <div className="border-[#484543] bg-[white] border md:rounded-[1.5vw] rounded-[4vw] md:px-[1.5vw] px-[3vw] md:pt-[1vw] pt-[5vw] md:pb-[2.25vw] pb-[7vw] flex flex-col">
                        <div>
                            <div className="flex justify-between">
                                <p className="text-[#090B0A] font-og font-light md:text-[1.30vw] text-[5vw]">01</p>
                                <img src={arrowR} className="md:w-[2vw] w-[8vw] mt-[1vw]" alt=""/>
                            </div>
                            <div className="flex gap-[.5vw] items-baseline  pl-[.5vw] border-[#E5E5E5] md:mt-0 mt-[5vw] border-b-[1.5px] md:pb-[2vw] pb-[6vw]">
                                <img src={c} className="md:w-[3.5vw] w-[8vw]" alt="c"/>
                                <img src={r} className="md:w-[1.5vw] w-[4vw]" alt="r"/>
                            </div>
                        </div>
                        <div className="flex flex-col flex-1">
                            <div className="md:mt-[4vw] mt-[8vw] px-[1vw]">
                                <p className="text-[#090B0A] font-og font-normal md:text-[2.7vw] text-[8vw] ">Curation Requests</p>
                                <p className="font-og font-light text-[#2B2929] md:text-[1.15vw] text-[3.5vw] leading-[1.3] md:mt-[1vw] mt-[2vw] ">Submit a dataset of your choice for curation by our expert curation team.</p>
                            </div>
                            {/* <!-- Give the link to create curation request --> */}
                            <Link to= {"/create-curation-request"} className="flex items-center px-[1vw] mt-[5vw] md:mt-auto">
                                <div className="flex items-center rounded-full border border-[#090B0A] text-[#090B0A] md:h-[2.25vw] h-[7vw] bg-white font-og font-medium md:text-[.8vw] text-[3vw] leading-[1] md:px-[1.5vw] px-[4vw] md:py-[.65vw] py-[2vw]">
                                    <p>MAKE A REQUEST</p>
                                </div>
                                <div className="bg-[#191919] h-[2px] md:w-[1vw] w-[6vw]"></div>
                                <div className="rounded-full aspect-square flex items-center justify-center bg-white border border-[#090B0A]  md:h-[2.25vw] h-[7vw]">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-[#F14234] md:w-[.8vw] w-[3vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                        <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                            <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                            <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                            <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                        </g>
                                    </svg>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="border-[#484543] bg-[white] border md:rounded-[1.5vw] rounded-[4vw] md:px-[1.5vw] px-[3vw] md:pt-[1vw] pt-[5vw] md:pb-[2.25vw] pb-[7vw] flex flex-col">
                        <div>
                            <div className="flex justify-between">
                                <p className="text-[#090B0A] font-og font-light md:text-[1.30vw] text-[5vw]">02</p>
                                <img src={arrowR} className="md:w-[2vw] w-[8vw] mt-[1vw]" alt=""/>
                            </div>
                            <div className="flex gap-[.5vw] items-baseline  pl-[.5vw] border-[#E5E5E5] md:mt-0 mt-[5vw] border-b-[1.5px] md:pb-[2vw] pb-[6vw]">
                                <img src={d} className="md:w-[3.5vw] w-[8vw]" alt="c"/>
                            </div>
                        </div>
                        <div className="flex flex-col flex-1">
                            <div className="md:mt-[4vw] mt-[8vw] px-[1vw]">
                                <p className="text-[#090B0A] font-og font-normal md:text-[2.7vw] text-[8vw] ">Datasets</p>
                                <p className="font-og font-light text-[#2B2929] md:text-[1.15vw] text-[3.5vw] leading-[1.3] md:mt-[1vw] mt-[2vw] ">Meticulous metadata curation and harmonization in 3 disease areas, with over 80+ metadata fields spanning 3 levels of curation.</p>
                            </div>
                            <div className="mt-auto">
                                {/* <!-- Give the link to curated datasets from public resource --> */}
                                <Link to={"/study-table"}  onClick={() => handleDiseaseCategoryChange("Inflammatory Bowel Disease")} className="flex items-center md:mt-[2vw] mt-[5vw] px-[1vw]">
                                    <div className="flex items-center rounded-full border border-[#090B0A] text-[#090B0A] md:h-[2.25vw] h-[7vw] bg-white font-og font-medium md:text-[.8vw] text-[3vw] leading-[1] md:px-[1.5vw] px-[4vw] md:py-[.65vw] py-[2vw]">
                                        <p>SEARCH DATABASE</p>
                                    </div>
                                    <div className="bg-[#191919] h-[2px] md:w-[1vw] w-[6vw]"></div>
                                    <div className="rounded-full aspect-square flex items-center justify-center bg-white border border-[#090B0A]  md:h-[2.25vw] h-[7vw]">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-[#F14234] md:w-[.8vw] w-[3vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                            <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                                <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                                <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                                <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                            </g>
                                        </svg>
                                    </div>
                                </Link>
                            </div>
                        </div>

                    </div>
                    <div className="border-[#484543] bg-[white] border md:rounded-[1.5vw] rounded-[4vw] md:px-[1.5vw] px-[3vw] md:pt-[1vw] pt-[5vw] md:pb-[2.25vw] pb-[7vw] flex flex-col">
                        <div>
                            <div className="flex justify-between">
                                <p className="text-[#090B0A] font-og font-light md:text-[1.30vw] text-[5vw]">03</p>
                                <img src={arrowR} className="md:w-[2vw] w-[8vw] mt-[1vw]" alt=""/>
                            </div>
                            <div className="flex gap-[.5vw] items-baseline md:mt-0 mt-[5vw]  pl-[.5vw] border-[#E5E5E5] border-b-[1.5px]  md:pb-[2vw] pb-[6vw]">
                                <img src={mv}className="md:w-[7.25vw] w-[15vw]" alt="c"/>
                            </div>
                        </div>
                        <div className="flex flex-col flex-1">
                            <div className="md:mt-[2.5vw] mt-[8vw] px-[1vw]">
                                <p className="text-[#090B0A] font-og font-normal md:text-[2.7vw] text-[8vw] leading-[1] ">Metadata Visualization</p>
                                <p className="font-og font-light text-[#2B2929] md:text-[1.15vw] text-[3.5vw] leading-[1.3] md:mt-[1vw] mt-[2vw] ">Uncover hidden patterns, correlations and trends in the metadata using charts and graphs in various formats.</p>
                            </div>
                            <div className="mt-auto">
                                <Link to={"/metadata-visualization"} className="flex items-center md:mt-[2vw] mt-[5vw] px-[1vw]">
                                    <div className="flex items-center rounded-full border border-[#090B0A] text-[#090B0A] md:h-[2.25vw] h-[7vw] bg-white font-og font-medium md:text-[.8vw] text-[3vw] leading-[1] md:px-[1.5vw] px-[4vw] md:py-[.65vw] py-[2vw]">
                                        <p>EXPLORE</p>
                                    </div>
                                    <div className="bg-[#191919] h-[2px] md:w-[1vw] w-[6vw]"></div>
                                    <div className="rounded-full aspect-square flex items-center justify-center bg-white border border-[#090B0A]  md:h-[2.25vw] h-[7vw]">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-[#F14234] md:w-[.8vw] w-[3vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                            <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                                <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                                <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                                <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                            </g>
                                        </svg>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- Metadata Curation Section --> */}
        <section className="about-strand md:pt-[7vw] py-[15vw] md:pb-[8vw] relative font-og">
            <div className="about-strand px-[5.25vw]">
                <div className="about-strand flex items-center gap-[5vw]  md:gap-[2vw]">
                    <p className="about-strand text-[#090B0A] font-og font-medium md:text-[5.9vw] text-[9.3vw] leading-[1]">Metadata Curation</p>
                    <div className="about-strand bg-[#F14234] md:w-[8vw] w-[10vw] h-[.5vw] md:h-[.18vw]"></div>
                </div>
            </div>

            <div className="about-strand md:mt-[2vw] mt-[7vw] flex md:flex-row flex-col md:items-end md:justify-between gap-[15vw] md:gap-0 md:pr-[8vw] pl-[5vw] md:px-0">
                <div className="about-strand bg-[#F7F7F7] md:pr-[2vw] md:pl-[9.25vw] px-[4vw] md:px-0 md:py-[4.5vw] py-[10vw] md:w-[58%]">
                    <div className="about-strand flex flex-col md:gap-[1.5vw] gap-[3vw]">
                        <div className="about-strand flex items-start md:gap-[.75vw] gap-[3vw]">
                            <div className="about-strand md:w-[.75vw] w-[1.5vw] shrink-0 aspect-square bg-[#F24134] rounded-full md:mt-[.45vw] mt-[1.8vw]"></div>
                            <p className="about-strand text-[3.5vw] md:text-[1.2vw] font-[350]">This is a <span className="font-[500]">disease-focused portal</span> solving a key challenge in dataset acquisition for biomedical research. We artfully extract datasets from public repositories using customized keywords.</p>
                        </div>
                        <div className="about-strand flex items-start md:gap-[.75vw] gap-[3vw]">
                            <div className="about-strand md:w-[.75vw] w-[1.5vw] shrink-0 aspect-square bg-[#F24134] rounded-full md:mt-[.45vw] mt-[1.8vw]"></div>
                            <p className="about-strand text-[3.5vw] md:text-[1.2vw] font-[350]">Data is standardized and harmonized using <span className="font-[500]">internationally recognized ontologies </span>and<span className="font-[500]"> vocabularies.</span></p>
                        </div>
                        <div className="about-strand flex items-start md:gap-[.75vw] gap-[3vw]">
                            <div className="about-strand md:w-[.75vw] w-[1.5vw] shrink-0 aspect-square bg-[#F24134] rounded-full md:mt-[.45vw] mt-[1.8vw]"></div>
                            <p className="about-strand text-[3.5vw] md:text-[1.2vw] font-[350]">We offer extensive metadata curation with <span className="font-[500]">80+ metadata fields spanning 3 levels of curation:</span> study, experiment, and sample. We can customize metadata fields as needed to provide the right inputs for your research.</p>
                        </div>
                        <div className="about-strand flex items-start md:gap-[.75vw] gap-[3vw]">
                            <div className="about-strand md:w-[.75vw] w-[1.5vw] shrink-0 aspect-square bg-[#F24134] rounded-full md:mt-[.45vw] mt-[1.8vw]"></div>
                            <p className="about-strand text-[3.5vw] md:text-[1.2vw] font-[350]">The search feature on our portal helps users <span className="font-[500]">browse using specific keywords</span>. There are 26 filters to help narrow down to a dataset that meets your requirement.</p>
                        </div>

                        <div className="about-strand flex items-start md:gap-[.75vw] gap-[3vw]">
                            <div className="about-strand md:w-[.75vw] w-[1.5vw] shrink-0 aspect-square bg-[#F24134] rounded-full md:mt-[.45vw] mt-[1.8vw]"></div>
                            <p className="about-strand text-[3.5vw] md:text-[1.2vw] font-[350]">These datasets can be <span className="font-[500]">combined with the Strand Bioinformatics tools </span> that support scRNA sequencing analysis, bulk RNA sequencing and multi ‘omics analysis for valuable holistic insights.</p>
                        </div>
                    </div>
                    <div className="about-strand md:mt-[4vw] mt-[7vw] pl-[4vw] md:pl-0">
                        <Link to={"/study-table"} onClick={() => handleDiseaseCategoryChange("Inflammatory Bowel Disease")} className="flex items-center mt-[8vw] md:mt-[2vw]">
                            <div className="about-strand flex items-center rounded-full border-[1.5px] border-[#191919] text-[#FFFFFF] h-[7vw] md:h-[2.65vw] bg-[#191919] font-og text-[3.05vw] md:text-[1.05vw] leading-[1] px-[3vw] py-[1vw] md:px-[1vw] md:py-[.65vw]">
                                <p>EXPLORE DATASETS</p>
                            </div>
                            <div className="about-strand bg-[#191919] h-[1.5px] md:h-[2.5px] w-[7vw] md:w-[4.75vw]"></div>
                            <div className="about-strand rounded-full aspect-square flex items-center justify-center bg-[#191919] h-[7vw] md:h-[2.65vw]">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-white w-[3vw] md:w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                    <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                        <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                        <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                        <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                    </g>
                                </svg>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="about-strand md:w-[30%] pr-[5.25vw] md:pr-0">
                    <div className="about-strand border-[.75px] border-black rounded-[2vw] md:p-[1.5vw] p-[5vw]">
                        <div className="about-strand border-b border-[#E5E5E5] md:pb-[2vw] pb-[5vw]">
                            <p className="about-strand md:text-[2.5vw] text-[6vw] leading-[1] font-[325] tracking-tight w-[80%]">Features of the Database</p>
                        </div>
                        <div className="about-strand md:pt-[3vw] pt-[6vw]">
                            <div className="about-strand flex flex-col md:space-y-[1.25vw] space-y-[2.5vw] pr-[3vw] pb-[2.5vw]">
                                <div className="about-strand flex md:gap-[.8vw] gap-[3vw] items-start">
                                    <img src={arrowR} className="md:w-[1.25vw] w-[3vw] rotate-45" alt="arrow"/>
                                    <p className="about-strand text-[#1D1D1D] md:text-[1.15vw] text-[3.65vw] font-og font-light leading-[1.2]">Custom Keywords To Harness Relevant Datasets</p>
                                </div>
                                <div className="about-strand flex md:gap-[.8vw] gap-[3vw] items-start">
                                    <img src={arrowR}  className="md:w-[1.25vw] w-[3vw] rotate-45" alt="arrow"/>
                                    <p className="about-strand text-[#1D1D1D] md:text-[1.15vw] text-[3.65vw] font-og font-light leading-[1.1]">3 Levels Of Curation</p>
                                </div>
                                <div className="about-strand flex md:gap-[.8vw] gap-[3vw] items-start ">
                                    <img src={arrowR} className="md:w-[1.25vw] w-[3vw] rotate-45" alt="arrow"/>
                                    <p className="about-strand text-[#1D1D1D] md:text-[1.15vw] text-[3.65vw] font-og font-light leading-[1.1]">80+ Metadata Fields</p>
                                </div>
                                <div className="about-strand flex md:gap-[.8vw] gap-[3vw] items-start">
                                    <img src={arrowR} className="about-strand md:w-[1.25vw] w-[3vw] rotate-45" alt="arrow"/>
                                    <p className="about-strand text-[#1D1D1D] md:text-[1.15vw] text-[3.65vw] font-og font-light leading-[1.2]">Standardization Using Recognised Ontologies & Vocabularies</p>
                                </div>

                                <div className="about-strand flex md:gap-[.8vw] gap-[3vw] items-start">
                                    <img src={arrowR} className="md:w-[1.25vw] w-[3vw] rotate-45" alt="arrow"/>
                                    <p className="about-strand text-[#1D1D1D] md:text-[1.15vw] text-[3.65vw] font-og font-light leading-[1.1]">Independent Curation & Review Process</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- About Strand Section --> */}
        <section className="about-strand md:pt-[5vw] py-[15vw] md:pb-[5vw] relative">

            <div className="relative z-[5]">
                <div className="about-strand flex items-center gap-[2vw] px-[5.25vw]">
                    <p className="text-[#090B0A] font-og font-medium md:text-[5.9vw] text-[10vw] ">About Strand</p>
                    <div className="bg-[#F14234] md:w-[8vw] w-[15vw] h-[.5vw] md:h-[.18vw]"></div>
                </div>
                <div className="about-strand pl-[10vw] md:px-0">
                    <div className="about-strand bg-[#E7F1F3] md:w-[88%] ml-auto md:pl-[10vw] px-[8vw] md:px-0 md:pt-[5vw] pt-[10vw] md:pb-[4vw] pb-[10vw] mt-[5vw] relative">
                        <img src={aboutmission} className="md:w-[26vw] w-[35vw] md:top-0 bottom-0 translate-y-[40%] md:-translate-y-[15%] md:absolute right-[5%] hidden md:block" alt="ceo"/>
                        <img src={bg} className="absolute z-0 md:w-[12vw] w-[20vw] left-0 top-[45%] -translate-x-[60%]" alt=""/>
                        <img src={q} className="md:w-[5vw] w-[10vw] absolute top-0 left-[6%] -translate-y-[35%]" alt=""/>
                        <div className="about-strand space-y-[4vw] md:space-y-[1.75vw] relative z-[1]">
                            <p className="text-[#090B0A] font-og font-[325] md:leading-[1.2] leading-[1.4] md:text-[1.5vw] text-[4vw] md:w-[55%]"><a href="https://us.strandls.com/" target="_blank" rel="noopener noreferrer"  className="underline">Strand Life Sciences</a> is a comprehensive bioinformatics company involved in genomic and transcriptomic research. Our focus is on improving the drug discovery process and precision medicine.</p>
                            <p className="text-[#090B0A] font-og font-[325] md:leading-[1.2] leading-[1.4] md:text-[1.5vw] text-[4vw] md:w-[55%]">Our team comprises over 300 highly trained curation scientists, software engineers, testing engineers, bioinformaticians, and clinical researchers. We have 23 years of experience in this domain.</p>
                            <p className="text-[#090B0A] font-og font-[325] md:leading-[1.2] leading-[1.4] md:text-[1.5vw] text-[4vw] md:w-[55%]">We are a Bangalore-based company with close ties with the Bay Area and San Diego, where many of our clientele are based.</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        {/* <!-- FAQ Section --> */}
        <section>
            <div className="flex flex-col-reverse md:flex-row gap-[3vw] font-og pb-[15vw] md:pb-0 pt-[10vw] md:pt-[5vw]">
                <div className="pl-[10vw] md:px-0 md:w-[62%]">
                    <div className="bg-[#F7F7F7] mt-[5vw] pl-[5.25vw] pr-[4.5vw] py-[5vw]">
                        <div className="relative w-full mx-auto overflow-hidden divide-y divide-[#090B0A]/[.28] rounded-md">
                          <ToggleAccordion/>
                        </div>
                    </div>
                </div>
                <div className="md:w-[38%] pt-[2.5vw] relative px-[5vw] md:px-0">
                    <h2 className="text-[10vw] md:text-[5.9vw] text-[#090B0A] leading-[1] md:leading-[.92] font-[450] relative z-[0]">Frequently Asked Questions <span className="h-[2.5px] w-[10vw] md:w-[4vw] md:mb-[1.5vw] mb-[2vw] bg-[#F14234] inline-block"></span></h2>
                    <img src={faq} className="absolute right-0 z-[1] w-[25vw] hidden md:block" alt="Bg image"/>
                </div>
            </div>
        </section>
        </main>

        <footer className="font-og">
        <div>
            <div className="md:flex-row flex flex-col-reverse">
                <div className="bg-[#1D1D1D] py-[15vw] md:py-[5.25vw] p-[5.25vw] md:w-[60%] text-white">
                    <a href="index.html">
                        <img src={footerLogo} className="w-[25vw] md:w-[11.5vw]" alt="Footer logo"/>
                    </a>

                    <div className="mt-[12vw] md:mt-[4vw] md:px-[2vw]">
                        <div className="flex gap-[20vw] md:gap-[10vw]">
                            <div>
                                <p className="text-[6vw] md:text-[2.5vw]">Features</p>
                                <ul className="text-[3.5vw] md:text-[1.235vw] font-[350] leading-[1.3] space-y-[1vw] md:space-y-[.35vw] mt-[2vw] md:mt-[1vw]">
                                    <li><Link to={"/create-curation-request"}>Data Curation</Link></li>
                                    <li><Link to={"/study-table"} onClick={() => handleDiseaseCategoryChange("Inflammatory Bowel Disease")}>Data Sets</Link></li>
                                    <li><Link to={"/metadata-visualization"}>Meta Data Visualization</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="mt-[12vw] md:mt-[7vw]">
                            <div className="flex flex-col-reverse md:flex-row md:items-end gap-[8vw]">
                                <div className="md:w-[20.5vw] w-[80vw] relative">
                                    <img src={dna} className="absolute top-[.5vw] right-[.5vw] w-[30vw] md:w-[10vw] translate-x-1/2 -translate-y-1/2" alt="Image"/>
                                    <div>
                                        <div className="">
                                            <img src={footer} alt="Image"/>
                                        </div>
                                        <a href="https://us.strandls.com/" target="_blank" rel="noopener noreferrer"  className="flex items-center mt-[8vw] md:mt-[2vw]">
                                            <div className="flex items-center rounded-full border-[1.5px] border-[#FFFFFF] text-[#FFFFFF] h-[7vw] md:h-[2.65vw] bg-[#191919] font-og text-[3.05vw] md:text-[1.05vw] leading-[1] px-[3vw] py-[1vw] md:px-[1vw] md:py-[.65vw]">
                                                <p className="whitespace-nowrap">VISIT STRAND WEBSITE</p>
                                            </div>
                                            <div className="bg-[#FFFFFF] h-[1.5px] md:h-[2.5px] w-[7vw] md:w-[4.75vw]"></div>
                                            <div className="rounded-full aspect-square flex items-center justify-center bg-[#191919] border-[1.5px] border-[#FFFFFF] h-[7vw] md:h-[2.65vw]">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-white w-[3vw] md:w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                                    <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                                        <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                                        <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                                        <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="flex gap-[4vw] md:gap-[1.85vw] items-center">
                                    <a href="https://www.linkedin.com/company/strand-life-sciences" target="_blank" rel="noopener noreferrer"  className="aspect-square w-[12vw] md:w-[4.25vw] rounded-full bg-[#272727] grid place-content-center">
                                        <svg id="Group_35829" data-name="Group 35829" xmlns="http://www.w3.org/2000/svg" className="h-[4vw] md:h-[1.75vw]" viewBox="0 0 34.712 34.711">
                                            <path id="Path_459" data-name="Path 459" d="M0,5H7.762V28.864H0Zm28.906.28c-.082-.026-.161-.054-.247-.078q-.156-.035-.315-.061A6.957,6.957,0,0,0,26.951,5c-4.526,0-7.4,3.291-8.342,4.562V5H10.847V28.864H18.61V15.847s5.866-8.17,8.342-2.169V28.864h7.76V12.76a7.741,7.741,0,0,0-5.806-7.48Z" transform="translate(0 5.846)" fill="#fff" />
                                            <circle id="Ellipse_43" data-name="Ellipse 43" cx="3.797" cy="3.797" r="3.797" transform="translate(0)" fill="#fff" />
                                        </svg>
                                    </a>
                                    <a href="https://x.com/StrandLife" target="_blank" rel="noopener noreferrer"  className="aspect-square w-[12vw] md:w-[4.25vw] rounded-full bg-[#272727] grid place-content-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-[3.5vw] md:h-[1.55vw]" viewBox="0 0 35.406 32.004">
                                            <path id="Path_460" data-name="Path 460" d="M111.949,100h5.429l-11.861,13.557L119.471,132H108.545l-8.557-11.188L90.2,132H84.764L97.45,117.5,84.065,100h11.2L103,110.227Zm-1.905,28.754h3.008L93.633,103.079H90.4Z" transform="translate(-84.065 -100)" fill="#fff" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <HandleCoversation/>
                {/* <div className="bg-[#171717] py-[15vw] md:py-[5.25vw] p-[5.25vw] md:w-[40%]">
                    <h2 className="text-[10vw] md:text-[3.5vw] text-[#ffffff] leading-[1] md:leading-[.92] font-[350] relative z-[0] pt-[1vw]">Let’s start<br/>A Conversation <span className="h-[2.5px] w-[10vw] md:w-[4vw] md:mb-[.9vw] mb-[2vw] bg-[#F14234] inline-block"></span></h2>

                    <div className="mt-[6vw] md:mt-[3vw]">
                        <form className="flex flex-col gap-[8vw] md:gap-[3.5vw]">
                            <div className="relative">
                                <div className="border-b border-white">
                                    <input required type="text" placeholder="Name" className="text-[4vw] pb-[.35vw] md:text-[1.235vw] placeholder:text-[3.5vw] md:placeholder:text-[1.235vw] bg-transparent text-white font-[350] focus:outline-none focus:ring-0 w-full"/>
                                </div>
                            </div>
                            <div className="relative">
                                <div className="border-b border-white">
                                    <input required type="tel" placeholder="Phone Number" className="text-[4vw] pb-[.35vw] md:text-[1.235vw] placeholder:text-[3.5vw] md:placeholder:text-[1.235vw] bg-transparent text-white font-[350] focus:outline-none focus:ring-0 w-full"/>
                                </div>
                            </div>
                            <div className="relative">
                                <div className="border-b border-white">
                                    <input required type="email" placeholder="Email Address" className="text-[4vw] pb-[.35vw] md:text-[1.235vw] placeholder:text-[3.5vw] md:placeholder:text-[1.235vw] bg-transparent text-white font-[350] focus:outline-none focus:ring-0 w-full"/>
                                </div>
                            </div>
                            <div className="relative">
                                <div className="border-b border-white">
                                    <input required type="text" placeholder="Type your message here" className="text-[4vw] pb-[.35vw] md:text-[1.235vw] placeholder:text-[3.5vw] md:placeholder:text-[1.235vw] bg-transparent text-white font-[350] focus:outline-none focus:ring-0 w-full"/>
                                </div>
                            </div>

                            <div className="relative mt-[1vw]">
                                <button type="submit">
                                    <div className="flex items-center">
                                        <div className="flex items-center rounded-full border-[1.5px] border-[#FFFFFF] text-[#191818] h-[7vw] md:h-[2.65vw] bg-[#FFFFFF] font-og text-[3.05vw] md:text-[1.05vw] leading-[1] px-[3vw] py-[1vw] md:px-[1.25vw] md:py-[.65vw]">
                                            <p>SUMBIT MESSAGE</p>
                                        </div>
                                        <div className="bg-[#FFFFFF] h-[1.5px] md:h-[2.5px] w-[7vw] md:w-[2.75vw]"></div>
                                        <div className="rounded-full aspect-square flex items-center justify-center bg-[#FFFFFF] h-[7vw] md:h-[2.65vw]">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-[#F14234] w-[3vw] md:w-[1.05vw] rotate-45 ml-[.25vw]" viewBox="0 0 39.607 39.607">
                                                <g id="c4067ea13a0109e58cd0c2fc71e6b022" transform="translate(20.596) rotate(45)">
                                                    <path id="Path_5191" data-name="Path 5191" d="M13.443,15.684a2.034,2.034,0,0,1-1.568-.672L.672,3.809a2.166,2.166,0,0,1,0-3.137,2.166,2.166,0,0,1,3.137,0l11.2,11.2a2.166,2.166,0,0,1,0,3.137,2.034,2.034,0,0,1-1.568.672Z" transform="translate(11.203 0)" fill="currentColor" />
                                                    <path id="Path_5192" data-name="Path 5192" d="M2.241,15.684a2.034,2.034,0,0,1-1.568-.672,2.166,2.166,0,0,1,0-3.137l11.2-11.2a2.166,2.166,0,0,1,3.137,0,2.166,2.166,0,0,1,0,3.137l-11.2,11.2a2.034,2.034,0,0,1-1.568.672Z" transform="translate(0 0)" fill="currentColor" />
                                                    <path id="Path_5193" data-name="Path 5193" d="M2.241,40.329A2.117,2.117,0,0,1,0,38.089V2.241A2.117,2.117,0,0,1,2.241,0,2.117,2.117,0,0,1,4.481,2.241V38.089A2.117,2.117,0,0,1,2.241,40.329Z" transform="translate(11.203 0)" fill="currentColor" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>*/}
            </div> 

            <div className="px-[5.25vw] py-[1.75vw] md:py-[1vw] flex flex-col md:flex-row gap-1 justify-between items-center bg-[#171717] text-white text-[3vw] md:text-[1vw] border-t border-white">
                <p>2024 Copyright Strand Life Sciences All Rights Reserved</p>
            </div>
        </div>
        </footer>

</div>
)
}

export default LandingNew;